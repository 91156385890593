import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Select from "react-select";
import { selectLocationOptions } from "../../store/selectors/LocationSelectors";
import CurrencyInput from "react-currency-input-field";
import { getMenus, getSubMenus } from "../../services/MenuService";
import { getItems } from "../../services/ItemService";
import noImg from "../../img/no-image-available.png";
import { preventMinus, randomStr } from "../../services/UtilsService";
import { ReactS3Client, s3Url } from "../../services/AWSS3";
import { Spinner } from "react-bootstrap";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { addReward, editReward } from "../../services/NewRewardsService";
import { NumberInput } from "../reusable/NumberInput";
import { TextInput } from "../reusable/TextInput";

const NewRewardsSettings = ({
    selectedReward,
    setSelectedReward,
    save,
    cancel,
}) => {
    const defaultFormData = {
        badgeStatus: {}, streak: {}
    };
    const defaultFormConditions = {
        item: false,
        discount: false,
        discountPercentage: true,
        cashback: false
    };
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({ ...defaultFormData });
    const [formConditions, setFormConditions] = useState({ ...defaultFormConditions });
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const locationOptions = useSelector(selectLocationOptions);
    const [menus, setMenus] = useState([]);
    const [subMenus, setSubMenus] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedImage, setSelectedImage] = useState();
    const types = [
        "Badge",
        "Streak",
        "Category",
        "Birthday"
    ];

    useEffect(() => {
        const newFormData = {
            ...formData
        }
        if (selectedReward._id) {
            newFormData._id = selectedReward._id;
        }
        if (selectedReward.type) {
            newFormData.type = selectedReward.type;
        }
        if (selectedReward.title) {
            newFormData.title = selectedReward.title;
        }
        if (selectedReward.image) {
            newFormData.image = selectedReward.image;
        }
        if (selectedReward.badgeStatus) {
            newFormData.badgeStatus = selectedReward.badgeStatus;
        }
        if (selectedReward.streak) {
            newFormData.streak = selectedReward.streak;
        }
        if (selectedReward.discountPercentage) {
            newFormData.discountPercentage = selectedReward.discountPercentage;
            formConditions.discountPercentage = true;
            formConditions.discount = true;
        }
        if (selectedReward.discountAmount) {
            newFormData.discountAmount = selectedReward.discountAmount;
            formConditions.discountPercentage = false;
            formConditions.discount = true;
        }
        if (selectedReward.walletAmount) {
            newFormData.walletAmount = selectedReward.walletAmount;
            formConditions.cashback = true;
        }
        if (selectedReward.birchBucks) {
            newFormData.birchBucks = selectedReward.birchBucks;
            formConditions.cashback = true;
        }
        if (selectedReward.itemId) {
            formConditions.item = true;
        }

        setFormData({ ...newFormData });
        setFormConditions({ ...formConditions });
        populateSelectedItem();
    }, [selectedReward]);

    useEffect(() => {
        if (selectedItem?.locationId) {
            populateMenuDropDown();
        } else {
            setMenus([]);
        }

        if (selectedItem?.menuId) {
            populateSubMenusDropDown();
        } else {
            setSubMenus([]);
        }

        if (selectedItem?.menuId && selectedItem?.subMenuId) {
            populateItemsDropdown();
        } else {
            setItems([]);
        }
    }, [selectedItem]);

    const populateMenuDropDown = () => {
        getMenus(selectedItem.locationId)
            .then((response) => {
                const responseData = response.data.data;
                if (responseData.menu) {
                    setMenus(responseData.menu);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const populateSubMenusDropDown = () => {
        getSubMenus(selectedItem.menuId)
            .then((response) => {
                const responseData = response.data.data;
                if (responseData.subMenu) {
                    setSubMenus(responseData.subMenu);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const populateItemsDropdown = () => {
        setLoading(true);
        getItems(selectedItem.menuId, selectedItem.subMenuId)
            .then((response) => {
                const responseData = response.data.data;
                if (responseData.items) {
                    setItems(Object.values(responseData.items));
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    };

    const populateSelectedItem = () => {
        if (selectedReward?._id) {
            selectedItem.locationId = selectedReward.locationId;
            selectedItem.menuId = selectedReward.menuId;
            selectedItem.subMenuId = selectedReward.submenuId;
            selectedItem.itemId = selectedReward.itemId;
            setSelectedItem({ ...selectedItem });
        }
    };

    const handleChangeDropdown = (event, key) => {
        if (key == "locationId") {
            selectedItem[key] = event.value;
        } else {
            selectedItem[key] = event.target.value;
        }
        setSelectedItem({ ...selectedItem });
        if (formData) {
            formData.saving = true;
            setFormData({ ...formData });
        }
    };

    const handleSaveFormSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};

        if (!formData?.type) {
            newErrors["type"] = "Please choose a type";
        }
        if (!formData?.title) {
            newErrors["title"] = "Please fill title";
        }
        if (formData.type == "Badge") {
            if (!formData?.badgeStatus.totalSpendAmount) {
                newErrors["badgeStatus.totalSpendAmount"] = "Please fill amount on spending";
            }

            if (formConditions?.discount && !formData.badgeStatus.discountOrderNumber) {
                newErrors["badgeStatus.discountOrderNumber"] =
                    "Please fill discount on which order";
            }

            if (formConditions?.item && !formData.badgeStatus.itemOrderNumber) {
                newErrors["badgeStatus.itemOrderNumber"] =
                    "Please fill item on which order";
            }
        } else if (formData.type == "Streak") {
            if (!formData.streak.days) {
                newErrors["streak.days"] = "Please enter streak days";
            }
        } else if (formData.type == "Category") {

        }
        if (formConditions.discount) {
            if (formConditions.discountPercentage && !formData.discountPercentage) {
                newErrors["discountPercentage"] = "Please fill discount in percentage";
            }
            if (!formConditions.discountPercentage && !formData.discountAmount) {
                newErrors["discountAmount"] = "Please fill flat discount";
            }
        }
        if (formConditions.cashback) {
            if (!formData.walletAmount && !formData.birchBucks) {
                newErrors["walletAmount"] = "Please fill wallet amount / birch bucks";
                newErrors["birchBucks"] = "Please fill birch bucks / wallet amount";
            }
        }
        if (formConditions.item) {
            if (!selectedItem.itemId) {
                newErrors["itemId"] = "Please select an item";
            }
        }
        if (!formConditions.discount && !formConditions.cashback && !formConditions.item) {
            // enable atleast one
            newErrors["formConditions"] = "Enable atleast one from Discount/Cashback/Item";
        }
        setErrors({ ...newErrors });
        if (!newErrors || !Object.values(newErrors).length) {
            setLoading(true);
            const request = {
                type: formData.type,
                title: formData.title,
            };
            if (formData.type == "Badge") {
                request.badgeStatus = {
                    totalSpendAmount: formData.badgeStatus.totalSpendAmount
                };
                if (formConditions.discount) {
                    request.badgeStatus.discountOrderNumber = formData.badgeStatus.discountOrderNumber;
                }
                if (formConditions.cashback) {
                    request.badgeStatus.cashbackOrderNumber = formData.badgeStatus.cashbackOrderNumber;
                }
                if (formConditions.item) {
                    request.badgeStatus.itemOrderNumber = formData.badgeStatus.itemOrderNumber;
                }
            }
            if (formData.type == "Streak") {
                request.streak = {
                    days: formData.streak.days
                };
            }
            if (formConditions.discount) {
                if (formConditions.discountPercentage) {
                    request.discountPercentage = formData.discountPercentage;
                    request.discountAmount = 0;
                } else {
                    request.discountAmount = formData.discountAmount;
                    request.discountPercentage = 0;
                }
            }
            if (formConditions.cashback) {
                if (formData.walletAmount) {
                    request.walletAmount = formData.walletAmount;
                }
                if (formData.birchBucks) {
                    request.birchBucks = formData.birchBucks;
                }
            }
            if (formConditions.item) {
                request.locationId = selectedItem.locationId;
                request.menuId = selectedItem.menuId;
                request.submenuId = selectedItem.subMenuId;
                request.itemId = selectedItem.itemId;
            }
            if (!!selectedImage?.source) {
                // upload image
                const name = await uploadImageToS3();
                if (!name) {
                    setLoading(false);
                    return;
                }
            }
            request.image = formData.image;
            if (formData._id) {
                // editing
                request.rewardId = formData._id;
                editReward(request)
                    .then((response) => {
                        swal("Good job!", "Successfully Updated", "success");
                        setFormData({ ...defaultFormData });
                        setFormConditions({ ...defaultFormConditions });
                        setSelectedItem({});
                        setLoading(false);
                        save();
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.error(error);
                        swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                    });
            } else {
                addReward(request)
                    .then((response) => {
                        swal("Good job!", "Successfully Created", "success");
                        setFormData({ ...defaultFormData });
                        setFormConditions({ ...defaultFormConditions });
                        setSelectedItem({});
                        setLoading(false);
                        save();
                    })
                    .catch((error) => {
                        setLoading(false);
                        console.error(error);
                        swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                    });
            }
        }
    };

    const handleChangeField = (event, key, obj = null) => {
        let value = event && event.target ? event.target.value : event;
        if (["discountPercentage", "discountOrderNumber",
            "cashbackOrderNumber", "itemOrderNumber"].includes(key) && value < 0) {
            value = 0;
        }
        if (obj) {
            obj[key] = value;
        } else {
            formData[key] = value;
        }

        formData.saving = true;
        setFormData({ ...formData });
        setErrors({});
    };

    const handleCancel = () => {
        setErrors({});
        setFormData(null);
        cancel();
    };

    const handleImageUpload = (event) => {
        setSelectedImage({
            source: event.target.files[0],
            fileURL: URL.createObjectURL(event.target.files[0]),
        });
        formData.saving = true;
        setFormData({ ...formData });
    };

    const uploadImageToS3 = async () => {
        const newErrors = {};
        if (!selectedImage?.source) {
            newErrors["uploadImage"] = "Please upload an image from your computer";
            setErrors({ ...newErrors });
            return;
        }
        let newFileName = randomStr(12) + "_" + selectedImage?.source?.name;
        try {
            const data = await ReactS3Client.uploadFile(
                selectedImage?.source,
                newFileName
            );
            setSelectedImage(undefined);
            formData.image = data.key;
            setFormData({ ...formData });
            return data.key;
        } catch (err) {
            console.error(err);
            newErrors["uploadImage"] = "Something went wrong with this image";
            setErrors({ ...newErrors });
            return;
        }
    };

    return (
        <form className="position-relative">
            {loading && (
                <div className="loading-overlay pb-5">
                    <Spinner className="mb-5" animation="border" variant="primary" />
                </div>
            )}
            <div className="add-contact-box bordered-form-box">
                <div className="add-contact-content">
                    <div className="form-group px-2 py-3 border-bottom">
                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                            <div className="d-flex">
                                <h4 className="text-black font-w500 mb-0">Reward Type</h4>
                                {!!errors?.type && <span className="ml-2 validation-text text-danger">({errors?.type})</span>}
                            </div>
                            <div>
                                <select
                                    className="form-control w-100 text-capitalize"
                                    onChange={(event) => handleChangeField(event, "type")}
                                    required
                                >
                                    <option value="" selected>
                                        Choose Type
                                    </option>
                                    {types.map((opt) => {
                                        return (
                                            <option
                                                value={opt}
                                                selected={opt === formData?.type}
                                            >
                                                {opt}
                                            </option>
                                        );
                                    })}
                                    ;
                                </select>
                            </div>
                        </div>
                    </div>
                    {!!formData.type &&
                        <div className="mb-5">
                            <div className="form-group px-2 border-bottom">
                                <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                    <div className="d-flex">
                                        <h4 className="text-black font-w500 mb-0">Upload Image</h4>
                                        {!!errors?.uploadImage && <span className="ml-2 validation-text text-danger">({errors?.uploadImage})</span>}
                                    </div>
                                    <div className="ml-auto">
                                        <input
                                            type="file"
                                            autoComplete="off"
                                            onChange={handleImageUpload}
                                        />
                                    </div>
                                    <div className="image-wrapper">
                                        <img
                                            src={
                                                selectedImage?.fileURL ||
                                                (formData?.image ? s3Url + "/" + formData?.image : noImg)
                                            }
                                            width={"100%"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group px-2 py-2 border-bottom">
                                <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                    <div className="d-flex">
                                        <h4 className="text-black font-w500 mb-0">Title</h4>
                                        {!!errors?.title && <span className="ml-2 validation-text text-danger">({errors?.title})</span>}
                                    </div>
                                    <div className="name">
                                        <TextInput
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            required="required"
                                            value={formData?.title}
                                            onChange={(event) => handleChangeField(event, "title")}
                                            placeholder="Enter Title"
                                        />

                                    </div>
                                </div>
                            </div>
                            {formData.type == "Badge" &&
                                <>
                                    <div className="d-flex align-items-center justify-content-between pr-2">
                                        <h3 className="form-title">
                                            Badge Condition
                                        </h3>
                                    </div>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <div className="d-flex">
                                                <h4 className="text-black font-w500 mb-0">Spending Amount</h4>
                                                {!!errors['badgeStatus.totalSpendAmount'] && <span className="ml-2 validation-text text-danger">({errors['badgeStatus.totalSpendAmount']})</span>}
                                            </div>
                                            <div className="name">
                                                <CurrencyInput
                                                    className="form-control"
                                                    prefix="$"
                                                    decimalsLimit={2}
                                                    onKeyDown={preventMinus}
                                                    min={0}
                                                    placeholder="Enter spending amount"
                                                    defaultValue={formData?.badgeStatus.totalSpendAmount}
                                                    onValueChange={(value) => handleChangeField(value, "totalSpendAmount", formData.badgeStatus)}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            {formData.type == "Streak" &&
                                <>
                                    <div className="d-flex align-items-center justify-content-between pr-2">
                                        <h3 className="form-title">
                                            Streak Condition
                                        </h3>
                                    </div>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <div className="d-flex">
                                                <h4 className="text-black font-w500 mb-0">Streak day</h4>
                                                {!!errors["streak.days"] && <span className="ml-2 validation-text text-danger">({errors["streak.days"]})</span>}
                                            </div>
                                            <div className="name">
                                                <NumberInput
                                                    className="form-control"
                                                    autoComplete="off"
                                                    value={formData?.streak.days}
                                                    onChange={(event) =>
                                                        handleChangeField(event, "days", formData.streak)
                                                    }
                                                    placeholder="Enter streak day"
                                                    min={0}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="d-flex align-items-center justify-content-between pr-2">
                                <h3 className="form-title">
                                    Discount
                                </h3>
                                <div className="mt-2">
                                    <BootstrapSwitchButton
                                        checked={formConditions.discount}
                                        onlabel='Yes'
                                        offlabel='No'
                                        size="sm"
                                        onChange={(checked) => setFormConditions({ ...formConditions, discount: !formConditions.discount })}
                                    />
                                </div>
                            </div>
                            {formConditions.discount &&
                                <>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <div className="d-flex">
                                                <h4 className="text-black font-w500 mb-0">In %age or flat?</h4>
                                            </div>
                                            <div>
                                                <BootstrapSwitchButton
                                                    checked={formConditions.discountPercentage}
                                                    onlabel='%age'
                                                    offlabel='Flat'
                                                    size="sm"
                                                    onChange={(checked) => setFormConditions({ ...formConditions, discountPercentage: !formConditions.discountPercentage })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {!!formConditions.discountPercentage &&
                                        <div className="form-group px-2 py-2 border-bottom">
                                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                                <div className="d-flex">
                                                    <h4 className="text-black font-w500 mb-0">Discount in Percentage</h4>
                                                    {!!errors?.discountPercentage && <span className="ml-2 validation-text text-danger">({errors.discountPercentage})</span>}
                                                </div>
                                                <div>
                                                    <NumberInput
                                                        className="form-control w-100"
                                                        autoComplete="off"
                                                        value={formData?.discountPercentage}
                                                        onChange={(event) => handleChangeField(event, "discountPercentage")}
                                                        placeholder="Enter Discount in %age"
                                                        min={0} max={100} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!formConditions.discountPercentage &&
                                        <div className="form-group px-2 py-2 border-bottom">
                                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                                <div className="d-flex">
                                                    <h4 className="text-black font-w500 mb-0">Flat Discount</h4>
                                                    {!!errors?.discountAmount && <span className="ml-2 validation-text text-danger">({errors.discountAmount})</span>}
                                                </div>
                                                <div>
                                                    <CurrencyInput
                                                        className="form-control"
                                                        prefix="$"
                                                        decimalsLimit={2}
                                                        onKeyDown={preventMinus}
                                                        placeholder="Enter Flat Discount"
                                                        defaultValue={formData?.discountAmount}
                                                        onValueChange={(value) => handleChangeField(value, "discountAmount")}
                                                        autoComplete="off"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {formData.type == "Badge" &&
                                        <div className="form-group px-2 py-2 border-bottom">
                                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                                <div className="d-flex">
                                                    <h4 className="text-black font-w500 mb-0">Give discount on which order?</h4>
                                                    {!!errors['badgeStatus.discountOrderNumber'] && <span className="ml-2 validation-text text-danger">({errors['badgeStatus.discountOrderNumber']})</span>}
                                                </div>
                                                <div>
                                                    <NumberInput
                                                        className="form-control which-input"
                                                        autoComplete="off"
                                                        value={formData?.badgeStatus.discountOrderNumber}
                                                        onChange={(event) =>
                                                            handleChangeField(event, "discountOrderNumber", formData.badgeStatus)
                                                        }
                                                        placeholder="which"
                                                        min={0} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                            <div className="d-flex align-items-center justify-content-between pr-2">
                                <h3 className="form-title">
                                    Cashback
                                </h3>
                                <div className="mt-2">
                                    <BootstrapSwitchButton
                                        checked={formConditions.cashback}
                                        onlabel='Yes'
                                        offlabel='No'
                                        size="sm"
                                        onChange={(checked) => setFormConditions({ ...formConditions, cashback: !formConditions.cashback })}
                                    />
                                </div>
                            </div>

                            {formConditions.cashback &&
                                <>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <div className="d-flex">
                                                <h4 className="text-black font-w500 mb-0">Wallet Amount</h4>
                                                {!!errors?.walletAmount && <span className="ml-2 validation-text text-danger">({errors.walletAmount})</span>}
                                            </div>
                                            <div>
                                                <CurrencyInput
                                                    className="form-control"
                                                    prefix="$"
                                                    decimalsLimit={2}
                                                    onKeyDown={preventMinus}
                                                    placeholder="Enter Wallet Amount"
                                                    defaultValue={formData?.walletAmount}
                                                    onValueChange={(value) => handleChangeField(value, "walletAmount")}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <div className="d-flex">
                                                <h4 className="text-black font-w500 mb-0">Birch Bucks</h4>
                                                {!!errors?.birchBucks && <span className="ml-2 validation-text text-danger">({errors.birchBucks})</span>}
                                            </div>
                                            <div>
                                                <CurrencyInput
                                                    className="form-control"
                                                    prefix="$"
                                                    decimalsLimit={2}
                                                    onKeyDown={preventMinus}
                                                    placeholder="Enter Birch Bucks"
                                                    defaultValue={formData?.birchBucks}
                                                    onValueChange={(value) => handleChangeField(value, "birchBucks")}
                                                    autoComplete="off"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {formData.type == "Badge" &&
                                        <div className="form-group px-2 py-2 border-bottom">
                                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                                <div className="d-flex">
                                                    <h4 className="text-black font-w500 mb-0">Give cashback on which order?</h4>
                                                    {!!errors['badgeStatus.cashbackOrderNumber'] && <span className="ml-2 validation-text text-danger">({errors['badgeStatus.discountOrderNumber']})</span>}
                                                </div>
                                                <div>
                                                    <NumberInput
                                                        className="form-control which-input"
                                                        autoComplete="off"
                                                        value={formData?.badgeStatus.cashbackOrderNumber}
                                                        onChange={(event) =>
                                                            handleChangeField(event, "cashbackOrderNumber", formData.badgeStatus)
                                                        }
                                                        placeholder="which"
                                                        min={0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }

                            <div className="d-flex align-items-center justify-content-between pr-2">
                                <h3 className="form-title">
                                    Item
                                </h3>
                                <div className="mt-2">
                                    <BootstrapSwitchButton
                                        checked={formConditions.item}
                                        onlabel='Yes'
                                        offlabel='No'
                                        size="sm"
                                        onChange={(checked) => setFormConditions({ ...formConditions, item: !formConditions.item })}
                                    />
                                </div>
                            </div>

                            {/* locations, menus, sub menus and items */}
                            {formConditions.item &&
                                <>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <h4 className="text-black font-w500 mb-0">Location</h4>
                                            <div className="name">
                                                <Select
                                                    className="form-control-location"
                                                    onChange={(option) =>
                                                        handleChangeDropdown(option, "locationId")
                                                    }
                                                    options={locationOptions}
                                                    value={locationOptions.filter(
                                                        (opt) => opt.value == selectedItem?.locationId
                                                    )}
                                                    placeholder="Choose location to select an item"
                                                    theme={(theme) => ({
                                                        ...theme,
                                                        borderRadius: 0,
                                                        border: "none",
                                                        colors: {
                                                            ...theme.colors,
                                                            text: "white",
                                                            primary25: "#ffeae6",
                                                            primary: "#FF3333",
                                                        },
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <div className="d-flex">
                                                <h4 className="text-black font-w500 mb-0">
                                                    Choose a menu
                                                </h4>
                                                {!!errors?.menuId && (
                                                    <span className="ml-2 validation-text text-danger">
                                                        ({errors?.menuId})
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <select
                                                    className="form-control text-capitalize"
                                                    onChange={(event) => handleChangeDropdown(event, "menuId")}
                                                    required
                                                >
                                                    <option value="" selected>
                                                        Choose menu
                                                    </option>
                                                    {menus.map((opt) => {
                                                        return (
                                                            <option
                                                                value={opt._id}
                                                                selected={opt._id === selectedItem?.menuId}
                                                            >
                                                                {opt.categoryData.name}
                                                            </option>
                                                        );
                                                    })}
                                                    ;
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <div className="d-flex">
                                                <h4 className="text-black font-w500 mb-0">
                                                    Choose a sub-menu
                                                </h4>
                                                {!!errors?.subMenuId && (
                                                    <span className="ml-2 validation-text text-danger">
                                                        ({errors?.subMenuId})
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <select
                                                    className="form-control ml-2 text-capitalize"
                                                    onChange={(event) => handleChangeDropdown(event, "subMenuId")}
                                                    required
                                                >
                                                    <option value="" selected>
                                                        Choose sub menu
                                                    </option>
                                                    {subMenus.map((opt) => {
                                                        return (
                                                            <option
                                                                value={opt._id}
                                                                selected={opt._id === selectedItem?.subMenuId}
                                                            >
                                                                {opt.name}
                                                            </option>
                                                        );
                                                    })}
                                                    ;
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group px-2 py-2 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <div className="d-flex">
                                                <h4 className="text-black font-w500 mb-0">Choose Item</h4>
                                                {!!errors?.itemId && (
                                                    <span className="ml-2 validation-text text-danger">
                                                        ({errors?.itemId})
                                                    </span>
                                                )}
                                            </div>

                                            <div className="d-flex align-items-center justify-content-space">
                                                <select
                                                    className="form-control w-100 text-capitalize"
                                                    onChange={(event) => handleChangeDropdown(event, "itemId")}
                                                    required
                                                >
                                                    <option value="" selected>
                                                        Choose Item
                                                    </option>
                                                    {items.map((opt) => {
                                                        return (
                                                            <option
                                                                value={opt._id}
                                                                selected={opt._id === selectedItem?.itemId}
                                                            >
                                                                {opt.itemData.name}
                                                            </option>
                                                        );
                                                    })}
                                                    ;
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    {formData.type == "Badge" &&
                                        <div className="form-group px-2 py-2 border-bottom">
                                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                                <div className="d-flex">
                                                    <h4 className="text-black font-w500 mb-0">Give item on which order?</h4>
                                                    {!!errors['badgeStatus.itemOrderNumber'] && <span className="ml-2 validation-text text-danger">({errors['badgeStatus.itemOrderNumber']})</span>}
                                                </div>
                                                <div>
                                                    <NumberInput
                                                        className="form-control which-input"
                                                        autoComplete="off"
                                                        value={formData?.badgeStatus.itemOrderNumber}
                                                        onChange={(event) =>
                                                            handleChangeField(event, "itemOrderNumber", formData.badgeStatus)
                                                        }
                                                        placeholder="which"
                                                        min={0}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                            <div className="form-group py-2">
                                {!!errors?.formConditions && (
                                    <span className="validation-text text-danger fs-20">
                                        {errors?.formConditions}
                                    </span>
                                )}
                            </div>
                        </div>
                    }
                    {!!formData?.saving && (
                        <div className="card-footer text-center save-cancel">
                            <button
                                type="button"
                                onClick={() => handleCancel()}
                                className="btn btn-light mr-3 w-25"
                            >
                                {" "}
                                <i className="flaticon-delete-1"></i> Cancel
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary w-25"
                                onClick={handleSaveFormSubmit}
                            >
                                Save
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </form>
    );
};

export default NewRewardsSettings;
