// import S3 from 'react-aws-s3';
import AWS from "aws-sdk";

export const s3Url = process.env.REACT_APP_S3_US_EAST_BASE_URL;

AWS.config.update({
  region: process.env.REACT_APP_S3_COGNITO_POOL_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_S3_COGNITO_POOL_ID,

  }),
});
export const ReactS3Client = {
    uploadFile: async(file, fileName, albumName = "develop")=> {
          let photoKey = albumName + "/" + fileName;
        
          let upload = new AWS.S3.ManagedUpload({
            params: {
              Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
              Key: photoKey,
              Body: file,
              ACL: "public-read",
            },
          });     
          let promise = await upload.promise();
          promise.imageName = fileName;
          return promise;
        }
}