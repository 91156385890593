import axiosInstance from './AxiosInstance';

export function getLocations() {
    return axiosInstance.get(`/admin/locations`);
}

export function getSquareLocations() {
    return axiosInstance.get(`/admin/squareLocations`);
}

export function getLocationDetail(locationId) {
    return axiosInstance.get(`/admin/location?locationId=${locationId}`);
}

export function addLocation(locationData) {
    return axiosInstance.post(`/admin/locations`, locationData);
}

export function editLocation(locationData) {
    return axiosInstance.put(`/admin/locations`, locationData);
}

export function deleteLocation(locationId) {
    return axiosInstance.delete(`/admin/locations?locationId=${locationId}`);
}

export function changePassword(oldPassword, newPassword) {
    const request = {
        oldPassword,
        newPassword
    };
    return axiosInstance.put('/admin/auth/changePassword', request);
}