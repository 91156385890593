import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const ModifierListModal = ({ showModal, setShowModal, modifierLists, setModifierLists, save }) => {

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        var error = false;
        if (!error) {
            save(modifierLists);
        }

    };

    const handleCheckbox = (key) => {
        modifierLists[key]['checked'] = !modifierLists[key]['checked'];
        setModifierLists([...modifierLists]);
    };

    const handleCancel = () => {
        setShowModal(false);
    };
    return (
        <Modal className="modal fade flex-modal" show={!!showModal} onHide={handleCancel} >
            {!!showModal && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">Select Modifiers</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="add-contact-box">
                        {modifierLists.map((x, i) => {
                            return (
                                <div key={i} className="custom-control custom-checkbox mb-3 checkbox-primary check-xl" onClick={() => handleCheckbox(i)}>
                                    <input type="checkbox" className="custom-control-input" name={x.modifierListData.name} value={x?._id} checked={!!x.checked} />
                                    <label className="custom-control-label pl-3 pt-1 text-capitalize">{x.modifierListData.name}</label>
                                </div>);
                        })}
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>}
        </Modal>
    );
};

export default ModifierListModal;
