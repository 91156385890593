import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import swal from 'sweetalert';
import { refundOrderAPI } from '../../services/DashboardService';
import { currencyFormat, toFixedNumber } from '../../services/UtilsService';

const RequestRefundModal = ({ refundOrder, cancel }) => {
    const [formData, setFormData] = useState({
        type: 'full',
        refund_amount: 0.00,
        errors: {},
        max_refund_amount: null
    });

    useEffect(() => {
        if (refundOrder) {
            calculateDefaultRefundAmount();
        }
    }, [refundOrder]);

    const calculateDefaultRefundAmount = () => {
        formData.refund_amount = refundOrder.totalMoney?.amount + refundOrder.totalTaxMoney?.amount + refundOrder.totalTipMoney?.amount - refundOrder.totalDiscountMoney?.amount;
        formData.max_refund_amount = refundOrder.totalMoney?.amount + refundOrder.totalTaxMoney?.amount + refundOrder.totalTipMoney?.amount - refundOrder.totalDiscountMoney?.amount;
        formData.refund_amount = toFixedNumber(parseFloat(formData.refund_amount), 2);
        formData.max_refund_amount = toFixedNumber(parseFloat(formData.max_refund_amount), 2);
        if (formData.refund_amount <= 0) {
            formData.refund_amount = 0;
            formData.errors['refund_amount'] = 'Order amount is 0';
        }
        setFormData({ ...formData });
    };

    const handleChangeField = (value, key) => {
        formData.errors = {};
        if (key == "type" && value == "full") {
            calculateDefaultRefundAmount();
        } else if(key == "type") {
            formData.refund_amount = null;
        }
        
        if(key == "refund_amount") {
            if(value <= 0) {
                value = 0;
            }
            if(value >= formData.max_refund_amount) {
                value = formData.max_refund_amount - 0.01;
            }
        }
        formData[key] = value;
        setFormData({ ...formData });
    };

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        formData.errors = {};
        setFormData({ ...formData });
        if (!formData?.refund_amount) {
            formData.errors['refund_amount'] = "Please fill refund amount";
        }
        setFormData({ ...formData });
        if (!formData.errors || !Object.values(formData.errors).length) {
            const request = {
                orderId: refundOrder._id,
                amount: formData.refund_amount
            }
            refundOrderAPI(request).then(response => {
                swal('Good job!', 'Refund inititaled successfully', "success");
                handleCancel();
            }).catch(error => {
                console.error(error);
                swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
            });
        }
    };

    const handleCancel = () => {
        setFormData({
            type: 'full',
            amount: 0.00,
            errors: {}
        });
        cancel();
    }
    return (
        <Modal className="modal fade flex-modal" show={!!refundOrder} onHide={handleCancel} >
            {!!refundOrder && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">Refund Order</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content d-flex align-items-center mb-4">
                            <div className="custom-control custom-radio radio-primary check-xl" onClick={() => handleChangeField('full', 'type')}>
                                <input type="radio" className="custom-control-input" name="type" value="full" checked={formData.type == "full"} />
                                <label className="custom-control-label pl-3 pt-1">Full Refund</label>
                            </div>
                            <div className="custom-control custom-radio ml-3 radio-primary check-xl" onClick={() => handleChangeField('partial', 'type')}>
                                <input type="radio" className="custom-control-input" name="type" value="partial" checked={formData.type == "partial"} />
                                <label className="custom-control-label pl-3 pt-1">Partial Refund</label>
                            </div>
                        </div>
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Refund Amount</label>
                                <div className="name">
                                    {formData.type == "full" ? currencyFormat(formData.refund_amount) :
                                        <CurrencyInput id="input-example"
                                            className="form-control"
                                            prefix="$"
                                            decimalsLimit={2}
                                            placeholder={`Enter partial refund amount (< $${formData.max_refund_amount.toFixed(2)})`}
                                            value={formData.refund_amount}
                                            onValueChange={(value) => { handleChangeField(value, 'refund_amount') }}
                                            autoComplete='off'
                                        />
                                    }
                                    <span className="validation-text text-danger">{formData.errors?.refund_amount}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>}
        </Modal>
    );
};

export default RequestRefundModal;
