import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from "sweetalert";
import noImg from "../../img/no-image-available.png";
import { s3Url } from '../../services/AWSS3';
import { getDiscountRequestCategories, updateUserDiscountCategory } from '../../services/DiscountRequestsService';
import { currencyFormat } from '../../services/UtilsService';

const ChangeDiscountCategoryModal = ({ selectedDiscountCategory, setSelectedDiscountCategory, save, cancel }) => {
    const [discountCategories, setDiscountCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoryId, setCategoryId] = useState(null);
    const [categoryError, setCategoryError] = useState("");

    useEffect(() => {
        populateDiscountCategoriesData();
    }, []);
    const populateDiscountCategoriesData = () => {
        setLoading(true);
        getDiscountRequestCategories().then(response => {
            const responseData = response.data;
            setDiscountCategories(responseData.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        var error = false;
        if (!categoryId) {
            error = true;
            setCategoryError('Please choose discount category');
        }
        if (!error) {
            updateUserDiscountCategory(categoryId, selectedDiscountCategory.userId).then((response) => {
                swal('Good job!', 'Successfully Updated', "success");
                save();
            }).catch(error => {
                console.error(error);
                swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
            });
        }

    };

    const handleCancel = () => {
        setCategoryId(null);
        setCategoryError("");
        cancel();
    };

    const handleCheckbox = (_id) => {
        setCategoryId(_id);
    };
    return (
        <Modal className="modal fade flex-modal" show={!!selectedDiscountCategory} onHide={handleCancel} >
            <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">{!!selectedDiscountCategory?.category ? 'Edit' : 'Set'} Discount Category</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <div className="name">
                                    {discountCategories.map((x, i) => {
                                        return (
                                            <div key={i} className="custom-control custom-checkbox mb-3 checkbox-primary check-xl" onClick={() => handleCheckbox(x._id)}>
                                                <input type="checkbox" className="custom-control-input" name={x.title} value={x?._id} checked={x._id == categoryId} />
                                                <label className="custom-control-label pl-3 pt-1 text-capitalize">
                                                    <div className="media d-flex align-items-center">
                                                        <div className="avatar avatar-xl mr-2">
                                                            <img
                                                                className="rounded-circle img-fluid"
                                                                src={!!x.image ? `${s3Url}/${x.image}` : noImg}
                                                                style={{ width: "26px", height: "26px" }}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-0 fs--1">
                                                                {x.title}
                                                                &nbsp;
                                                                {!!x.discountPercentage && <span>(Discount: {x.discountPercentage}%)</span>}
                                                                {!!x.discountAmount && <span>(Discount: {currencyFormat(x.discountAmount, 2)})</span>}
                                                                {!!x.walletAmount && <span>(Wallet: {currencyFormat(x.discountAmount, 2)})</span>}
                                                                {!!x.birchBucks && <span>(Birch Coins: {x.birchBucks})</span>}
                                                                {x._id == selectedDiscountCategory?._id &&
                                                                    <small className='ml-2 text-primary'>Current</small>
                                                                }
                                                            </h5>

                                                        </div>
                                                    </div>
                                                </label>
                                            </div>);
                                    })}
                                    <span className="validation-text text-danger">{categoryError}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>
        </Modal>
    );
};

export default ChangeDiscountCategoryModal;
