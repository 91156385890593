import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import swal from "sweetalert";
import NewItem from "./NewItem";
import { deleteItem, getItems, itemsOrder, toggleItemAvailable } from "../../services/ItemService";
import { getMenus } from "../../services/MenuService";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Items = () => {
    const params = useParams();
    const [items, setItems] = useState([]);
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(false);
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [selectedItem, setSelectedItem] = useState(null);
    const { selectedMenu: breadcrumbMenu, selectedSubMenu: breadcrumbSubMenu, onLocationId } = useSelector((state) => state.breadcrumbs);
    const [willRearrange, setWillRearrange] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (onLocationId != locationId) {
            history.push('/menu');
        }
    }, [locationId]);

    useEffect(() => {
        if (locationId) {
            populateItemsData();
            populateMenuDropDown();
        }
    }, []);

    const populateItemsData = () => {
        setLoading(true);
        getItems(params.id, params.id2).then(response => {
            const responseData = response.data.data;
            if (responseData.items) {
                setItems(Object.values(responseData.items));
            }
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const populateMenuDropDown = () => {
        getMenus(locationId).then(response => {
            const responseData = response.data.data;
            if (responseData.menu) {
                setMenus(responseData.menu);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const onOff = (checked, id) => {
        toggleItemAvailable(id, checked).then(response => {
            const newItems = items.map(x => {
                if (x._id == id) {
                    x.itemData.isAvailable = checked;
                }
                return x;
            });
            setItems([...newItems]);
        }).catch(error => {
            console.error(error);
        });
    };

    const handleEditClick = (event, item) => {
        const selectedItem = {
            _id: item._id,
            name: item.itemData.name,
            menuId: params.id,
            subMenuId: params.id2,
            description: item.itemData.description,
            isTaxExempt: item.itemData.isTaxExempt,
            sizes: item.itemData.variations,
            modifierLists: item.itemData.modifierListInfo,
            saving: false
        };
        setSelectedItem(selectedItem);
    };

    const handleCopyClick = (item) => {
        const selectedItem = {
            name: item.itemData.name,
            menuId: params.id,
            subMenuId: params.id2,
            description: item.itemData.description,
            isTaxExempt: item.itemData.isTaxExempt,
            sizes: item.itemData.variations.map(size => {
                delete size.variationId;
                delete size._id;
                return size;
            }),
            modifierLists: item.itemData.modifierListInfo,
            saving: true
        };
        setSelectedItem(selectedItem);
    };

    const newItem = (event) => {
        setSelectedItem({
            name: "New Item",
            menuId: params.id,
            subMenuId: params.id2,
            description: "",
            isTaxExempt: false,
            sizes: [],
            modifierLists: [],
            saving: true
        });
    }

    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the item",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteItem(id).then(response => {
                    const newItems = items.filter(x => {
                        return x._id !== id;
                    });
                    setItems([...newItems]);
                    swal(
                        "Poof! Your item is deleted",
                        {
                            icon: "success",
                        }
                    );
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const cancelModal = () => {
        setSelectedItem(null);
        // refresh
        populateItemsData();
    };

    const saveModal = (updatedItem) => {
        setSelectedItem(null);
        // refresh
        populateItemsData();
    };

    const stopDragging = (destinationIndex, sourceIndex) => {
        if (sourceIndex == destinationIndex) {
            return;
        }
        let newItems = items.sort(compare).map((item, index) => {
            item.orderNumber = index + 1;
            if (item.orderNumber == sourceIndex) {
                return {
                    ...item,
                    orderNumber: destinationIndex
                }
            }
            if (sourceIndex < destinationIndex && item.orderNumber <= destinationIndex) {
                return {
                    ...item,
                    orderNumber: item.orderNumber - 1
                }
            }

            if (sourceIndex > destinationIndex && item.orderNumber >= destinationIndex) {
                return {
                    ...item,
                    orderNumber: item.orderNumber + 1
                }
            }
            return item;
        });
        setItems([]);
        setTimeout(() => {
            setItems([...newItems]);
            setWillRearrange(true);
        }, 0);
    };

    const compare = (a, b) => {
        return a.orderNumber - b.orderNumber;
    }

    const handleCancelRerrange = () => {
        setItems([]);
        setWillRearrange(false);
        populateItemsData();
    };

    const handleSaveRearrange = () => {
        setLoading(true);
        const request = items.sort(compare).map((item, index) => {
            return {
                itemId: item._id,
                orderNumber: index + 1
            }
        });
        itemsOrder(request).then(response => {
            setLoading(false);
            setWillRearrange(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        stopDragging(destination.index, source.index);
    }

    return (
        <>
            <div className="form-head d-flex mb-0 align-items-start">
                <div className="mr-auto">
                    {!selectedItem &&
                        <h2 className="text-black font-w600 mb-0">
                            Items
                        </h2>
                    }
                    {!!selectedItem &&
                        <h2 className="text-black font-w600 mb-0 text-capitalize">
                            {selectedItem?.name || 'New Item'}
                        </h2>
                    }
                    <ol className="breadcrumb d-none d-md-flex d-lg-flex">
                        <li className="breadcrumb-item">
                            <Link to="/menu">
                                Main
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/menu/menus">
                                Menus
                            </Link>
                        </li>
                        {!!breadcrumbMenu?.categoryData?.name &&
                            <li className="breadcrumb-item text-capitalize">
                                {breadcrumbMenu?.categoryData?.name}
                            </li>
                        }
                        <li className="breadcrumb-item">
                            <Link to={`/menu/menus/${params.id}/submenus`}>
                                Sub Menus
                            </Link>
                        </li>
                        {!!breadcrumbSubMenu?.name &&
                            <li className="breadcrumb-item text-capitalize">
                                {breadcrumbSubMenu?.name}
                            </li>
                        }
                        {!!selectedItem &&
                            <li className="breadcrumb-item">
                                <Link to="#" onClick={() => cancelModal()}>
                                    Items
                                </Link>
                            </li>
                        }
                    </ol>
                </div>
                {!selectedItem && <>
                    <Link className="btn mr-2" to={`/menu/menus/${params.id}/submenus`}>
                        <i className="las la-undo mr-0 mr-lg-2"></i>
                        <span className="d-none d-lg-inline-block">Back</span>
                    </Link>
                    <Button onClick={newItem}>+ New Item</Button>
                </>}
                {!!selectedItem && <Link to="#" className="btn btn-light mr-2" onClick={cancelModal}>
                    <i className="las la-undo mr-0 mr-lg-2"></i>
                    <span className="d-none d-lg-inline-block">Cancel</span>
                </Link>}
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {loading && !items.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !items.length) &&
                        <div className="card">
                            <div className="card-body" style={{ padding: !selectedItem ? "1.25rem" : '0px' }}>
                                {!!selectedItem &&
                                    <NewItem selectedItem={selectedItem} setSelectedItem={setSelectedItem}
                                        menus={menus} cancel={cancelModal} save={saveModal}></NewItem>
                                }

                                {!selectedItem && (
                                    <div className="table-responsive no-overflow">
                                        {
                                            !items.length && <>Nothing Found</>
                                        }
                                        {
                                            !!items.length &&
                                            (
                                                <DragDropContext onDragEnd={onDragEnd}>
                                                    <table className="table mb-0 text-black" id="sortable">
                                                        <thead>
                                                            <tr>
                                                                <th className="align-middle td-sm"></th>
                                                                <th className="align-middle"></th>
                                                                <th className="align-middle text-left td-sm">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <Droppable droppableId="orders">
                                                            {(provided) => {
                                                                return (<tbody id="orders" {...provided.droppableProps} ref={provided.innerRef}>
                                                                    {items.sort(compare).map((item, index) => {
                                                                        return (
                                                                            <Draggable draggableId={item._id} index={index + 1} key={index}>
                                                                                {(provided2) => {
                                                                                    return (
                                                                                        <tr {...provided2.draggableProps}
                                                                                            {...provided2.dragHandleProps}
                                                                                            ref={provided2.innerRef}
                                                                                            key={item._id} className="btn-reveal-trigger">
                                                                                            <td className="py-2">
                                                                                                <i className="fs-32 las la-coffee"></i>
                                                                                            </td>
                                                                                            <td className="py-2">
                                                                                                <h4 className="mb-0">{item.itemData.name}</h4>
                                                                                            </td>
                                                                                            <td className="py-2 text-right">
                                                                                                <div className="d-flex align-items-center justify-content-right">
                                                                                                    <BootstrapSwitchButton
                                                                                                        checked={item.itemData.isAvailable !== false}
                                                                                                        onlabel='ON'
                                                                                                        offlabel='OFF'
                                                                                                        size="sm"
                                                                                                        onChange={(checked) => {
                                                                                                            onOff(checked, item._id);
                                                                                                        }}
                                                                                                    />
                                                                                                    <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                                                        onClick={(event) => handleEditClick(event, item)}
                                                                                                    >
                                                                                                        <i className="fa fa-pencil"></i>
                                                                                                    </Link>
                                                                                                    <Link to="#" className="btn btn-secondary shadow btn-xs sharp mr-2"
                                                                                                        onClick={() => handleCopyClick(item)}
                                                                                                    >
                                                                                                        <i className="fa fa-copy"></i>
                                                                                                    </Link>
                                                                                                    <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                                                        onClick={() => handleDeleteClick(item._id)}
                                                                                                    >
                                                                                                        <i className="fa fa-trash"></i>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }}
                                                                            </Draggable>
                                                                        );
                                                                    })}
                                                                    {provided.placeholder}
                                                                </tbody>);
                                                            }}
                                                        </Droppable>
                                                    </table>
                                                </DragDropContext>
                                            )
                                        }
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
            {!!willRearrange &&
                <div className="card-footer text-center save-cancel">
                    {!loading &&
                        <>
                            <button type="button" onClick={() => handleCancelRerrange()} className="btn btn-light mr-3 w-25"> <i className="flaticon-delete-1"></i> Cancel</button>
                            <button type="submit" className="btn btn-primary w-25" onClick={handleSaveRearrange}>Save</button>
                        </>
                    }
                    {!!loading && <Spinner animation="border" variant="primary" />}
                </div>
            }
        </>
    );
};

export default Items;