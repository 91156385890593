import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TextAreaInput } from '../reusable/TextAreaInput';

const DescriptionModal = ({ descriptionData, setDescriptionData, save }) => {
    const [descriptionError, setDescriptionError] = useState("");

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        var error = false;
        if (!descriptionData?.description) {
            error = true;
            setDescriptionError('Please fill ' + (descriptionData?.title || 'description'));
        }
        if (!error) {
            save(descriptionData.description);
        }

    };

    const handleChangeDescription = (event) => {
        descriptionData.description = event.target.value;
        setDescriptionData({ ...descriptionData });
    };

    const handleCancel = () => {
        setDescriptionError("");
        setDescriptionData(null);
    };
    return (
        <Modal className="modal fade flex-modal" show={!!descriptionData} onHide={handleCancel} >
            {!!descriptionData && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">{!!descriptionData?.editing ? 'Edit' : 'Add'} {descriptionData.title || 'Description'}</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <div className="name">
                                    <TextAreaInput className="form-control" autoComplete="off" required="required" value={descriptionData?.description} onChange={handleChangeDescription} placeholder={"Enter " + (descriptionData?.title || 'description')}></TextAreaInput>
                                    <span className="validation-text text-danger">{descriptionError}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>}
        </Modal>
    );
};

export default DescriptionModal;
