import React, { Fragment, useEffect, useState } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";

import avartar5 from "../../img/admin-avatar.png";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Link } from "react-router-dom";
import swal from "sweetalert";
import AdminModal from "../modals/AdminModal";
import { deleteAdminUser, getAdminUsers, toggleAdminUser } from "../../services/AdminUsersService";
import { humanReadableFormattedDateString } from "../../services/UtilsService";

const AdminUsers = () => {
    const [adminUsers, setAdminUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);

    useEffect(() => {
        populateAdminUsersData();
    }, []);

    const populateAdminUsersData = () => {
        setLoading(true);
        getAdminUsers().then(response => {
            const responseData = response.data;
            setAdminUsers(responseData.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onOff = (checked, id) => {
        toggleAdminUser(id, checked).then(response => {
            adminUsers.map(row => {
                if (row._id == id) {
                    row.enabled = checked;
                }
            });
            setAdminUsers([...adminUsers]);
        }).catch(error => {
            console.error(error);
        });
    };

    const handleEditClick = (event, row) => {
        setSelectedAdmin({
            _id: row._id,
            name: row.name,
            email: row.email,
            locations: row.locations,
        });
    }
    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the admin",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteAdminUser(id).then((response) => {
                    const newAdmins = adminUsers.filter(x => {
                        return x._id !== id;
                    });
                    setAdminUsers([...newAdmins]);
                    swal(
                        "Poof! Your admin is deleted",
                        {
                            icon: "success",
                        }
                    );
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const saveModal = (event) => {
        setSelectedAdmin(null);
        populateAdminUsersData();
    }
    const cancelModal = (event) => {
        setSelectedAdmin(null);
    }

    return (
        <Fragment>
            <AdminModal selectedAdmin={selectedAdmin} setSelectedAdmin={setSelectedAdmin} cancel={cancelModal} save={saveModal}></AdminModal>
            <div className="form-head d-flex mb-4 align-items-center">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Admins</h2>
                    <p className="mb-0 d-none d-md-block d-lg-block">Manage admin users and roles!</p>
                </div>
                <Button onClick={() => { setSelectedAdmin({ name: '' }) }}>+ New Admin</Button>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {loading && !adminUsers.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !adminUsers.length) &&
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {
                                        !loading && !adminUsers.length && <>Nothing Found</>
                                    }
                                    {
                                        !!adminUsers.length &&
                                        <table className="table mb-0 table-striped respon-table-data">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Locations</th>
                                                    <th>Joined</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody id="customers">
                                                {adminUsers.map(row => {
                                                    return (
                                                        <tr className="btn-reveal-trigger">
                                                            <td className="py-3">
                                                                <Link to="/ecom-customers">
                                                                    <div className="media d-flex align-items-center">
                                                                        <div className="avatar avatar-xl mr-2">
                                                                            <div className="">
                                                                                <img
                                                                                    className="rounded-circle img-fluid"
                                                                                    src={avartar5}
                                                                                    width="30"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h5 className="mb-0 fs--1">
                                                                                {row.name}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </td>
                                                            <td className="py-2">
                                                                <a href={"mailto:" + row.email}>
                                                                    {row.email}
                                                                </a>
                                                            </td>
                                                            <td>
                                                                {row.locations.length > 0 &&
                                                                    <span>{row.locations[0].name}</span>
                                                                }
                                                                {
                                                                    row.locations.length > 1 &&
                                                                    <span class="badge">+{row.locations.length - 1}</span>
                                                                }
                                                            </td>
                                                            <td className="py-2">
                                                                <label className="date-label">
                                                                    {humanReadableFormattedDateString(row.createdAt)}
                                                                </label>
                                                            </td>
                                                            <td className="py-2 text-right">
                                                                <div className="d-flex align-items-center justify-content-right">
                                                                    <BootstrapSwitchButton
                                                                        checked={row?.enabled}
                                                                        onlabel='ON'
                                                                        offlabel='OFF'
                                                                        size="sm"
                                                                        onChange={(checked) => {
                                                                            onOff(checked, row._id);
                                                                        }}
                                                                    />
                                                                    <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                        onClick={(event) => handleEditClick(event, row)}
                                                                    >
                                                                        <i className="fa fa-pencil"></i>
                                                                    </Link>
                                                                    <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                        onClick={() => handleDeleteClick(row._id)}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default AdminUsers;
