import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import CurrencyInput from 'react-currency-input-field';
import { TextInput } from '../reusable/TextInput';

const SizeModal = ({ sizeData, setSizeData, save, deleteSize, sizeIndex }) => {
    const [sizeError, setSizeError] = useState("");

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        var error = false;
        if (!sizeData?.size) {
            error = true;
            setSizeError('Please fill size');
        }
        if (!error) {
            save(sizeData, sizeIndex);
        }

    };

    const handleChangeField = (event, key) => {
        if (event?.target) {
            sizeData[key] = event.target.value;
        } else {
            sizeData[key] = event;
        }

        setSizeData({ ...sizeData });
    };

    const handleCancel = () => {
        setSizeError("");
        setSizeData(null);
    };

    const handleDelete = () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the size",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteSize(sizeIndex);
            }
        });
    };
    return (
        <Modal className="modal fade flex-modal" show={!!sizeData} onHide={handleCancel} >
            {!!sizeData && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">{sizeIndex !== -1 ? 'Edit' : 'Add'} Size</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Size</label>
                                <div className="name">
                                    <TextInput type="text" className="form-control" autoComplete="off" required="required" value={sizeData?.size} onChange={(event) => { handleChangeField(event, 'size') }} placeholder={"Enter size"} />
                                    <span className="validation-text text-danger">{sizeError}</span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Price</label>
                                <div className="name">
                                    <CurrencyInput id="input-example"
                                        className="form-control"
                                        prefix="$"
                                        decimalsLimit={2}
                                        placeholder="Enter price"
                                        defaultValue={sizeData?.price}
                                        onValueChange={(value) => handleChangeField(value, 'price')}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    {sizeIndex !== -1 &&
                        <button type="button" onClick={() => handleDelete()} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Delete</button>
                    }
                    {sizeIndex === -1 &&
                        <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    }
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>}
        </Modal>
    );
};

export default SizeModal;
