import React, { Fragment, useState } from "react";
import noImg from "../../../img/no-image-available.png";
import { ReactS3Client, s3Url } from "../../../services/AWSS3";
import { Spinner } from "react-bootstrap";
import { randomStr } from "../../../services/UtilsService";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { TextInput } from "../../reusable/TextInput";
import { TextAreaInput } from "../../reusable/TextAreaInput";
import { sendNotificationsAPI } from "../../../services/NotificationsService";

const StepOne = ({ stepNo, formData, setFormData, next, prev }) => {
    const [errors, setErrors] = useState({});
    const [btnLoading, setBtnLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState();

    const handleSaveFormSubmit = async (event, toAll = false) => {
        event.preventDefault();
        const newErrors = {};
        if (!formData?.title) {
            newErrors["title"] = "Please fill title";
        }
        if (!formData?.body) {
            newErrors["body"] = "Please fill body";
        }
        setErrors({ ...newErrors });
        console.log("formData", formData);
        console.log("newErrors", newErrors);
        if (!newErrors || !Object.values(newErrors).length) {
            const request = {
                title: formData.title,
                body: formData.body
            };
            if (!!selectedImage?.source) {
                // upload image
                setBtnLoading(true);
                const image = await uploadImageToS3("image");
                if (!image) {
                    setBtnLoading(false);
                    return;
                }
            }
            request.image = formData.image;
            if (toAll) {
                // send to all users
                setBtnLoading(true);
                sendNotificationsAPI(request).then(response => {
                    swal("Good job!", "Successfully Sent", "success");
                    setBtnLoading(false);
                }).catch((error) => {
                    setBtnLoading(false);
                    console.error(error);
                });
            } else {
                next();
            }
        }
    };

    const handleChangeField = (event, key, obj = null) => {
        let value = event && event.target ? event.target.value : event;
        if (obj) {
            obj[key] = value;
        } else {
            formData[key] = value;
        }
        formData.saving = true;
        setFormData({ ...formData });
        setErrors({});
    };

    const handleImageUpload = (event) => {
        setSelectedImage({
            source: event.target.files[0],
            fileURL: URL.createObjectURL(event.target.files[0]),
        });
        formData.saving = true;
        setFormData({ ...formData });
    };

    const uploadImageToS3 = async (formDataKey) => {
        const newErrors = {};
        if (!selectedImage?.source) {
            newErrors["uploadImage"] = "Please upload an image from your computer";
            setErrors({ ...newErrors });
            return;
        }
        let newFileName = randomStr(12) + "_" + selectedImage?.source?.name;
        try {
            const data = await ReactS3Client.uploadFile(
                selectedImage?.source,
                newFileName
            );
            if (!data?.key) {
                newErrors["uploadImage"] = "Something went wrong with this image";
                setErrors({ ...newErrors });
                return;
            }
            formData[formDataKey] = data.key;
            setFormData({ ...formData });
            setSelectedImage(undefined);
            return data.key;
        } catch (err) {
            console.error(err);
            newErrors["uploadImage"] = "Something went wrong with this image";
            setErrors({ ...newErrors });
            return;
        }
    };

    return (
        <Fragment>
            {stepNo == 1 &&
                <div>
                    <h3 className="form-title text-center mb-4">Set Notification Data</h3>
                    <form className="position-relative">
                        <div className="add-contact-box">
                            <div className="add-contact-content">
                                <div className="form-group mb-3">
                                    <label className="text-black font-w500">Upload Icon</label>
                                    <div className="name">
                                        <input
                                            type="file"
                                            autoComplete="off"
                                            onChange={handleImageUpload}
                                        />
                                        <div className="validation-text text-danger">
                                            {errors?.uploadImage}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    <div className="image-wrapper">
                                        <img
                                            src={
                                                selectedImage?.fileURL ||
                                                (formData?.icon ? s3Url + "/" + formData?.icon : noImg)
                                            }
                                            width={"100%"}
                                        />
                                    </div>
                                </div>
                                <div className="form-group mb-3">
                                    <label className="text-black font-w500">Title</label>
                                    <div className="name">
                                        <TextInput
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            required="required"
                                            value={formData?.title}
                                            onChange={(event) => handleChangeField(event, "title")}
                                            placeholder="Enter Title"
                                        />
                                        <span className="validation-text text-danger">
                                            {errors?.title}
                                        </span>
                                    </div>
                                </div>
                                <div className="form-group mb-4">
                                    <label className="text-black font-w500">Body</label>
                                    <div className="name">
                                        <TextAreaInput
                                            type="text"
                                            className="form-control"
                                            autoComplete="off"
                                            required="required"
                                            value={formData?.body}
                                            onChange={(event) => handleChangeField(event, "body")}
                                            placeholder="Enter Body"
                                        ></TextAreaInput>
                                        <span className="validation-text text-danger">
                                            {errors?.body}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center justify-content-center">
                                {!!btnLoading && <Spinner animation="border" variant="primary" />}
                                {!btnLoading &&
                                    <>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-primary"
                                            onClick={handleSaveFormSubmit}
                                        >
                                            Select Users
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-sm m-2"
                                            onClick={(event) => handleSaveFormSubmit(event, true)}
                                        >
                                            Send to All
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </form>
                </div>
            }
            {stepNo != 1 &&
                <div>
                    <h4 className="form-title mb-3">Notification Data</h4>
                    <div className="d-flex align-items-center">
                        <div className="image-wrapper">
                            <img
                                src={
                                    selectedImage?.fileURL ||
                                    (formData?.image ? s3Url + "/" + formData?.image : noImg)
                                }
                                width={"100%"}
                            />
                        </div>
                        <div className="d-flex flex-column align-items-start ml-3">
                            <h5 className="mb-1">{formData.title}</h5>
                            <p className="mb-0">{formData.body}</p>
                        </div>
                        <div className="menu-arrow-box ml-auto">
                            <Link to="#" onClick={prev}>
                                <div className="menu-arrow">
                                    <i className="fs-32 las la-angle-double-right"
                                    ></i>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
};

export default StepOne;
