import React, { Fragment, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

import { Link } from "react-router-dom";
import swal from "sweetalert";
import { currencyFormat, humanReadableFormattedDateString, numberThFormat } from "../../services/UtilsService";
import { deleteStatus, getStatuses } from "../../services/RewardsService";
import NewRewardsStatus from "./NewRewardStatus";
import { s3Url } from "../../services/AWSS3";
import noImg from "../../img/no-image-available.png";

const RewardsStatus = () => {
    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(null);

    useEffect(() => {
        populateStatusData();
    }, []);

    const populateStatusData = () => {
        setLoading(true);
        getStatuses().then(response => {
            const responseData = response.data;
            setStatuses(responseData.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const handleEditClick = (event, row) => {
        setSelectedStatus({ ...row });
    }
    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the status",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteStatus(id).then((response) => {
                    const newStatuses = statuses.filter(x => {
                        return x._id !== id;
                    });
                    setStatuses([...newStatuses]);
                    swal(
                        "Poof! Your reward status is deleted",
                        {
                            icon: "success",
                        }
                    );
                });
            }
        })
    };

    const saveModal = (event) => {
        setSelectedStatus(null);
        populateStatusData();
    }
    const cancelModal = (event) => {
        setSelectedStatus(null);
    }

    return (
        <Fragment>
            <div className="form-head d-flex mb-2 mb-lg-4 align-items-center">
                <div className="mr-auto">
                    {!selectedStatus &&
                        <>
                            <h2 className="text-black font-w600 mb-0">Rewards Settings</h2>
                            <p className="mb-0 d-none d-md-block d-lg-block">Manage rewards settings for users!</p>
                        </>
                    }
                    {!!selectedStatus &&
                        <>
                            <h2 className="text-black font-w600 mb-0 text-capitalize">{selectedStatus?.title || "New Reward Status"}</h2>
                            <ol className="breadcrumb d-none d-md-flex d-lg-flex">
                                <li className="breadcrumb-item">
                                    <Link to="/rewards">
                                        Rewards Settings
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="#" onClick={() => cancelModal()}>
                                        Rewards
                                    </Link>
                                </li>
                            </ol>
                        </>
                    }
                </div>
                {!!selectedStatus && <Link to="#" className="btn btn-light mr-2" onClick={cancelModal}>
                    <i className="las la-undo mr-0 mr-lg-2"></i>
                    <span className="d-none d-lg-inline-block">Cancel</span>
                </Link>}
                {!selectedStatus &&
                    <Button onClick={() => { setSelectedStatus({ title: '' }) }}>+ New Status</Button>
                }
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {loading && !statuses.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !statuses.length) &&
                        <div className="card">
                            <div className="card-body">
                                {!!selectedStatus && <NewRewardsStatus selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} save={saveModal} cancel={cancelModal} ></NewRewardsStatus>}
                                {!selectedStatus &&
                                    <div className="table-responsive">
                                        {
                                            !loading && !statuses.length && <>Nothing Found</>
                                        }
                                        {
                                            !!statuses.length &&
                                            <table className="table mb-0 table-striped respon-table-data">
                                                <thead>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>On spending</th>
                                                        <th>Discount</th>
                                                        <th>Item</th>
                                                        <th>Created At</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody id="customers">
                                                    {statuses.map(row => {
                                                        return (
                                                            <tr className="btn-reveal-trigger">
                                                                <td className="py-3">
                                                                    <Link to="#" onClick={(event) => handleEditClick(event, row)}>
                                                                        <div className="media d-flex align-items-center">
                                                                            <div className="avatar avatar-xl mr-2">
                                                                                <img
                                                                                    className="rounded-circle img-fluid"
                                                                                    src={!!row.image ? `${s3Url}/${row.image}` : noImg}
                                                                                    width="30"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="media-body">
                                                                                <h5 className="mb-0 fs--1">
                                                                                    {row.title}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td className="py-2">
                                                                    {currencyFormat(row.amount)}
                                                                </td>
                                                                <td className="py-2">
                                                                    {row.discount}% on {numberThFormat(row.discountOrderNumber)} order
                                                                </td>
                                                                <td className="py-2">
                                                                    {!!row.item ?
                                                                        <span>{row.item?.itemData?.name} on {numberThFormat(row.itemDiscountOrderNumber)} orderr</span>
                                                                        : '-'
                                                                    }
                                                                </td>
                                                                <td className="py-2">
                                                                    <label className="date-label">
                                                                        {humanReadableFormattedDateString(row.createdAt)}
                                                                    </label>
                                                                </td>
                                                                <td className="py-2 text-right">
                                                                    <div className="d-flex align-items-center justify-content-right">
                                                                        <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                            onClick={(event) => handleEditClick(event, row)}
                                                                        >
                                                                            <i className="fa fa-pencil"></i>
                                                                        </Link>
                                                                        <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                            onClick={() => handleDeleteClick(row._id)}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default RewardsStatus;
