import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Select from "react-select";
import { selectLocationOptions } from "../../store/selectors/LocationSelectors";
import { addStatus, editStatus } from "../../services/RewardsService";
import CurrencyInput from "react-currency-input-field";
import { getMenus, getSubMenus } from "../../services/MenuService";
import { getItems } from "../../services/ItemService";
import noImg from "../../img/no-image-available.png";

import { randomStr } from "../../services/UtilsService";
import { ReactS3Client, s3Url } from "../../services/AWSS3";
import { Spinner } from "react-bootstrap";

const NewRewardsStatus = ({
  selectedStatus,
  setSelectedStatus,
  save,
  cancel,
}) => {
  // const [selectedOption, setSelectedOption] = useState(null);

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const locationOptions = useSelector(selectLocationOptions);
  const [menus, setMenus] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    setFormData({ ...selectedStatus });
    populateSelectedItem();
  }, [selectedStatus]);

  useEffect(() => {
    if (selectedItem?.locationId) {
      populateMenuDropDown();
    } else {
      setMenus([]);
    }

    if (selectedItem?.menuId) {
      populateSubMenusDropDown();
    } else {
      setSubMenus([]);
    }

    if (selectedItem?.menuId && selectedItem?.subMenuId) {
      populateItemsDropdown();
    } else {
      setItems([]);
    }
  }, [selectedItem]);

  const populateMenuDropDown = () => {
    getMenus(selectedItem.locationId)
      .then((response) => {
        const responseData = response.data.data;
        if (responseData.menu) {
          setMenus(responseData.menu);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const populateSubMenusDropDown = () => {
    getSubMenus(selectedItem.menuId)
      .then((response) => {
        const responseData = response.data.data;
        if (responseData.subMenu) {
          setSubMenus(responseData.subMenu);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const populateItemsDropdown = () => {
    setLoading(true);
    getItems(selectedItem.menuId, selectedItem.subMenuId)
      .then((response) => {
        const responseData = response.data.data;
        if (responseData.items) {
          setItems(Object.values(responseData.items));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const populateSelectedItem = () => {
    if (selectedStatus?._id) {
      selectedItem.locationId = selectedStatus.locationId;
      selectedItem.menuId = selectedStatus.menuId;
      selectedItem.subMenuId = selectedStatus.submenuId;
      selectedItem.itemId = selectedStatus.item?._id;
      setSelectedItem({ ...selectedItem });
    }
  };

  const handleChangeDropdown = (event, key) => {
    console.log("handleChangeDropdown", event);
    if (key == "locationId") {
      selectedItem[key] = event.value;
    } else {
      selectedItem[key] = event.target.value;
    }
    setSelectedItem({ ...selectedItem });
    if (formData) {
      formData.saving = true;
      setFormData({ ...formData });
    }
  };

  const handleSaveFormSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!formData?.title) {
      newErrors["title"] = "Please fill title";
    }
    if (!formData?.amount && (formData?.discount || formData?.itemId)) {
      newErrors["amount"] = "Please fill amount on spending";
    }
    if (formData?.discount && !formData.discountOrderNumber) {
      newErrors["discountOrderNumber"] =
        "Please fill discount order number when discount is specified";
    }
    if (formData?.itemId && !formData.itemOrderNumber) {
      newErrors["itemOrderNumber"] =
        "Please fill item order number when item is specified";
    }
    setErrors({ ...newErrors });
    console.log("formData", formData);
    console.log("newErrors", newErrors);
    if (!newErrors || !Object.values(newErrors).length) {
      setLoading(true);
      const request = {
        title: formData.title,
      };
      request.amount = formData.amount;
      if (formData.discount) {
        request.discount = formData.discount;
        request.discountNumber = formData.discountOrderNumber;
      }
      if (formData.item._id) {
        request.itemId = formData.item._id;
        request.itemDiscountNumber = formData.itemOrderNumber;
      }
      if (!!selectedImage?.source) {
        // upload image
        const name = await uploadImageToS3();
        if (!name) {
          setLoading(false);
          return;
        }
      }
      request.image = formData.image;
      if (formData._id) {
        // editing
        request.statusId = formData._id;
        editStatus(request)
          .then((response) => {
            swal("Good job!", "Successfully Updated", "success");
            setFormData(null);
            setLoading(false);
            save();
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      } else {
        addStatus(request)
          .then((response) => {
            swal("Good job!", "Successfully Created", "success");
            setFormData(null);
            setLoading(false);
            save();
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }
    }
  };

  const handleChangeField = (event, key, obj = null) => {
    let value = event && event.target ? event.target.value : event;
    if (obj) {
      obj[key] = value;
    } else {
      formData[key] = value;
    }
    formData.saving = true;
    setFormData({ ...formData });
    setErrors({});
  };

  const handleCancel = () => {
    console.log("handleCancel");
    setErrors({});
    setFormData(null);
    cancel();
  };

  const handleImageUpload = (event) => {
    setSelectedImage({
      source: event.target.files[0],
      fileURL: URL.createObjectURL(event.target.files[0]),
    });
    formData.saving = true;
    setFormData({ ...formData });
  };

  const uploadImageToS3 = async () => {
    const newErrors = {};
    if (!selectedImage?.source) {
      newErrors["uploadImage"] = "Please upload an image from your computer";
      setErrors({ ...newErrors });
      return;
    }
    let newFileName = randomStr(12) + "_" + selectedImage?.source?.name;
    try {
      const data = await ReactS3Client.uploadFile(
        selectedImage?.source,
        newFileName
      );
      setSelectedImage(undefined);
      formData.image = data.key;
      setFormData({ ...formData });
      return data.key;
    } catch (err) {
      console.error(err);
      newErrors["uploadImage"] = "Something went wrong with this image";
      setErrors({ ...newErrors });
      return;
    }
  };

  return (
    <form className="position-relative">
      {loading && (
        <div className="loading-overlay pb-5">
          <Spinner className="mb-5" animation="border" variant="primary" />
        </div>
      )}
      <div>
        <i className="flaticon-cancel-12 close"></i>
        <div className="add-contact-box">
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">Upload Image</label>
              <div className="name">
                <input
                  type="file"
                  autoComplete="off"
                  onChange={handleImageUpload}
                />
                <div className="validation-text text-danger">
                  {errors?.uploadImage}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="image-wrapper">
                <img
                  src={
                    selectedImage?.fileURL ||
                    (formData?.image ? s3Url + "/" + formData?.image : noImg)
                  }
                  width={"100%"}
                />
              </div>
            </div>
          </div>
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">Title</label>
              <div className="name">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  required="required"
                  value={formData?.title}
                  onChange={(event) => handleChangeField(event, "title")}
                  placeholder="Enter Title"
                />
                <span className="validation-text text-danger">
                  {errors?.title}
                </span>
              </div>
            </div>
          </div>
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">On spending amount</label>
              <div className="name">
                <CurrencyInput
                  id="input-example"
                  className="form-control"
                  prefix="$"
                  decimalsLimit={2}
                  placeholder="Enter on spending amount"
                  defaultValue={formData?.amount}
                  onValueChange={(value) => handleChangeField(value, "amount")}
                  autoComplete="off"
                />
                <span className="validation-text text-danger">
                  {errors?.amount}
                </span>
              </div>
            </div>
          </div>
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">Discount</label>
              <div className="name">
                <div className="d-flex align-items-center justify-content-space">
                  <input
                    type="number"
                    className="form-control w-100"
                    autoComplete="off"
                    value={formData?.discount}
                    onChange={(event) => handleChangeField(event, "discount")}
                    placeholder="Enter Discount in %age"
                  />
                  <label className="text-black mx-2 mb-0">On</label>
                  <input
                    type="number"
                    className="form-control which-input"
                    autoComplete="off"
                    value={formData?.discountOrderNumber}
                    onChange={(event) =>
                      handleChangeField(event, "discountOrderNumber")
                    }
                    placeholder="which"
                  />
                  <label className="text-black ml-2 mb-0">Order</label>
                </div>
                <span className="validation-text text-danger">
                  {errors?.discount}
                </span>
                <span className="validation-text text-danger">
                  {errors?.discountOrderNumber}
                </span>
              </div>
            </div>
          </div>

          {/* locations, menus, sub menus and items */}
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">Choose Locations</label>
              <div className="name">
                <Select
                  className="form-control-location"
                  onChange={(option) =>
                    handleChangeDropdown(option, "locationId")
                  }
                  options={locationOptions}
                  value={locationOptions.filter(
                    (opt) => opt.value == selectedItem?.locationId
                  )}
                  placeholder="Choose location to select an item"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    border: "none",
                    colors: {
                      ...theme.colors,
                      text: "white",
                      primary25: "#ffeae6",
                      primary: "#FF3333",
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">
                Choose menus and sub-menus
              </label>
              <div className="name d-flex align-items-center">
                <select
                  className="form-control text-capitalize"
                  onChange={(event) => handleChangeDropdown(event, "menuId")}
                  required
                >
                  <option value="" selected>
                    Choose menu
                  </option>
                  {menus.map((opt) => {
                    return (
                      <option
                        value={opt._id}
                        selected={opt._id === selectedItem?.menuId}
                      >
                        {opt.categoryData.name}
                      </option>
                    );
                  })}
                  ;
                </select>
                <select
                  className="form-control ml-2 text-capitalize"
                  onChange={(event) => handleChangeDropdown(event, "subMenuId")}
                  required
                >
                  <option value="" selected>
                    Choose sub menu
                  </option>
                  {subMenus.map((opt) => {
                    return (
                      <option
                        value={opt._id}
                        selected={opt._id === selectedItem?.subMenuId}
                      >
                        {opt.name}
                      </option>
                    );
                  })}
                  ;
                </select>
              </div>
              {!!errors?.menuId && (
                <span className="ml-2 validation-text text-danger">
                  ({errors?.menuId})
                </span>
              )}
              {!!errors?.subMenuId && (
                <span className="ml-2 validation-text text-danger">
                  ({errors?.subMenuId})
                </span>
              )}
            </div>
          </div>
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">Choose Item</label>
            </div>
            <div className="d-flex align-items-center justify-content-space">
              <select
                className="form-control w-100 text-capitalize"
                onChange={(event) => handleChangeDropdown(event, "itemId")}
                required
              >
                <option value="" selected>
                  Choose Item
                </option>
                {items.map((opt) => {
                  return (
                    <option
                      value={opt._id}
                      selected={opt._id === selectedItem?.itemId}
                    >
                      {opt.itemData.name}
                    </option>
                  );
                })}
                ;
              </select>
              <label className="text-black mx-2 mb-0">On</label>
              <input
                type="number"
                className="form-control which-input"
                autoComplete="off"
                value={formData?.itemOrderNumber}
                onChange={(event) =>
                  handleChangeField(event, "itemOrderNumber")
                }
                placeholder="which"
              />
              <label className="text-black ml-2 mb-0">Order</label>
            </div>
            {!!errors?.itemId && (
              <span className="ml-2 validation-text text-danger">
                ({errors?.itemId})
              </span>
            )}
          </div>
        </div>
      </div>
      {!!formData?.saving && (
        <div className="card-footer text-center save-cancel">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn btn-light mr-3 w-25"
          >
            {" "}
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary w-25"
            onClick={handleSaveFormSubmit}
          >
            Save
          </button>
        </div>
      )}
    </form>
  );
};

export default NewRewardsStatus;
