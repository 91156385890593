import {
    CONFIRMED_GET_LOCATIONS,
    CONFIRMED_SET_LOCATIONID,
} from '../actions/LocationTypes';

const initialState = {
    selectedLocationId: null,
    locations: []
};

export default function LocationReducer(state = initialState, actions) {
    if (actions.type === CONFIRMED_SET_LOCATIONID) {
        return {
            ...state,
            selectedLocationId: actions.payload
        };
    }
    if (actions.type === CONFIRMED_GET_LOCATIONS) {
        return {
            ...state,
            locations: actions.payload
        };
    }
    return state;
}
