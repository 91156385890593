import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { downloadReport } from '../../services/DashboardService';
import swal from "sweetalert";
import { formattedDateString } from '../../services/UtilsService';

const DownloadReportModal = ({ showModal, setShowModal, type = "normal", userId, selectionRange }) => {
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        email: "",
        report_menu: true,
        report_item: true,
        report_sales: true,
        report_wallet: true,
        report_coins: true,
        errors: {}
    });

    useEffect(() => {
        if (locationId) {

        }
    }, [locationId]);

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        formData.errors = {};
        setFormData({ ...formData });
        var error = false;
        if (!formData.email) {
            error = true;
            formData.errors['email'] = "Please enter email address";
        }
        if (!!error) {
            setFormData({ ...formData });
            return;
        }
        setLoading(true);
        const request = {
            email: formData.email,
            report: []
        }
        if (formData.report_sales) {
            request.report.push("sales");
        }
        if (type == "user") {
            request.userId = userId;
            if (formData.report_wallet) {
                request.report.push("wallet");
            }
            if (formData.report_coins) {
                request.report.push("coins");
            }
        } else {
            request.locationId = locationId;
            if (formData.report_item) {
                request.report.push("items");
            }
            if (formData.report_menu) {
                request.report.push("menu");
            }
        }

        if (selectionRange.startDate) {
            request.startDate = formattedDateString(selectionRange.startDate);
        } else {
            request.startDate = "";
        }
        
        if (selectionRange.endDate) {
            request.endDate = formattedDateString(selectionRange.endDate);
        } else {
            request.endDate = "";
        }

        downloadReport(request).then(response => {
            setLoading(false);
            swal('Good job!', 'Report is sent to your email', "success");
            setShowModal(false);
        }).catch(error => {
            console.error(error);
            swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
            setLoading(false);
        });
    };

    const handleChangeField = (event, field) => {
        formData[field] = event.target.value;
        setFormData({ ...formData });
    };

    const handleCheckbox = (key) => {
        formData[key] = !formData[key];
        setFormData({ ...formData });
    };

    const handleCancel = () => {
        setShowModal(false);
    };
    return (
        <Modal className="modal fade flex-modal" show={!!showModal} onHide={handleCancel} >
            {!!showModal && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">Download Report</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h5 className="mb-3">Enter your email to receive the report in your inbox. <br />Please Note: if you use Gmail, the report may appear in your junk or promotions folder.</h5>
                    <div className="add-contact-box">
                        <div className="form-group mb-3">
                            <label className="text-black font-w500">Email</label>
                            <div>
                                <input type="email" className="form-control" autoComplete="off" value={formData.email} onChange={(event) => { handleChangeField(event, 'email') }} placeholder={"Enter email"} />
                                <span className="validation-text text-danger">{formData.errors?.email}</span>
                            </div>
                        </div>
                    </div>
                    <div className="add-contact-box">
                        <div className="custom-control custom-checkbox mb-3 checkbox-primary check-xl" onClick={() => handleCheckbox('report_sales')}>
                            <input type="checkbox" className="custom-control-input" name="report_sales" value="report_sales" checked={!!formData.report_sales} />
                            <label className="custom-control-label pl-3 pt-1 text-capitalize">Sales Report</label>
                        </div>
                    </div>
                    {type !== "user" &&
                        <>
                            <div className="add-contact-box">
                                <div className="custom-control custom-checkbox mb-3 checkbox-primary check-xl" onClick={() => handleCheckbox('report_menu')}>
                                    <input type="checkbox" className="custom-control-input" name="report_menu" value="report_menu" checked={!!formData.report_menu} />
                                    <label className="custom-control-label pl-3 pt-1 text-capitalize">Menu Report</label>
                                </div>
                            </div>
                            <div className="add-contact-box">
                                <div className="custom-control custom-checkbox mb-3 checkbox-primary check-xl" onClick={() => handleCheckbox('report_item')}>
                                    <input type="checkbox" className="custom-control-input" name="report_item" value="report_item" checked={!!formData.report_item} />
                                    <label className="custom-control-label pl-3 pt-1 text-capitalize">Item Report</label>
                                </div>
                            </div>
                        </>
                    }
                    {type == "user" &&
                        <>
                            <div className="add-contact-box">
                                <div className="custom-control custom-checkbox mb-3 checkbox-primary check-xl" onClick={() => handleCheckbox('report_wallet')}>
                                    <input type="checkbox" className="custom-control-input" name="report_wallet" value="report_wallet" checked={!!formData.report_wallet} />
                                    <label className="custom-control-label pl-3 pt-1 text-capitalize">Wallet Report</label>
                                </div>
                            </div>
                            <div className="add-contact-box">
                                <div className="custom-control custom-checkbox mb-3 checkbox-primary check-xl" onClick={() => handleCheckbox('report_coins')}>
                                    <input type="checkbox" className="custom-control-input" name="report_coins" value="report_coins" checked={!!formData.report_coins} />
                                    <label className="custom-control-label pl-3 pt-1 text-capitalize">Birch Coins Report</label>
                                </div>
                            </div>
                        </>
                    }
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit} disabled={!!loading}>Download {!!loading && <Spinner className="primary"></Spinner>}</button>
                </div>
            </form>}
        </Modal>
    );
};

export default DownloadReportModal;
