import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import Autocomplete from "react-google-autocomplete";
import { useSelector } from 'react-redux';
import { ReactS3Client, s3Url } from '../../services/AWSS3';
import { randomStr } from '../../services/UtilsService';
import noImg from "../../img/no-image-available.png";
import { selectLocationOptions } from '../../store/selectors/LocationSelectors';
import { TextInput } from '../reusable/TextInput';
import { getSquareLocations } from '../../services/LocationService';
// import swal from 'sweetalert';

// const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyDFjI2nLCawhNq98m1_OLfSO3gRtCl-W84";

const LocationModal = ({ editLocationData, setEditLocationData, save, cancel }) => {
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const locationOptions = useSelector(selectLocationOptions);
    const [selectedImage, setSelectedImage] = useState();
    const [squareLocations, setSquareLocations] = useState([]);
    const [locationSearchedOnGoogle, setLocationSearchedOnGoogle] = useState(true);
    const googlePlacePickerOptions = {
        types: [],
        componentRestrictions: { country: ["us"] }
    };

    useEffect(() => {
        if (editLocationData) {
            populateSquareLocations();
        }
    }, [editLocationData]);

    const populateSquareLocations = () => {
        getSquareLocations().then(response => {
            if (response.data?.data) {
                setSquareLocations(response.data.data);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const handleChangeField = (event, key, obj = null) => {
        if (obj) {
            obj[key] = event.target.value;
        } else {
            editLocationData[key] = event.target.value;
        }
        setEditLocationData({ ...editLocationData });
        setErrors({});
    };

    const handleSaveFormSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};
        if (!editLocationData?.coordinates?.length) {
            newErrors['addressLine1'] = "Please fill a valid address";
        }
        if (!editLocationData?.address?.addressLine1) {
            newErrors['addressLine1'] = "Please fill address";
        }
        if (!editLocationData?.address?.state) {
            newErrors['state'] = "Please fill state";
        }
        if (!editLocationData?.address?.country) {
            newErrors['country'] = "Please fill country";
        }
        if (!editLocationData?.address?.city) {
            newErrors['city'] = "Please fill city";
        }
        if (!editLocationData?.address?.postalCode) {
            newErrors['postalCode'] = "Please fill zip code";
        }
        if (!editLocationData?.displayAddress) {
            newErrors['displayAddress'] = "Please fill display address";
        }
        if (!editLocationData?.squareLocationId) {
            newErrors['squareLocationId'] = "Please select a location from square";
        }
        setErrors({ ...newErrors });
        if (!newErrors || !Object.values(newErrors).length) {
            setLoading(true);
            if (!!selectedImage?.source) {
                // upload image
                const name = await uploadImageToS3();
                if (!name) {
                    return;
                }
            }
            if (!editLocationData?.image) {
                setLoading(false);
                newErrors['uploadImage'] = "Please upload an image";
                setErrors({ ...newErrors });
            } else {
                setLoading(false);
                save(editLocationData);
            }
        }
    };

    const handleOnPlaceSelected = (place) => {
        setErrors({});
        editLocationData.coordinates[0] = place.geometry.location.lng();
        editLocationData.coordinates[1] = place.geometry.location.lat();
        editLocationData.displayAddress = place.formatted_address;
        editLocationData.address.addressLine1 = place.formatted_address;
        place.address_components.forEach(part => {
            if (part.types.includes('country')) {
                editLocationData.address.country = part.long_name;
            }
            if (part.types.includes('administrative_area_level_1')) {
                editLocationData.address.state = part.long_name || part.short_name;
            }
            if (part.types.includes('locality')) {
                editLocationData.address.city = part.long_name || part.short_name;
            }
        });
        editLocationData.address.postalCode = "";
        if (editLocationData.coordinates?.length) {
            editLocationData.locationSearchedOnGoogle = true;
        }
        setEditLocationData({ ...editLocationData });
    };

    const placeSelectedNext = () => {
        // if place is already set, then next button will work
        if (editLocationData.coordinates.length == 2 && !!editLocationData.coordinates[0] && !!editLocationData.coordinates[1]) {
            editLocationData.locationSearchedOnGoogle = true;
            setEditLocationData({ ...editLocationData });
        }
    };

    const handleImageUpload = (event) => {
        setSelectedImage({
            source: event.target.files[0],
            fileURL: URL.createObjectURL(event.target.files[0])
        });
    };

    const uploadImageToS3 = async () => {
        const newErrors = {};
        if (!selectedImage?.source) {
            newErrors['uploadImage'] = "Please upload an image from your computer";
            setErrors({ ...newErrors });
            return;
        }
        let newFileName = randomStr(12) + "_" + selectedImage?.source?.name;
        try {
            const data = await ReactS3Client
                .uploadFile(selectedImage?.source, newFileName);
            setSelectedImage(undefined);
            editLocationData.image = data.key;
            setEditLocationData({ ...editLocationData });
            return data.key;
        } catch (err) {
            console.error(err);
            newErrors['uploadImage'] = "Something went wrong with this image";
            setErrors({ ...newErrors });
            return;
        }
    };

    const handleCancel = () => {
        setErrors({});
        setEditLocationData(null);
        cancel();
    }
    return (
        <Modal className="modal fade" show={!!editLocationData} onHide={handleCancel} >
            {!!editLocationData && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">{!!editLocationData.addNew ? 'Add' : 'Edit'} Location Address</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body position-relative">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                {!editLocationData.locationSearchedOnGoogle &&
                                    <div className="row mb-3">
                                        <div className="col-12">
                                            <label className="text-black font-w500">Pick an address</label>
                                            <Autocomplete
                                                className="form-control"
                                                onPlaceSelected={handleOnPlaceSelected}
                                                // apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                                                debounce={500}
                                                options={googlePlacePickerOptions}
                                                placeholder={"Search location (powered by google)"}
                                                defaultValue={editLocationData.address?.addressLine1}
                                                inputAutocompleteValue={editLocationData.address?.addressLine1}
                                            />
                                        </div>
                                    </div>
                                }
                                {!!editLocationData.locationSearchedOnGoogle &&
                                    <>
                                        <div className="mt-3 mb-3 d-flex">
                                            <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${editLocationData.coordinates[1]},${editLocationData.coordinates[0]}&zoom=13&size=300x100&markers=color:blue%7C${editLocationData.coordinates[1]},${editLocationData.coordinates[0]}&key=AIzaSyDFjI2nLCawhNq98m1_OLfSO3gRtCl-W84`} />
                                            <button className="btn btn-sm btn-light" onClick={() => {
                                                editLocationData.locationSearchedOnGoogle = false;
                                                setEditLocationData({ ...editLocationData });
                                            }}>Pick address again</button>
                                        </div>
                                        <div className="row mt-3 mb-3">
                                            <div className="col-12">
                                                <TextInput type="text" className="form-control" autoComplete="off" value={editLocationData.address?.addressLine1} onChange={(event) => { handleChangeField(event, 'addressLine1', editLocationData.address) }} placeholder={"Enter complete address"} />
                                                <span className="validation-text text-danger">{errors?.addressLine1}</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3 mb-3">
                                            <div className="col-6">
                                                <TextInput type="text" className="form-control" autoComplete="off" value={editLocationData.address?.city} onChange={(event) => { handleChangeField(event, 'city', editLocationData.address) }} placeholder={"Enter city"} />
                                                <span className="validation-text text-danger">{errors?.city}</span>
                                            </div>
                                            <div className="col-6">
                                                <TextInput type="text" className="form-control" autoComplete="off" value={editLocationData.address?.state} onChange={(event) => { handleChangeField(event, 'state', editLocationData.address) }} placeholder={"Enter state"} />
                                                <span className="validation-text text-danger">{errors?.state}</span>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            {/* <div className="col-6">
                                                <input type="text" className="form-control" autoComplete="off" value={editLocationData.address?.country} onChange={(event) => { handleChangeField(event, 'country', editLocationData.address) }} placeholder={"Enter country"} />
                                                <span className="validation-text text-danger">{errors?.country}</span>
                                            </div> */}
                                            <div className="col-12">
                                                <TextInput type="text" className="form-control" autoComplete="off" value={editLocationData.address?.postalCode} onChange={(event) => { handleChangeField(event, 'postalCode', editLocationData.address) }} placeholder={"Enter Zip Code"} />
                                                <span className="validation-text text-danger">{errors?.postalCode}</span>
                                            </div>
                                        </div>
                                    </>}
                            </div>
                            {!!editLocationData.locationSearchedOnGoogle &&
                                <>
                                    <div className="form-group mb-3">
                                        <label className="text-black font-w500">Display Address</label>
                                        <div className="name">
                                            <TextInput type="text" className="form-control" autoComplete="off" value={editLocationData.displayAddress} onChange={(event) => { handleChangeField(event, 'displayAddress') }} placeholder={"Enter display address"} />
                                            <span className="validation-text text-danger">{errors?.displayAddress}</span>
                                        </div>
                                    </div>
                                    {
                                        !!editLocationData.addNew &&
                                        <div className="form-group mb-3">
                                            <label className="text-black font-w500">Copy Data from Other Location</label>
                                            <div className="name">
                                                <select className="form-control" onChange={(event) => handleChangeField(event, 'locationId')}>
                                                    <option value="" selected>Choose a location</option>
                                                    {locationOptions.map(opt => {
                                                        return (<option value={opt.value}>{opt.label}</option>);
                                                    })};
                                                </select>
                                            </div>
                                        </div>
                                    }

                                    <div className="form-group mb-3">
                                        <label className="text-black font-w500">Square Locations</label>
                                        <div className="name">
                                            <select className="form-control" onChange={(event) => handleChangeField(event, 'squareLocationId')}>
                                                <option value="" selected>Choose a square location</option>
                                                {squareLocations.map(opt => {
                                                    return (<option value={opt.id} selected={opt.id == editLocationData.squareLocationId}>{opt.name}</option>);
                                                })};
                                            </select>
                                            <span className="validation-text text-danger">{errors?.squareLocationId}</span>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <label className="text-black font-w500">Upload Image</label>
                                        <div className="name">
                                            <input type="file" autoComplete="off" onChange={handleImageUpload} />

                                            <div className="validation-text text-danger">{errors?.uploadImage}</div>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <div className='image-wrapper'>
                                            <img src={selectedImage?.fileURL || (editLocationData?.image ? s3Url + "/" + editLocationData?.image : noImg)} width={'100%'} />
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    {!!loading &&
                        <Spinner animation="border" variant="primary" />
                    }
                    {!loading &&
                        <>
                            <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                            {!!editLocationData.locationSearchedOnGoogle &&
                                <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                            }
                            {!editLocationData.locationSearchedOnGoogle && editLocationData.coordinates.length == 2 &&
                                <button type="submit" className="btn btn-primary" onClick={placeSelectedNext}>Next</button>
                            }
                        </>
                    }
                </div>
            </form>}
        </Modal>
    );
};

export default LocationModal;
