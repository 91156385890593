import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import { selectIsSubAdmin } from "../../../store/selectors/AuthSelectors";
import { useSelector } from "react-redux";

const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
   const [toggle, setToggle] = useState("");
   const onClick = (name) => setToggle(toggle === name ? "" : name);
   const isSubAdmin = useSelector(selectIsSubAdmin);
   return (
      <Fragment>
         <NavHader />
         <SideBar onClick={() => onClick2()} onClick3={() => onClick3()} isSubAdmin={isSubAdmin} />
         <Header
            onNote={() => onClick("chatbox")}
            onNotification={() => onClick("notification")}
            onProfile={() => onClick("profile")}
            toggle={toggle}
            title={title}
            onBox={() => onClick("box")}
            onClick={() => ClickToAddEvent()}
            isSubAdmin={isSubAdmin} />
      </Fragment>
   );
};

export default JobieNav;
