import React, { Fragment, useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";

const SendNotifications = () => {
   const [formData, setFormData] = useState({ users: {} });
   let [stepNo, setStepNo] = useState(1);
   const maxSteps = 2;
   const next = () => {
      stepNo++;
      if (stepNo > maxSteps) {
         stepNo = maxSteps;
      }
      setStepNo(stepNo);
   };
   const prev = () => {
      stepNo--;
      if (stepNo < 1) { stepNo = 1 };
      setStepNo(stepNo);
   };
   return (
      <Fragment>
         <div className="form-head d-flex mb-2 mb-lg-4 align-items-center">
            <div className="mr-auto">
               <h2 className="text-black font-w600 mb-0">Notifications</h2>
               <p className="mb-0 d-none d-md-block d-lg-block">Send push notifications to users!</p>
            </div>
         </div>
         <div className="row">
            <div className="col-xl-12 col-xxl-12">
               <div className="card">
                  <div className="card-body">
                     <form
                        onSubmit={(e) => e.preventDefault()}
                     >
                        <StepOne stepNo={stepNo} next={next} prev={prev} formData={formData} setFormData={setFormData} />
                        {stepNo == 2 &&
                           <StepTwo stepNo={stepNo} prev={prev} formData={formData} setFormData={setFormData} />
                        }
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default SendNotifications;
