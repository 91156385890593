import React from 'react';
import { Modal } from 'react-bootstrap';
import { currencyFormat, humanReadableFormattedDateString } from '../../services/UtilsService';

const OrderDetailModal = ({ selectedOrder, setSelectedOrder }) => {
    const handleCancel = () => {
        setSelectedOrder(null);
    };


    function getDiscountAmountFromDiscountList(discountList){
        if(!discountList || discountList.length == 0) return 0;
        else{
            let walletDiscountObject = discountList.find(item => item.name == "Wallet");
            let amount = walletDiscountObject?.name ?  walletDiscountObject.amountMoney?.amount : 0;
            return amount  
        }
      

    }
    return (
        <Modal className="modal fade" size="lg" show={!!selectedOrder} onHide={handleCancel} >
            <div className="modal-header">
                <h4 className="modal-title fs-20">Order Detail</h4>
                <button type="button" className="btn close" onClick={() => handleCancel()}>
                    <span>×</span>
                </button>
            </div>
            {!!selectedOrder?.createdAt &&
                <div className="modal-body">
                    <div className="add-contact-box">
                        <div className="form-group px-2 pb-2 border-bottom">
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Date</label>
                                <div className="name">
                                    {humanReadableFormattedDateString(selectedOrder.createdAt)}
                                </div>
                            </div>
                        </div>
                        <div className="form-group px-2 pb-2 border-bottom">
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Customer</label>
                                <div className="name">
                                    {selectedOrder.customer}
                                </div>
                            </div>
                        </div>
                        <div className="form-group px-2 pb-2 border-bottom">
                            {selectedOrder.lineItems?.map(item => {
                                return (
                                    <>
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className="text-black font-w500">{item.name} <span className="mx-2">•</span> {item.quantity} Quantity</label>
                                            <div className="name">
                                                {item.variationTotalPriceMoney?.amount ? currencyFormat(item.variationTotalPriceMoney?.amount) : "Free"}
                                            </div>
                                        </div>
                                        {
                                            item.modifiers?.map(modifier => {
                                                return (
                                                    <div className='d-flex justify-content-between align-items-center'>
                                                        <label className="text-black fs-12">{modifier.name}</label>
                                                        <div className="name fs-12">
                                                            {modifier.basePriceMoney?.amount ? currencyFormat(modifier.basePriceMoney?.amount) : "Included"}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </>
                                )
                            })}
                        </div>
                        {/* {!!selectedOrder.discounts?.length &&
                            <div className="form-group px-2 pb-2 border-bottom">
                                {selectedOrder.discounts.map(x => {
                                    return (
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className="text-black font-w500">Discount <span className="mx-2">•</span> {x.name} <span className="mx-2">•</span> {x.type.replace("_", " ")}</label>
                                            <div className="name">
                                                {currencyFormat(x.amountMoney?.amount)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        } */}
                        {!!selectedOrder.walletCashbacks?.length &&
                            <div className="form-group px-2 pb-2 border-bottom">
                                {selectedOrder.walletCashbacks.map(x => {
                                    return (
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className="text-black font-w500">Cashback <span className="mx-2">•</span> {x.name} <span className="mx-2">•</span> {x.type.replace("_", " ")}</label>
                                            <div className="name">
                                                {currencyFormat(x.amountMoney?.amount)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        {!!selectedOrder.bucksCashbacks?.length &&
                            <div className="form-group px-2 pb-2 border-bottom">
                                {selectedOrder.bucksCashbacks.map(x => {
                                    return (
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <label className="text-black font-w500">Cashback <span className="mx-2">•</span> {x.name} <span className="mx-2">•</span> {x.type.replace("_", " ")}</label>
                                            <div className="name">
                                                {currencyFormat(x.amountMoney?.amount).replace('$', 'B')}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        <div className="form-group px-2 pb-2 border-bottom">
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Sub Total</label>
                                <div className="name">
                                    {currencyFormat(selectedOrder.totalMoney?.amount)}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Tax</label>
                                <div className="name">
                                    +{currencyFormat(selectedOrder.totalTaxMoney?.amount)}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Tip</label>
                                <div className="name">
                                    +{currencyFormat(selectedOrder.totalTipMoney?.amount)}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Discount</label>
                                <div className="name">
                                    -{currencyFormat(selectedOrder.totalDiscountMoney?.amount)}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Wallet</label>
                                <div className="name">
                                    -{currencyFormat(getDiscountAmountFromDiscountList(selectedOrder.discounts))}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Cashback <span className="mx-2">•</span> Wallet</label>
                                <div className="name">
                                    {currencyFormat(selectedOrder.totalWalletCashback?.amount)}
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Cashback <span className="mx-2">•</span> Birch Bucks</label>
                                <div className="name">
                                    {currencyFormat(selectedOrder.totalBucksCashback?.amount, 0).replace("$", "")}
                                </div>
                            </div>
                        </div>
                        <div className="form-group px-2 pb-2 border-bottom">
                            <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Gross</label>
                                <div className="name">
                                    {currencyFormat(selectedOrder.totalMoney?.amount + selectedOrder.totalTaxMoney?.amount + selectedOrder.totalTipMoney?.amount - selectedOrder.totalDiscountMoney?.amount)}
                                </div>
                            </div>
                            {/* <div className='d-flex justify-content-between align-items-center'>
                                <label className="text-black font-w500">Due</label>
                                <div className="name">
                                    {currencyFormat(selectedOrder.netAmountDueMoney?.amount)}
                                </div>
                            </div> */}
                        </div>
                        {!!selectedOrder.refundedAmountMoney?.amount &&
                            <div className="form-group px-2 pb-2">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <label className="text-black font-w500">Refund</label>
                                    <div className="name">
                                        <span className="fs-14">
                                            {currencyFormat(selectedOrder.refundedAmountMoney?.amount)} Refunded
                                        </span>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            }
            <div className="modal-footer d-flex text-center">
                <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Close</button>
            </div>
        </Modal>
    );
};

export default OrderDetailModal;
