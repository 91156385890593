import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { connect } from "react-redux";
import { getGraphData } from "../../services/DashboardService";
import { formattedDateString } from "../../services/UtilsService";

const options = {
  maintainAspectRatio: false,
  tooltips: {
    enabled: false,
  },
  legend: {
    display: false,
    labels: {
      usePointStyle: false,
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
    xAxes: [
      {
        // Change here
        barPercentage: 0.6,
        gridLines: {
          display: false,
          drawBorder: false,
        },
      },
    ],
  },
};

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            label: this.props.graphType.toUpperCase(),
            data: [],
            borderColor: "#FF3333",
            borderWidth: "1",
            backgroundColor: "#ffeae6",
          }
        ],
      }
    };
  }

  componentDidMount() {
    this.fetchGraphData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.locationId == this.props.locationId && prevProps?.graphType == this.props.graphType && prevProps?.startDate == this.props.startDate && prevProps?.endDate == this.props.endDate) {

    } else {
      this.fetchGraphData();
    }
  }

  fetchGraphData() {
    getGraphData(this.props.locationId, this.props.startDate, this.props.endDate, this.props.graphType).then(response => {
      const responseData = response.data?.data;
      const labels = responseData.result.map(labels => {
        return new Date(labels[0]).toDateString().slice(4, -5);
        // return formattedDateString(labels[0]);
      });
      const stateData = { ...this.state.data };
      stateData.labels = labels;
      stateData.datasets[0].data = responseData.array2;
      this.setState({ data: stateData });
    }).catch(error => {
      console.error(error);
    });
  }

  render() {
    return (
      <>
        <Bar data={this.state.data} height={290} options={options} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    locationId: state.location.selectedLocationId
  }
};

export default connect(mapStateToProps)(BarChart);
