import { useRef } from "react";
import { toFixedNumber } from "../../services/UtilsService";

export const NumberInput = (props) => {
    const { min, max, isDecimal, value, onChange, onBlur, ...leftOver } = props;
    const el = useRef();
    const checkKey = (e) => {
        const keys = "1234567890.".split("");
        keys.push("Backspace");
        keys.push("Delete");
        keys.push("Tab");
        if (keys.includes(e.key)) {

        } else {
            e.preventDefault();
            return false;
        }
    }

    const onChangeWithValidation = (e) => {
        if (e.target.value !== "") {
            let val = toFixedNumber(parseFloat(e.target.value), isDecimal ? 2 : 0);
            let minNo = toFixedNumber(parseFloat(min), isDecimal ? 2 : 0) || 0;
            let maxNo = toFixedNumber(parseFloat(max),isDecimal ? 2 : 0) || 0;
            if (minNo <= maxNo || !maxNo) {
                if (minNo > 0) {
                    if (val < minNo) {
                        el.current.value = minNo || "";
                        if(onBlur) onBlur(e);
                        if(onChange) onChange(e);
                        return;
                    }
                }
                if (maxNo > 0) {
                    if (val > maxNo) {
                        el.current.value = maxNo || "";
                        if(onBlur) onBlur(e);
                        if(onChange) onChange(e);
                        return;
                    }
                }
            }
            el.current.value = val || "";
            if(onBlur) onBlur(e);
            if(onChange) onChange(e);
            return;
        }
        if(onBlur) onBlur(e);
        if(onChange) onChange(e);
    };
    return (
        <input ref={el} defaultValue={value && value !== "0" && value !== "0.00" ? parseFloat(value).toFixed(isDecimal ? 2 : 0) : ""} onKeyDown={checkKey} onKeyUp={checkKey} onChange={onChange} onBlur={onChangeWithValidation} {...leftOver} />
    );
};