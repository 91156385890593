import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { isMobile } from "../../services/UtilsService";
import { deleteUser, getUsers, toggleBlockUser } from "../../services/UserService";
import ReactPaginate from 'react-paginate';
import useDebounce from "../../services/Debounce";
import { s3Url } from "../../services/AWSS3";
import noImg from "../../img/no-image-available.png";

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const q = new URLSearchParams(useLocation().search);
    const [paginator, setPaginator] = useState({
        current: 0,
        pageCount: 0,
        totalUsers: 0,
        limit: 10,
        showing: {
            from: 0,
            to: 0
        }
    });

    useEffect(() => {
        if (q.get('search') && !search != q.get('search')) {
            setSearch(q.get('search'));
        }
    }, [q.get('search')]);

    useDebounce(() => {
        populateUsersData(q.get('page') && q.get('page') > 0 ? q.get('page') : 0, search);
    }, [search], 300);

    const populateUsersData = (page = 0) => {
        setLoading(true);
        getUsers(search, page, paginator.limit).then(response => {
            const responseData = response.data?.data;
            setUsers(responseData.users || []);
            paginator.current = parseInt(page);
            paginator.pageCount = Math.ceil(responseData.totalUsers / paginator.limit);
            paginator.totalUsers = responseData.totalUsers;
            paginator.showing.from = (paginator.current * paginator.limit) + 1;
            paginator.showing.to = ((paginator.current + 1) * paginator.limit);
            if (paginator.showing.to > paginator.totalUsers) {
                paginator.showing.to = paginator.totalUsers;
            }
            setPaginator(paginator);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onOff = (checked, id) => {
        toggleBlockUser(id, !checked).then(response => {
            users.map(row => {
                if (row._id == id) {
                    row.isBlocked = !checked;
                }
            });
            setUsers([...users]);
        }).catch(error => {
            console.error(error);
        });
    };

    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the user",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteUser(id).then((response) => {
                    const newUsers = users.filter(x => {
                        return x._id !== id;
                    });
                    setUsers([...newUsers]);
                    swal(
                        "Poof! Your user is deleted",
                        {
                            icon: "success",
                        }
                    );
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <Fragment>
            <div className="form-head d-flex mb-2 mb-lg-4 align-items-center">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Users</h2>
                    <p className="mb-0 d-none d-md-block d-lg-block">Manage users and their orders!</p>
                </div>
                <div className={isMobile() ? 'w-75' : `w-25`}>
                    <input type="text" className="form-control w-100" placeholder="Search users by name/email" value={search} onChange={handleSearch} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {loading && !users.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !users.length) &&
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {
                                        !loading && !users.length && <>Nothing Found</>
                                    }
                                    {
                                        !!users.length &&
                                        <div className="dataTables_wrapper no-footer">
                                            <table className="table mb-0 table-striped respon-table-data">
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Status</th>
                                                        <th className="text-right">Actions</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody id="customers">
                                                    {users.map(row => {
                                                        return (
                                                            <tr className="btn-reveal-trigger">
                                                                <td className="py-3">
                                                                    <Link to={`/users/${row._id}`}>
                                                                        <div className="media d-flex align-items-center">
                                                                            <div className="avatar avatar-xl mr-2">
                                                                                <div className="position-relative">
                                                                                    {!!row.profilePic ?
                                                                                        <img
                                                                                            className="rounded-circle img-fluid"
                                                                                            src={`${s3Url}/${row.profilePic}`}
                                                                                            width="30"
                                                                                            height="30"
                                                                                            alt=""
                                                                                        /> : <div className="user-icon border rounded-circle"><i className="fs-24 las la-user"></i></div>
                                                                                    }
                                                                                    {!!row.userType?.status?.image &&
                                                                                        <img
                                                                                            className="rounded-circle img-fluid status-img"
                                                                                            src={`${s3Url}/${row.userType?.status?.image}`}
                                                                                            width="30"
                                                                                            alt={row.userType?.status?.title}
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="media-body">
                                                                                <h5 className="mb-0 fs--1">
                                                                                    {row.firstName} {row.lastName}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td className="py-2">
                                                                    <a href={"mailto:" + row.email}>
                                                                        {row.email}
                                                                    </a>
                                                                </td>
                                                                <td>{row.countryCode} {row.phoneNumber}</td>
                                                                <td>{!!row.isVerified ? <span className="badge text-success">Verified</span> : <span className="badge text-danger">Verification Pending</span>}</td>
                                                                <td className="py-2 text-right">
                                                                    <div className="d-flex align-items-center justify-content-right">
                                                                        <div className="ml-auto switch-btn-sm-width">
                                                                            <BootstrapSwitchButton
                                                                                checked={!row?.isBlocked}
                                                                                onlabel='Block'
                                                                                offlabel='Unblock'
                                                                                size="sm"
                                                                                onChange={(checked) => {
                                                                                    onOff(checked, row._id);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <Link to="#" className="btn btn-danger shadow btn-xs sharp ml-2"
                                                                                onClick={() => handleDeleteClick(row._id)}
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="menu-arrow-box">
                                                                    <Link to={`/users/${row._id}?${paginator.current > 0 ? `page=${paginator.current}` : ``}${search ? `&search=${search}` : ``}`}>
                                                                        <div className="menu-arrow">
                                                                            <i className="fs-32 las la-angle-double-right"></i>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                                                <div className="dataTables_info">
                                                    Showing {paginator.showing.from} to {paginator.showing.to}{" "}
                                                    of {paginator.totalUsers} entries
                                                </div>
                                                {!!paginator.pageCount && paginator.pageCount > 1 &&
                                                    <ReactPaginate
                                                        breakLabel="..."
                                                        nextLabel=">"
                                                        onPageChange={(event) => { populateUsersData(event.selected); }}
                                                        pageRangeDisplayed={isMobile() ? 2 : 4}
                                                        marginPagesDisplayed={1}
                                                        initialPage={paginator.current}
                                                        pageCount={paginator.pageCount}
                                                        previousLabel="<"
                                                        renderOnZeroPageCount={null}
                                                        pageClassName="paginate_button"
                                                        pageLinkClassName=""
                                                        previousClassName=""
                                                        previousLinkClassName="btn btn-sm btn-light"
                                                        nextClassName=""
                                                        nextLinkClassName="btn btn-sm btn-light"
                                                        breakClassName=""
                                                        breakLinkClassName=""
                                                        containerClassName="dataTables_paginate paging_simple_numbers d-flex align-items-center"
                                                        activeClassName="current"
                                                    />
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default UserManagement;
