import React, { useEffect, useState } from "react";
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isValidDate } from "../../services/UtilsService";

function OpenCloseTime({ initData, save }) {
    const [timeData, setTimeData] = useState({
        startTime: null,
        endTime: null,
        disabled: false
    });
    useEffect(() => {
        if (!timeData?.startTime) {
            timeData.startTime = new Date();
            timeData.startTime.setHours(initData.startHours || 9);
            timeData.startTime.setMinutes(initData.startMinutes || 0);
            setTimeData({ ...timeData });
        }
        if (!timeData?.endTime) {
            timeData.endTime = new Date();
            timeData.endTime.setHours(initData.endHours || 20);
            timeData.endTime.setMinutes(initData.endMinutes || 0);
            setTimeData({ ...timeData });
        }
    }, []);
    const openTimeChange = (event) => {
        if (isValidDate(event)) {
            timeData.startTime = event;
            setTimeData({ ...timeData });
            save(timeData);
        }
    };

    const closeTimeChange = (event) => {
        if (isValidDate(event)) {
            timeData.endTime = event;
            setTimeData({ ...timeData });
            save(timeData);
        }
    };

    return (
        <>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <div className="d-flex">
                    <h4 className="text-black mb-0 font-w500">{initData.startTimeLabel}</h4>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        mask="__:__ _M"
                        label=""
                        value={timeData.startTime}
                        onChange={openTimeChange}
                        disabled={!!initData.disabled}
                    />
                </MuiPickersUtilsProvider>
            </div>
            <div className='d-flex justify-content-between align-items-center mb-2'>
                <div className="d-flex">
                    <h4 className="text-black mb-0 font-w500">{initData.endTimeLabel}</h4>
                </div>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        mask="__:__ _M"
                        value={timeData.endTime}
                        onChange={closeTimeChange}
                        disabled={!!initData.disabled}
                    />
                </MuiPickersUtilsProvider>
            </div>
        </>
    );
}

export default OpenCloseTime;
