/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { isMobile } from "../../../services/UtilsService";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  handleLinkClick = (e) => {
    if (isMobile()) {
      document.querySelector('.hamburger').click();
    }
    // this logic works when there is only one expand/collapsed menu
    if (e.target.closest('li li') === null) {
      if (document.querySelector('.metismenu .mm-show')) {
        document.querySelector('.metismenu .has-arrow').click();
      }
      if (document.querySelector('.metismenu > li.mm-active')) {
      }
    }
    this.props.onClick();
  }
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    const menu = [
      "menu",
      "menus",
      "submenus",
      "items",
      "lists",
      "modifiers"
    ];
    const dashboard = [
      ""
    ];
    const settings = [
      "settings"
    ];

    const admins = [
      "admins"
    ];
    
    const notifications = [
      "notifications"
    ];

    const rewards = [
      "rewards"
    ];

    const users = [
      "users"
    ];

    const discountRequests = [
      "discount/requests"
    ];

    const rewardsDiscounts = [...discountRequests, ...rewards];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashboard.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" onClick={this.handleLinkClick} to="/" >
                <i className="flaticon-381-television"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${menu.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" onClick={this.handleLinkClick} to="/menu" >
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Menu</span>
              </Link>
            </li>
            <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" onClick={this.handleLinkClick} to="/settings" >
                <i className="flaticon-381-settings-2"></i>
                <span className="nav-text">Settings</span>
              </Link>
            </li>
            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" onClick={this.handleLinkClick} to="/users" >
                <i class="las la-users"></i>
                <span className="nav-text">Users</span>
              </Link>
            </li>
            <li className={`${rewardsDiscounts.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#" >
                <i class="las la-percentage"></i>
                <span className="nav-text">Rewards/Discounts</span>
              </Link>
              <ul>
                <li>
                  <Link className={`${discountRequests.includes(path) ? "mm-active" : ""}`}
                    onClick={this.handleLinkClick} to="/discount/requests">
                    Discount Requests
                  </Link>
                </li>
                <li>
                  <Link className={`${rewards.includes(path) ? "mm-active" : ""}`}
                    onClick={this.handleLinkClick} to="/rewards" >
                    Rewards Settings
                  </Link>
                </li>
              </ul>
            </li>

            {!this.props.isSubAdmin &&
              <li className={`${admins.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" onClick={this.handleLinkClick} to="/admins" >
                  <i class="las la-user-friends"></i>
                  <span className="nav-text">Admins</span>
                </Link>
              </li>
            }
            <li className={`${notifications.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" onClick={this.handleLinkClick} to="/notifications" >
                  <i class="las la-bell"></i>
                  <span className="nav-text">Send Notifications</span>
                </Link>
              </li>
          </MM>
          {/* <div className="bottom-menu">
            <MM className="metismenu" id="menu">
              <li className={`${settings.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" onClick={this.handleLinkClick} to="#">
                  <i className="las la-question-circle"></i>
                  <span className="nav-text">Support</span>
                </Link>
              </li>
            </MM>
          </div> */}
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
