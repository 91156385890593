import axios from 'axios';
import { logout } from '../store/actions/AuthActions';
import { store } from '../store/store';
import { createBrowserHistory } from 'history';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const state = store.getState();
    const token = state.auth.auth.token;
    config.headers.Authorization = "Bearer " + token.token;
    return config;
});

axiosInstance.interceptors.response.use(response => {
    return response;
}, error => {
    console.error(error);
    if (error.response) {
        const { status } = error.response;
        const { dispatch } = store;

        if (status === 401 || status === 403) {
            const history = createBrowserHistory();
            dispatch(logout(history));
        }
    }
    return Promise.reject(error);
});

export default axiosInstance;
