import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';

const ListSurchargeModal = ({ showModal, setShowModal, save, modifierListIndex = -1, setModifierListIndex }) => {
    const [surchargeError, setSurchargeError] = useState("");
    const [surcharge, setSurcharge] = useState(0);

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        var error = false;
        if (!surcharge) {
            error = true;
            setSurchargeError('Please fill price');
        }
        if (!error) {
            save(surcharge, modifierListIndex);
            setSurcharge(0);
        }

    };

    const handleChangeSurcharge = (event) => {
        if (event?.target) {
            setSurcharge(event.target.value);
        } else {
            setSurcharge(event);
        }
    };

    const handleCancel = () => {
        setSurchargeError("");
        setSurcharge(0);
        setShowModal(false);
        setModifierListIndex(-1);
    };
    return (
        <Modal className="modal fade flex-modal" show={!!showModal} onHide={handleCancel} >
            {!!showModal && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">Edit List</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <div className="name">
                                    <CurrencyInput id="input-example"
                                        className="form-control"
                                        prefix="$"
                                        decimalsLimit={2}
                                        placeholder="Enter list surcharge"
                                        defaultValue={surcharge}
                                        onValueChange={(value) => handleChangeSurcharge(value)}
                                        autoComplete='off'
                                    />
                                    <span className="validation-text text-danger">{surchargeError}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>}
        </Modal>
    );
};

export default ListSurchargeModal;
