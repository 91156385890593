import axiosInstance from './AxiosInstance';

export function getStatuses() {
    return axiosInstance.get(`/admin/user/status`);
}

export function addStatus(statusData) {
    return axiosInstance.post(`/admin/user/status`, statusData);
}

export function editStatus(statusData) {
    console.log(statusData, "from function");
    return axiosInstance.put(`/admin/user/status`, statusData);
}

export function deleteStatus(statusId) {
    return axiosInstance.delete(`/admin/user/status?adminId=${statusId}`);
}