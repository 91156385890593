import axiosInstance from './AxiosInstance';

export function getRewards() {
    return axiosInstance.get(`/admin/user/rewards`);
}

export function addReward(rewardData) {
    return axiosInstance.post(`/admin/user/rewards`, rewardData);
}

export function editReward(rewardData) {
    console.log(rewardData, "from function");
    return axiosInstance.put(`/admin/user/rewards`, rewardData);
}

export function deleteRewards(rewardId) {
    return axiosInstance.delete(`/admin/user/rewards?rewardId=${rewardId}`);
}