import React, { Fragment, useEffect, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { deleteUser, getUserDetails, toggleBlockUser } from '../../services/UserService';
import { currencyFormat } from '../../services/UtilsService';
import OrderManagement from './OrderManagement';
import { DateRangePicker } from 'react-date-range';
import TransactionsHistory from './TransactionsHistory';
import swal from "sweetalert";
import DownloadReportModal from '../modals/DownloadReportModal';
import { s3Url } from '../../services/AWSS3';
import noImg from "../../img/no-image-available.png";
import RechargeWalletModal from '../modals/RechargeWalletModal';
import ChangeDiscountCategoryModal from '../modals/ChangeDiscountCategoryModal';

const UserProfile = () => {
    const params = useParams();
    const q = new URLSearchParams(useLocation().search);
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showRecharge, setShowRecharge] = useState(false);
    const [selectionRange, setSelectionRange] = useState({
        endDate: new Date(),
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        key: 'selection',
        label: 'Last 7 days'
    });
    const [activeToggle, setActiveToggle] = useState("orders");
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [rechargeDone, setRechargeDone] = useState(false);
    const [selectedDiscountCategory, setSelectedDiscountCategory] = useState(null);

    useEffect(() => {
        populateUserData();
    }, []);

    const populateUserData = () => {
        setLoading(true);
        getUserDetails(params.id).then(response => {
            const responseData = response.data.data;
            if (responseData) {
                setSelectedUser(responseData);
            }
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onOff = (checked, id) => {
        swal({
            title: "Are you sure?",
            text: "Users session will be logged out! You can block/unblock anytime!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willBlock) => {
            if (willBlock) {
                toggleBlockUser(id, !checked).then(response => {
                    selectedUser.isBlocked = !checked;
                    setSelectedUser({ ...selectedUser });
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        });
    };

    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the user",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteUser(id).then((response) => {
                    swal(
                        "Poof! Your user is deleted",
                        {
                            icon: "success",
                        }
                    ).then(() => {
                        // go to users
                    });
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const presets = [
        { label: 'Today', start: new Date(), end: new Date() },
        { label: 'Last Week', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() }
    ];

    const handleSelect = (ranges) => {
        selectionRange.startDate = ranges.selection.startDate;
        selectionRange.endDate = ranges.selection.endDate;
        selectionRange.label = selectionRange.startDate.toDateString();
        if (selectionRange.startDate.toDateString() != selectionRange.endDate.toDateString()) {
            selectionRange.label += " - " + selectionRange.endDate.toDateString();
        }
        setSelectionRange({ ...selectionRange });
        document.querySelector('.date-range-dropdown button.dropdown-toggle').click();
    }
    function handleRechargeClick() {
        setShowRecharge(true);
    }

    const handleChangeDiscountCategory = () => {
        setSelectedDiscountCategory({ ...selectedUser.discountCategory?.categoryId, userId: selectedUser._id });
    };

    const handleSaveDiscountCategory = () => {
        setSelectedDiscountCategory(null);
        populateUserData();
    };
    const handleCancelDiscountCategory = () => {
        setSelectedDiscountCategory(null);
    };

    return (
        <>
            <RechargeWalletModal
                show={showRecharge}
                onHide={() => setShowRecharge(false)}
                userId={params.id}
                refetchData={() => setRechargeDone(true)}
            />
            <DownloadReportModal showModal={showDownloadModal} setShowModal={setShowDownloadModal} type="user" userId={params.id}></DownloadReportModal>
            <ChangeDiscountCategoryModal selectedDiscountCategory={selectedDiscountCategory} setSelectedDiscountCategory={setSelectedDiscountCategory} save={handleSaveDiscountCategory} cancel={handleCancelDiscountCategory}></ChangeDiscountCategoryModal>
            {loading && !selectedUser && <Spinner animation="border" variant="primary" />}
            {!!selectedUser &&
                <Fragment>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="profile card card-body px-3 pt-0 pb-0">
                                <div className="profile-head">
                                    <div className="profile-info d-flex align-items-center">
                                        <div className="profile-photo position-relative">
                                            {selectedUser?.profilePic &&
                                                <img
                                                    width={'76px'}
                                                    src={`${s3Url}/${selectedUser.profilePic}`}
                                                    className="img-fluid rounded-circle"
                                                    alt="profile"
                                                />
                                            }
                                            {!!selectedUser.userType?.status?.image &&
                                                <img
                                                    className="rounded-circle img-fluid status-img-lg"
                                                    src={`${s3Url}/${selectedUser.userType?.status?.image}`}
                                                    width="30"
                                                    alt=""
                                                />
                                            }
                                        </div>
                                        <div className="profile-details">
                                            <div className="profile-name px-3 pt-2">
                                                <h4 className="text-primary mb-0">{selectedUser.firstName} {selectedUser.lastName}</h4>
                                                <p className="text-muted mb-0">{selectedUser.email}</p>
                                            </div>
                                            <div className="profile-email px-2 pt-2">
                                                <p className="text-muted mb-0">{selectedUser.countryCode} {selectedUser.phoneNumber}</p>
                                                {selectedUser.isVerified && <span className='badge text-success p-0'>Verified</span>}
                                                {!selectedUser.isVerified && <span className='badge text-danger p-0'>Not Verified</span>}
                                            </div>
                                            <Link className="btn mr-2 ml-auto back-btn" to={`/users?${q.get('page') && q.get('page') > 0 ? `page=${q.get('page')}` : ``}${q.get('search') ? `&search=${q.get('search')}` : ``}`}>
                                                <i className="las la-undo mr-0 mr-lg-2"></i>
                                                <span className="d-none d-lg-inline-block">Back</span>
                                            </Link>
                                            <Dropdown className="dropdown ml-2 mt-2">
                                                <Dropdown.Toggle
                                                    variant="primary"
                                                    className="btn btn-primary light sharp i-false"
                                                    data-toggle="dropdown"
                                                    aria-expanded="true"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                        width="18px"
                                                        height="18px"
                                                        viewBox="0 0 24 24"
                                                        version="1.1"
                                                    >
                                                        <g
                                                            stroke="none"
                                                            strokeWidth="1"
                                                            fill="none"
                                                            fillRule="evenodd"
                                                        >
                                                            <rect x="0" y="0" width="24" height="24"></rect>
                                                            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                                                            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                                                            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                                                        </g>
                                                    </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                    {!!selectedUser.isBlocked &&
                                                        <Dropdown.Item className="dropdown-item" onClick={() => onOff(true, selectedUser._id)}>
                                                            <i className="fa fa-ban text-primary mr-2" />
                                                            Unblock
                                                        </Dropdown.Item>
                                                    }
                                                    {!selectedUser.isBlocked &&
                                                        <Dropdown.Item className="dropdown-item" onClick={() => onOff(false, selectedUser._id)}>
                                                            <i className="fa fa-ban text-primary mr-2" />
                                                            Block
                                                        </Dropdown.Item>
                                                    }
                                                    <Dropdown.Item className="dropdown-item" onClick={() => handleDeleteClick(selectedUser._id)}>
                                                        <i className="fa fa-trash text-primary mr-2" />
                                                        Delete
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div class="mail-list card-body p-0">
                                            <div class="list-group-item">
                                                <i class="las la-coffee font-18 align-middle mr-2"></i>Total Orders <span class="badge badge-primary badge-sm float-right">{selectedUser.totalOrders || '0'}</span>
                                            </div>
                                            <div class="list-group-item">
                                                <i class="las la-leaf font-18 align-middle mr-2"></i>Vegan Only? <span class="badge badge-primary badge-sm float-right">{selectedUser.veganOnly ? 'Yes' : 'No'}</span>
                                            </div>
                                            <div class="list-group-item">
                                                <img
                                                    className="rounded-circle img-fluid align-middle group-icon-img"
                                                    src={!!selectedUser.userType?.status?.image ? `${s3Url}/${selectedUser.userType?.status?.image}` : noImg}
                                                    width="16"
                                                    alt=""
                                                />Reward Status
                                                <span class="small float-right">
                                                    <div className="media d-flex align-items-center">
                                                        <div className="media-body">
                                                            <span className="badge badge-sm badge-primary">
                                                                {selectedUser.userType?.status?.title}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div class="list-group-item d-flex align-items-center">
                                                <i class="las la-percent font-18 align-middle mr-2"></i>Discount Category
                                                <Link to="#" className='p-0 ml-2 text-primary' onClick={handleChangeDiscountCategory} role="button">
                                                    <i className='las la-pen font-18' />
                                                </Link>
                                                {!!selectedUser.discountCategory?.categoryId &&
                                                    <span class="badge badge-primary badge-sm ml-auto text-capitalize">{selectedUser.discountCategory?.categoryId?.title}</span>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="card">
                                        <div class="mail-list card-body p-0">
                                            <div class="list-group-item">
                                                <i class="las la-phone-volume font-18 align-middle mr-2"></i>
                                                Can be contacted for orders?
                                                <span class="float-right">
                                                    <i class={"fa font-18 p-0 align-middle " + (selectedUser.contactAboutOrders ? 'text-success fa-check' : 'fa-times')}></i>
                                                </span>
                                            </div>
                                            <div class="list-group-item">
                                                <i class="las la-money-bill-wave font-18 align-middle mr-2"></i> Total Spends
                                                <span class="badge badge-primary badge-sm float-right">{currencyFormat(selectedUser.totalSpend, 2)} USD</span>
                                            </div>
                                            <div class="list-group-item">
                                                <i class="las la-wallet font-18 align-middle mr-2"></i> Wallet
                                                <span class="badge badge-primary badge-sm float-right">{currencyFormat(selectedUser.wallet?.balance, 2)} USD</span>
                                            </div>
                                            <div class="list-group-item">
                                                <i class="las la-coins font-18 align-middle mr-2"></i> Birch Coins
                                                <span class="badge badge-primary badge-sm float-right">{selectedUser.birchCoins?.balance || '0'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="profile-tab">
                                        <div className="custom-tab-1">
                                            <div className='d-flex flex-column flex-lg-row align-items-center justify-content-between'>
                                                <ul className="nav nav-tabs mb-2">
                                                    <li className="nav-item" onClick={() => setActiveToggle("orders")}>
                                                        <Link to="#orders" data-toggle="tab" className={`nav-link ${activeToggle === "orders" ? "active show" : ""}`}>Orders</Link>
                                                    </li>
                                                    <li className="nav-item" onClick={() => setActiveToggle("wallet")}>
                                                        <Link to="#wallet" data-toggle="tab" className={`nav-link ${activeToggle === "wallet" ? "active show" : ""}`}>Wallet</Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="#birch" data-toggle="tab" onClick={() => setActiveToggle("birch")} className={`nav-link ${activeToggle === "birch" ? "active show" : ""}`}>Birch Coins</Link>
                                                    </li>
                                                </ul>
                                                <div className='d-flex align-items-center'>
                                                    {activeToggle == "wallet" && <button
                                                        className="recharge-button  btn shadow-sm mr-2"
                                                        onClick={handleRechargeClick}
                                                    >
                                                        Recharge
                                                    </button>}
                                                    <Link to="#" className="btn btn-sm btn-primary d-flex align-items-center justify-content-center mb-2 mr-2 ml-auto" onClick={() => { setShowDownloadModal(true); }}>
                                                        <i className="las la-cloud-download-alt fs-24 mr-2"></i> <span className="d-none d-md-block d-lg-block">Download</span>
                                                    </Link>
                                                    <Dropdown className="dropdown date-range-dropdown mb-2">
                                                        <Dropdown.Toggle
                                                            className="btn btn-sm btn-primary light w-100 d-flex align-items-center svg-btn"
                                                            data-toggle="dropdown"
                                                        >
                                                            <svg
                                                                width={28}
                                                                height={28}
                                                                viewBox="0 0 28 28"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <g>
                                                                    <path
                                                                        d="M22.4281 2.856H21.8681V1.428C21.8681 0.56 21.2801 0 20.4401 0C19.6001 0 19.0121 0.56 19.0121 1.428V2.856H9.71606V1.428C9.71606 0.56 9.15606 0 8.28806 0C7.42006 0 6.86006 0.56 6.86006 1.428V2.856H5.57206C2.85606 2.856 0.560059 5.152 0.560059 7.868V23.016C0.560059 25.732 2.85606 28.028 5.57206 28.028H22.4281C25.1441 28.028 27.4401 25.732 27.4401 23.016V7.868C27.4401 5.152 25.1441 2.856 22.4281 2.856ZM5.57206 5.712H22.4281C23.5761 5.712 24.5841 6.72 24.5841 7.868V9.856H3.41606V7.868C3.41606 6.72 4.42406 5.712 5.57206 5.712ZM22.4281 25.144H5.57206C4.42406 25.144 3.41606 24.136 3.41606 22.988V12.712H24.5561V22.988C24.5841 24.136 23.5761 25.144 22.4281 25.144Z"
                                                                        fill="#2F4CDD"
                                                                    />
                                                                </g>
                                                            </svg>
                                                            <div className="text-left ml-3 mr-2 text-end">
                                                                <span className="d-block fs-16">Filter Period</span>
                                                                <small className="d-block fs-13">{selectionRange.label}</small>
                                                            </div>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                                            <DateRangePicker
                                                                ranges={[selectionRange]}
                                                                rangeColors={[
                                                                    '#FF3333'
                                                                ]}
                                                                minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                                                                maxDate={new Date()}
                                                                onChange={handleSelect}
                                                                presets={presets}
                                                            />
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>

                                            </div>
                                            <div className="tab-content">
                                                <div id="orders" className={`tab-pane fade ${activeToggle === "orders" ? "active show" : ""}`} >
                                                    <div className="my-post-content pt-1">
                                                        <OrderManagement selectionRange={selectionRange}></OrderManagement>
                                                    </div>
                                                </div>
                                                <div id="wallet" className={`tab-pane fade ${activeToggle === "wallet" ? "active show" : ""}`} >
                                                    <div className="my-post-content pt-1">
                                                        <TransactionsHistory selectionRange={selectionRange} type={'wallet'} rechargeDone={rechargeDone} resetRechargeDone={() => setRechargeDone(false)}></TransactionsHistory>
                                                    </div>
                                                </div>
                                                <div id="birch" className={`tab-pane fade ${activeToggle === "birch" ? "active show" : ""}`} >
                                                    <div className="my-post-content pt-1">
                                                        <TransactionsHistory selectionRange={selectionRange} type={'coins'} rechargeDone={false} resetRechargeDone={() => { }}></TransactionsHistory>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
        </>
    );
};

export default UserProfile;
