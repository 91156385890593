import React, { Fragment, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

import { Link } from "react-router-dom";
import swal from "sweetalert";
import { s3Url } from "../../services/AWSS3";
import noImg from "../../img/no-image-available.png";
import { deleteDiscountRequestCategories, getDiscountRequestCategories } from "../../services/DiscountRequestsService";
import NewDiscountCategory from "./NewDiscountCategory";

const DiscountCategories = () => {
    const [discountCategories, setDiscountCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDiscountCategory, setSelectedDiscountCategory] = useState(null);

    useEffect(() => {
        populateDiscountCategoriesData();
    }, []);

    const populateDiscountCategoriesData = () => {
        setLoading(true);
        getDiscountRequestCategories().then(response => {
            const responseData = response.data;
            setDiscountCategories(responseData.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const handleEditClick = (event, row) => {
        setSelectedDiscountCategory({ ...row });
    }
    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the discount category",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteDiscountRequestCategories(id).then((response) => {
                    const newDiscountCategories = discountCategories.filter(x => {
                        return x._id !== id;
                    });
                    setDiscountCategories([...newDiscountCategories]);
                    swal(
                        "Poof! Your discount category is deleted",
                        {
                            icon: "success",
                        }
                    );
                });
            }
        })
    };

    const saveModal = (event) => {
        setSelectedDiscountCategory(null);
        populateDiscountCategoriesData();
    }
    const cancelModal = (event) => {
        setSelectedDiscountCategory(null);
    }

    return (
        <Fragment>
            <div className="form-head d-flex mb-2 mb-lg-4 align-items-center">
                <div className="mr-auto">
                    {!selectedDiscountCategory &&
                        <>
                            <h2 className="text-black font-w600 mb-0">Discount Categories</h2>
                            <p className="mb-0 d-none d-md-block d-lg-block">Manage discount categories!</p>
                        </>
                    }
                    {!!selectedDiscountCategory &&
                        <>
                            <h2 className="text-black font-w600 mb-0 text-capitalize">{selectedDiscountCategory?.title || "New Discount Category"}</h2>
                            <ol className="breadcrumb d-none d-md-flex d-lg-flex">
                                <li className="breadcrumb-item">
                                    <Link to="/rewards">
                                        Discount Categories
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="#" onClick={() => cancelModal()}>
                                        Categories
                                    </Link>
                                </li>
                            </ol>
                        </>
                    }
                </div>
                {!!selectedDiscountCategory && <Link to="#" className="btn btn-light mr-2" onClick={cancelModal}>
                    <i className="las la-undo mr-0 mr-lg-2"></i>
                    <span className="d-none d-lg-inline-block">Cancel</span>
                </Link>}
                {!selectedDiscountCategory &&
                    <Button onClick={() => { setSelectedDiscountCategory({ title: '' }) }}>+ Discount Category</Button>
                }
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {loading && !discountCategories.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !discountCategories.length) &&
                        <div className="card">
                            <div className="card-body">
                                {!!selectedDiscountCategory && <NewDiscountCategory selectedDiscountCategory={selectedDiscountCategory} setSelectedDiscountCategory={setSelectedDiscountCategory} save={saveModal} cancel={cancelModal} ></NewDiscountCategory>}
                                {!selectedDiscountCategory &&
                                    <div className="table-responsive">
                                        {
                                            !loading && !discountCategories.length && <>Nothing Found</>
                                        }
                                        {
                                            !!discountCategories.length &&
                                            <table className="table mb-0 table-striped respon-table-data">
                                                <thead>
                                                    <tr>
                                                        <th>Title</th>
                                                        <th>Discount</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody id="customers">
                                                    {discountCategories.map(row => {
                                                        return (
                                                            <tr className="btn-reveal-trigger">
                                                                <td className="py-3">
                                                                    <div className="media d-flex align-items-center">
                                                                        <div className="avatar avatar-xl mr-2">
                                                                            <img
                                                                                className="rounded-circle"
                                                                                src={!!row.icon ? `${s3Url}/${row.icon}` : noImg}
                                                                                width="30"
                                                                                height="30"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h5 className="mb-0 fs--1 text-capitalize">
                                                                                {row.title}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="py-2">
                                                                    {row.percentage}%
                                                                </td>
                                                                <td className="py-2 text-right">
                                                                    <div className="d-flex align-items-center justify-content-right">
                                                                        <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                            onClick={(event) => handleEditClick(event, row)}
                                                                        >
                                                                            <i className="fa fa-pencil"></i>
                                                                        </Link>
                                                                        <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                            onClick={() => handleDeleteClick(row._id)}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default DiscountCategories;
