import { useRef } from "react";

export const TextInput = (props) => {
    const { min, max, onChange, onBlur, value, ...leftOver } = props;
    const el = useRef();
    const checkKey = (e) => {
        
    }

    const onChangeWithValidation = (e) => {
        e.target.value = e.target.value.trimStart();
        onChange(e);
    };

    const checkFinalValue = (e) => {
        if(onBlur) {
            onBlur(e);
        }
        e.target.value = e.target.value.trim();
        if(onChange) {
            onChange(e);
        }
    };

    return (
        <input ref={el} onKeyDown={checkKey} onKeyUp={checkKey} onBlur={checkFinalValue} onChange={onChangeWithValidation} value={value} {...leftOver} />
    );
};