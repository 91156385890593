import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
import DescriptionModal from '../modals/DescriptionModal';
import { Link } from 'react-router-dom';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import SizeModal from '../modals/SizeModal';
import ModifierListModal from '../modals/ModifierListModal';
import ListSurchargeModal from '../modals/ListSurchargeModal';
import { getSubMenus } from '../../services/MenuService';
import { addItemModifierList, createItem, createItemVariation, deleteItemModifierList, deleteItemVariation, getItemDetail, updateItem, updateItemModifierData, updateItemVariation } from '../../services/ItemService';
import { getModifiers } from '../../services/ModifierService';
import { currencyFormat } from '../../services/UtilsService';
import CurrencyInput from 'react-currency-input-field';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TextInput } from '../reusable/TextInput';
import { NumberInput } from '../reusable/NumberInput';

const NewItem = ({ selectedItem, setSelectedItem, menus, save, cancel }) => {
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [loading, setLoading] = useState(false);
    const [subMenus, setSubMenus] = useState([]);
    const [modifierLists, setModifierLists] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [descriptionData, setDescriptionData] = useState(null);
    const [sizeData, setSizeData] = useState(null);
    const [sizeIndex, setSizeIndex] = useState(null);
    const [showModifierListModal, setShowModifierListModal] = useState(false);
    const [showSurchargeModal, setShowSurchargeModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [showModifier, setShowModifier] = useState(-1);
    const [modifierListIndex, setModifierListIndex] = useState(-1);

    useEffect(() => {
        setSizes(selectedItem.sizes);
        populateSubMenusDropDown();
        populateModifiersData();
    }, []);

    const populateSubMenusDropDown = () => {
        getSubMenus(selectedItem.menuId).then(response => {
            const responseData = response.data.data;
            if (responseData.subMenu) {
                setSubMenus(responseData.subMenu);
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const populateModifiersData = (cancelling = false) => {
        getModifiers(locationId).then(response => {
            const responseData = response.data.data;
            if (responseData) {
                const newResponseData = responseData.modifiers.map(list => {
                    const x = selectedItem.modifierLists.find(x => x._id === list._id);
                    if (x) {
                        list = { ...x };
                        list.checked = true;
                        list.finalChecked = true;
                    }
                    return list;
                });
                setModifierLists(newResponseData);
                if (!selectedItem._id && !cancelling) {
                    // this is a case of copying
                    selectedItem.modifierLists = [];
                    setSelectedItem({ ...selectedItem });
                }
            }
        }).catch(error => {
            console.error(error);
        });
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        stopDragging(destination.index, source.index);
    }

    const stopDragging = (destinationIndex, sourceIndex) => {
        if (sourceIndex == destinationIndex) {
            return;
        }
        const newModifierLists = modifierLists?.filter(list => !!list.finalChecked)?.sort(compare).map((item, index) => {
            if (!item.finalChecked) {
                return item;
            }
            item.orderNumber = index + 1;
            if (item.orderNumber == sourceIndex) {
                return {
                    ...item,
                    orderNumber: destinationIndex
                }
            }
            if (sourceIndex < destinationIndex && item.orderNumber <= destinationIndex) {
                return {
                    ...item,
                    orderNumber: item.orderNumber - 1
                }
            }

            if (sourceIndex > destinationIndex && item.orderNumber >= destinationIndex) {
                return {
                    ...item,
                    orderNumber: item.orderNumber + 1
                }
            }
            return item;
        });
        const newModifierListsOrderCorrected = newModifierLists.sort(compare).map((modifierList, index) => {
            modifierList.orderNumber = index + 1;
            return modifierList;
        });
        setModifierLists([]);
        setTimeout(() => {
            setModifierLists([...newModifierListsOrderCorrected]);
            selectedItem.saving = true;
            setSelectedItem({ ...selectedItem });
        }, 0);
    };

    const compare = (a, b) => {
        return a.orderNumber - b.orderNumber;
    }

    const validateForm = (key = null) => {
        let error = false;
        const newErrors = key ? errors : {};
        if (key) {
            delete newErrors[key];
        }

        if (!selectedItem?.name && (key === null || key == "name")) {
            error = true;
            newErrors.name = "Please fill menu name";
        }
        if (!selectedItem?.menuId && (key === null || key == "menuId")) {
            error = true;
            newErrors.menuId = "Please select a menu";
        }
        if(!sizes.length) {
            error = true;
            newErrors.sizes = "Please add atleast one size";
        }
        setErrors({ ...newErrors });
        return error;
    }

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            setLoading(true);
            if (selectedItem._id) {
                // editing
                const request = {
                    menuId: selectedItem.menuId,
                    submenuId: selectedItem.subMenuId,
                    itemId: selectedItem._id,
                    name: selectedItem.name,
                    description: selectedItem.description,
                    isTaxExempt: !!selectedItem.isTaxExempt
                };
                updateItem(request).then(async response => {
                    // update Modifier list and variations on Item API
                    const { deletePromises, addUpdatePromises } = addUpdateItemVariations(selectedItem._id);
                    await Promise.all(deletePromises);
                    await Promise.all(addUpdatePromises);
                    const { deleteModifierPromises, addUpdateModifierPromises } = addUpdateModifierLists(selectedItem._id);
                    await Promise.all(deleteModifierPromises);
                    await Promise.all(addUpdateModifierPromises);

                    swal('Good job!', 'Successfully Updated', "success");
                    save(selectedItem);
                    setLoading(false);
                }).catch(error => {
                    console.error(error);
                    setLoading(false);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            } else {
                const newItem = {
                    menuId: selectedItem.menuId,
                    submenuId: selectedItem.subMenuId,
                    name: selectedItem.name,
                    description: selectedItem.description,
                    // isTaxExempt: selectedItem.isTaxExempt
                };

                createItem(newItem).then(async response => {
                    // add Modifier list and variations on Item API
                    const { deletePromises, addUpdatePromises } = addUpdateItemVariations(response.data.data._id);
                    await Promise.all(deletePromises);
                    await Promise.all(addUpdatePromises);
                    const { deleteModifierPromises, addUpdateModifierPromises } = addUpdateModifierLists(response.data.data._id);
                    await Promise.all(deleteModifierPromises);
                    await Promise.all(addUpdateModifierPromises);
                    swal('Good job!', 'Successfully Added', "success");
                    setLoading(false);
                    save();
                }).catch(error => {
                    console.error(error);
                    setLoading(false);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        }
    };

    const addUpdateItemVariations = (itemId = null) => {
        // this is not updating selectedItem, so will refresh the populated items
        let deletePromises = [];
        let addUpdatePromises = [];
        const sizeIds = sizes.map(x => x._id);
        // selectedItem's size doesn't exist in these sizes then delete
        selectedItem.sizes.map(x => {
            if (!sizeIds.includes(x._id)) {
                // delete
                deletePromises.push(deleteItemVariation(itemId, x._id));
            }
        });
        sizes.map(x => {
            // size exist in selectedItem's sizes, then update else add; shortcut just find id
            if (x._id) {
                addUpdatePromises.push(updateItemVariation({
                    itemId: itemId,
                    variationId: x._id,
                    size: x.itemVariationData.name,
                    pricing_type: 'fixed pricing',
                    amount: x.itemVariationData.priceMoney.amount,
                    currency: 'USD'
                }));
            } else {
                addUpdatePromises.push(createItemVariation({
                    itemId: itemId,
                    size: x.itemVariationData.name,
                    pricing_type: 'fixed pricing',
                    amount: x.itemVariationData.priceMoney.amount,
                    currency: 'USD'
                }));
            }
        });
        return { deletePromises, addUpdatePromises };
    };

    const addUpdateModifierLists = (itemId = null) => {
        // this is not updating selectedItem, so will refresh the populated items
        let deletePromises = [];
        let addUpdatePromises = [];
        const modifierListIds = modifierLists.filter(x => !!x.finalChecked).map(x => x._id);
        // selectedItem's modifiers doesn't exist in these modifierLists then delete
        selectedItem.modifierLists.map(x => {
            if (!modifierListIds.includes(x._id)) {
                deletePromises.push(deleteItemModifierList(itemId, x._id));
            }
        });
        const newModifierListIds = [];
        modifierLists.filter(x => !!x.finalChecked).map(x => {
            // size exist in selectedItem's modifiers, then update else add
            let exists = false;
            selectedItem.modifierLists.map(y => {
                if (x._id === y._id) {
                    const updateItemModifierDataRequest = {
                        itemId: itemId,
                        modifierId: x._id,
                        required: !!x.modifierListData.required,
                        modifiers: [],
                        orderNumber: x.orderNumber
                    };
                    exists = true;
                    x.modifierListData.modifiers.map(modifier => {
                        updateItemModifierDataRequest.modifiers.push({
                            modifierDataId: modifier._id,
                            name: modifier.modifierData.name,
                            amount: modifier.modifierData.priceMoney.amount,
                            currency: 'USD',
                            isAvailable: modifier.isAvailable,
                            min: x.modifierListData.quantity.minimum || 0,
                            max: x.modifierListData.quantity.maximum || 0
                        });
                    });
                    addUpdatePromises.push(updateItemModifierData(updateItemModifierDataRequest));
                }
            });
            if (!exists) {
                newModifierListIds[x._id] = x;
            }
        });
        if (Object.keys(newModifierListIds).length) {
            const addItemModifierListRequest = [];
            Object.keys(newModifierListIds).map((modifierId, k) => {
                addItemModifierListRequest.push({
                    modifierId: modifierId,
                    required: !!newModifierListIds[modifierId].modifierListData.required,
                    orderNumber: newModifierListIds[modifierId]?.orderNumber || (k + 1)
                });
            });
            addUpdatePromises.push(addItemModifierList({
                itemId: itemId,
                modifier_list_data: addItemModifierListRequest
            }));
            Object.keys(newModifierListIds).map((id, k) => {
                const addUpdateItemModifierDataRequest = {
                    itemId: itemId,
                    modifierId: id,
                    required: newModifierListIds[id].modifierListData.required,
                    modifiers: [],
                    orderNumber: newModifierListIds[id].orderNumber || (k + 1)
                };
                newModifierListIds[id].modifierListData.modifiers.map(modifier => {
                    addUpdateItemModifierDataRequest.modifiers.push({
                        modifierDataId: modifier._id,
                        name: modifier.modifierData.name,
                        amount: modifier.modifierData.priceMoney.amount,
                        currency: 'USD',
                        isAvailable: modifier.isAvailable,
                        min: newModifierListIds[id].modifierListData.quantity.minimum || 0,
                        max: newModifierListIds[id].modifierListData.quantity.maximum || 0
                    });
                });
                addUpdatePromises.push(updateItemModifierData(addUpdateItemModifierDataRequest));
            });
        }
        return { deleteModifierPromises: deletePromises, addUpdateModifierPromises: addUpdatePromises };
    };

    const handleChangeField = (event, key) => {
        selectedItem[key] = event.target.value;
        selectedItem.saving = true;
        setSelectedItem({ ...selectedItem });
        validateForm(key);
        if (key == 'menuId') {
            populateSubMenusDropDown();
        }
    };

    const handleChangeModifierField = (event, key, modifier, modifierListData = null) => {
        if (event?.target) {
            modifier[key] = event.target.value;
        } else {
            modifier[key] = event;
        }
        setSelectedItem({ ...selectedItem, saving: true });
        setModifierLists([...modifierLists]);
    };

    const handleChangeModifierToggle = (checked, key, modifier) => {
        modifier[key] = checked;
        if (key == "required") {
            modifier.quantity.minimum = 1;
        }
        setSelectedItem({ ...selectedItem, saving: true });
        setModifierLists([...modifierLists]);
    };

    const handleChangeToggle = (checked, key) => {
        selectedItem[key] = checked;
        selectedItem.saving = true;
        setSelectedItem({ ...selectedItem });
    };

    const handleCancel = () => {
        setSizes(selectedItem.sizes);
        populateModifiersData(true);
        setErrors({});
        cancel();
    };

    const addDescription = () => {
        setDescriptionData({
            title: "description",
            description: selectedItem.description || "",
            editing: !!selectedItem.description
        });
    };

    const saveDescription = (description) => {
        selectedItem.description = description;
        selectedItem.saving = true;
        setSelectedItem({ ...selectedItem });
        setDescriptionData(null);
    };

    const addSize = () => {
        setSizeData({
            size: '',
            price: 0.00
        });
        setSizeIndex(-1);
    };

    const editSize = (key) => {
        setSizeData({
            size: sizes[key].itemVariationData.name,
            price: sizes[key].itemVariationData.priceMoney.amount
        });
        setSizeIndex(key);
    };

    const saveSize = (updatedSize, updatedSizeIndex = -1) => {
        if (updatedSizeIndex === -1) {
            sizes.push({
                itemVariationData: {
                    name: updatedSize.size,
                    pricingType: 'fixed pricing',
                    priceMoney: {
                        amount: updatedSize.price,
                        currency: 'USD'
                    }
                }
            });
        } else {
            sizes[updatedSizeIndex].itemVariationData.name = updatedSize.size;
            sizes[updatedSizeIndex].itemVariationData.priceMoney.amount = updatedSize.price;
        }
        setSelectedItem({ ...selectedItem, saving: true });
        setSizes([...sizes]);
        setSizeData(null);
        setSizeIndex(-1);
        validateForm("sizes");
    };

    const deleteSize = (sizeIndex) => {
        setSelectedItem({ ...selectedItem, saving: true });
        setSizes([...sizes.filter((r, k) => k != sizeIndex)]);
        setSizeData(null);
        setSizeIndex(-1);
    };

    const addEditModifierLists = () => {
        setShowModifierListModal(true);
    };

    const saveModiferLists = (updatedModifierLists) => {
        setShowModifier(-1);
        updatedModifierLists.map(updatedList => {
            updatedList.finalChecked = updatedList.checked;
        });
        setSelectedItem({ ...selectedItem, saving: true });
        setModifierLists([...updatedModifierLists]);
        setShowModifierListModal(false);
    };

    const addEditModifierListSurcharge = (listIndex) => {
        setModifierListIndex(listIndex);
        setShowSurchargeModal(true);
    };

    const saveSurcharge = (surcharge, modifierListIndex) => {
        const mL = modifierLists.find(x => x._id === modifierListIndex);
        mL.modifierListData.modifiers = mL.modifierListData.modifiers.map(x => {
            x.modifierData.priceMoney.amount = surcharge;
            return x;
        });
        setSelectedItem({ ...selectedItem, saving: true });
        setModifierLists([...modifierLists]);
        setModifierListIndex(-1);
        setShowSurchargeModal(false);
    };

    return (
        <form className="position-relative">
            {loading &&
                <div className="loading-overlay">
                    <Spinner className="mb-5" animation="border" variant="primary" />
                </div>
            }
            <DescriptionModal descriptionData={descriptionData} setDescriptionData={setDescriptionData} save={saveDescription}></DescriptionModal>
            <SizeModal sizeData={sizeData} setSizeData={setSizeData} save={saveSize} deleteSize={deleteSize} sizeIndex={sizeIndex}></SizeModal>
            <ModifierListModal showModal={showModifierListModal} setShowModal={setShowModifierListModal} modifierLists={modifierLists} setModifierLists={setModifierLists} save={saveModiferLists}></ModifierListModal>
            <ListSurchargeModal showModal={showSurchargeModal} setShowModal={setShowSurchargeModal} save={saveSurcharge} modifierListIndex={modifierListIndex} setModifierListIndex={setModifierListIndex}></ListSurchargeModal>
            <div className="card-body">
                <i className="flaticon-cancel-12 close"></i>
                <div className="add-contact-box">
                    <div className="add-contact-content">
                        <div className="d-flex align-items-center justify-content-between pr-2">
                            <h3 className="form-title">
                                Information
                            </h3>
                        </div>
                        <div className="form-group px-2 py-3 border-bottom">
                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                <div className="d-flex">
                                    <h4 className="text-black font-w500">Name</h4>
                                    {!!errors?.name && <span className="ml-2 validation-text text-danger">({errors?.name})</span>}
                                </div>
                                <TextInput type="text" className="form-control ml-auto w-auto" autoComplete="off" required="required" value={selectedItem?.name} onChange={(event) => handleChangeField(event, 'name')} placeholder="Enter Name" />
                            </div>
                        </div>
                        <div className="form-group px-2 py-3 border-bottom">
                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                <div className="d-flex">
                                    <h4 className="text-black font-w500">Menu</h4>
                                    {!!errors?.menuId && <span className="ml-2 validation-text text-danger">({errors?.menuId})</span>}
                                </div>
                                <select className="form-control ml-auto w-auto text-capitalize" onChange={(event) => handleChangeField(event, 'menuId')} required>
                                    {menus.map(opt => {
                                        return (<option value={opt._id} selected={opt._id === selectedItem.menuId}>{opt.categoryData.name}</option>);
                                    })};
                                </select>
                            </div>
                        </div>
                        <div className="form-group px-2 py-3 border-bottom">
                            <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                <div className="d-flex">
                                    <h4 className="text-black font-w500">Sub Menu</h4>
                                    {!!errors?.subMenuId && <span className="ml-2 validation-text text-danger">({errors?.subMenuId})</span>}
                                </div>
                                <select className="form-control ml-auto w-auto text-capitalize" onChange={(event) => handleChangeField(event, 'subMenuId')} required>
                                    {subMenus.map(opt => {
                                        return (<option value={opt._id} selected={opt._id === selectedItem.subMenuId}>{opt.name}</option>);
                                    })};
                                </select>
                            </div>
                        </div>
                        <div className="form-group px-2 py-3 border-bottom">
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className="d-flex">
                                    <h4 className="text-black font-w500">Description</h4>
                                </div>
                                <Link to="#" onClick={addDescription}>
                                    <div className="menu-arrow">
                                        {!!selectedItem.description && <i className='fs-24 fa fa-pencil'></i>}
                                        {!selectedItem.description &&
                                            <i className="fs-32 las la-plus"
                                            ></i>}
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="form-group px-2 py-3 border-bottom">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h4 className="text-black font-w500 mb-0">Is this item tax exempt?</h4>
                                <BootstrapSwitchButton
                                    checked={selectedItem?.isTaxExempt !== false}
                                    onlabel='ON'
                                    offlabel='OFF'
                                    size="sm"
                                    onChange={(checked) => handleChangeToggle(checked, 'isTaxExempt')}
                                />
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between pr-2 pt-3">
                            <h3 className="form-title">
                                Sizes
                                {!!errors?.sizes && <span className="ml-2 validation-text text-danger fs-18">({errors?.sizes})</span>}
                            </h3>
                        </div>
                        {sizes.map((size, key) => {
                            return (
                                <div className="form-group px-2 py-3 border-bottom">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className="d-flex">
                                            <h4 className="text-black font-w500">{size?.itemVariationData?.name} . {currencyFormat(size?.itemVariationData?.priceMoney.amount)}</h4>
                                        </div>
                                        <Link to="#" onClick={() => editSize(key)}>
                                            <div className="menu-arrow">
                                                <i className='fs-24 fa fa-pencil'></i>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="form-group px-2 py-3 border-bottom">
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className="d-flex">
                                    <h4 className="text-black font-w500">Add a size</h4>
                                </div>
                                <Link to="#" onClick={() => addSize()}>
                                    <div className="menu-arrow">
                                        <i className="fs-32 las la-plus"></i>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between pr-2 pt-3">
                            <h3 className="form-title">
                                Modifiers
                            </h3>
                            {
                                modifierLists?.filter(list => !!list.finalChecked)?.length > 0 &&
                                <Link to="#" className="mr-2" onClick={() => addEditModifierLists()}>
                                    EDIT
                                </Link>
                            }
                        </div>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="modifierLists">
                                {(provided) => {
                                    return (
                                        <div id="modifierLists" {...provided.droppableProps} ref={provided.innerRef}>
                                            {modifierLists?.filter(list => !!list.finalChecked)?.sort(compare).map((modifierList, listIndex) => {
                                                return (
                                                    <Draggable draggableId={listIndex + ''} index={listIndex + 1} key={listIndex}>
                                                        {(provided2) => {
                                                            return (
                                                                <div {...provided2.draggableProps}
                                                                    {...provided2.dragHandleProps}
                                                                    ref={provided2.innerRef}>
                                                                    <div className="form-group px-2 py-3 border-bottom expand-collapse-title">
                                                                        <div className='d-flex justify-content-between align-items-center'>
                                                                            <h4 className="text-black font-w500 mb-0">{modifierList.modifierListData.name}{!!modifierList.modifierListData.required ? "*" : ""}</h4>
                                                                            <div className='d-flex'>
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="custom-control custom-checkbox mb-2 checkbox-primaryy check-xll" >
                                                                                        <input type="checkbox" className="custom-control-inputt"
                                                                                            name={modifierList.modifierListData.name} value={modifierList._id} checked={!!modifierList.modifierListData.required}
                                                                                            onChange={(event) => handleChangeModifierToggle(event.target.checked, 'required', modifierList.modifierListData)} />
                                                                                    </div>
                                                                                    <label className="custom-control-labell ml-3 mr-2 mb-0 text-capitalize">required
                                                                                    </label>
                                                                                </div>
                                                                                <Link to="#" className="mr-2" onClick={() => addEditModifierListSurcharge(modifierList._id)}>
                                                                                    <div className="menu-arrow">
                                                                                        <i className="fs-24 fa fa-pencil"></i>
                                                                                    </div>
                                                                                </Link>
                                                                                <Link to="#" onClick={() => { setShowModifier(listIndex == showModifier ? -1 : listIndex) }}>
                                                                                    <div className="menu-arrow">
                                                                                        {showModifier === listIndex && <i className="fs-32 las la-angle-down"></i>}
                                                                                        {showModifier !== listIndex && <i className="fs-32 las la-angle-right"></i>}
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {showModifier === listIndex &&
                                                                        <>
                                                                            <div className="form-group px-2 py-3 border-bottom">
                                                                                <div className='d-flex justify-content-between mb-3 align-items-start align-items-lg-center flex-column flex-lg-row'>
                                                                                    <div className="d-flex">
                                                                                        <h4 className="text-black font-w500">Quantity</h4>
                                                                                    </div>
                                                                                    <div className='d-flex'>
                                                                                        <NumberInput className="form-control ml-auto w-auto" autoComplete="off"
                                                                                            value={modifierList.modifierListData.quantity.minimum}
                                                                                            onChange={(event) => handleChangeModifierField(event, 'minimum', modifierList.modifierListData.quantity, modifierList.modifierListData)}
                                                                                            placeholder="Enter Minimum"
                                                                                            min={0}
                                                                                            max={modifierList.modifierListData.quantity.maximum || 0}
                                                                                        />
                                                                                        <NumberInput className="form-control ml-2 w-auto" autoComplete="off"
                                                                                            value={modifierList.modifierListData.quantity.maximum}
                                                                                            onChange={(event) => handleChangeModifierField(event, 'maximum', modifierList.modifierListData.quantity)}
                                                                                            placeholder="Enter Maximum"
                                                                                            min={modifierList.modifierListData.quantity.minimum || 0}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {modifierList.modifierListData.modifiers?.map((modifier, modifierIndex) => {
                                                                                return (
                                                                                    <>
                                                                                        <div className="form-group px-2 py-3 border-bottom">
                                                                                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <div className="custom-control custom-checkbox mb-3 checkbox-primaryy check-xll" >
                                                                                                        <input type="checkbox" className="custom-control-inputt"
                                                                                                            name={modifier.modifierData.name} value={modifier._id} checked={!!modifier.isAvailable}
                                                                                                            onChange={(event) => handleChangeModifierToggle(event.target.checked, 'isAvailable', modifier)} />
                                                                                                    </div>
                                                                                                    <label className="custom-control-labell ml-4 text-capitalize">{modifier.modifierData.name}
                                                                                                    </label>
                                                                                                </div>
                                                                                                <CurrencyInput id="input-example"
                                                                                                    className="form-control ml-auto w-auto"
                                                                                                    prefix="$"
                                                                                                    decimalsLimit={2}
                                                                                                    placeholder="Enter Price"
                                                                                                    value={modifier.modifierData.priceMoney.amount}
                                                                                                    onValueChange={(value) => {
                                                                                                        if (value < 0) { value = 0; }
                                                                                                        handleChangeModifierField(value, 'amount', modifier.modifierData.priceMoney);
                                                                                                    }}
                                                                                                    autoComplete='off'
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                );
                                                                            })}

                                                                        </>
                                                                    }
                                                                </div>
                                                            );
                                                        }}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    );
                                }}
                            </Droppable>
                        </DragDropContext>
                        {
                            modifierLists?.filter(list => !!list.finalChecked)?.length === 0 &&
                            <div className="form-group px-2 py-3 border-bottom">
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="d-flex">
                                        <h4 className="text-black font-w500">Add a modifier</h4>
                                    </div>
                                    <Link to="#" onClick={addEditModifierLists}>
                                        <div className="menu-arrow">
                                            <i className="fs-32 las la-plus"></i>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {!!selectedItem.saving &&
                <div className="card-footer text-center save-cancel">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light mr-3 w-25"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary w-25" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            }
        </form>
    );
};

export default NewItem;
