import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { changePassword } from '../../services/LocationService';

const ChangePasswordModal = ({ showModal, cancel }) => {
    const [formData, setFormData] = useState({
        oldPassword: null,
        newPassword: null,
        confirmPassword: null,
        errors: {}
    });

    const handleChangeField = (event, key) => {
        formData[key] = event.target.value;
        setFormData({ ...formData });
    };

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        formData.errors = {};
        setFormData({ ...formData });
        if (!formData?.oldPassword) {
            formData.errors['oldPassword'] = "Please fill old password";
        }
        if (!formData?.newPassword) {
            formData.errors['newPassword'] = "Please fill new password";
        }
        if (formData?.newPassword.length < 6) {
            formData.errors['newPassword'] = "New password must be atleast 6 characters long";
        }
        if (formData.newPassword !== formData.confirmPassword) {
            formData.errors['confirmPassword'] = "Confirm password doesn't match with new password";
        }
        setFormData({ ...formData });
        if (!formData.errors || !Object.values(formData.errors).length) {
            changePassword(formData.oldPassword, formData.newPassword).then(response => {
                swal('Good job!', 'Successfully Changed', "success");
                handleCancel();
            }).catch(error => {
                console.error(error);
                swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
            });
        }
    };

    const handleCancel = () => {
        setFormData({
            oldPassword: null,
            newPassword: null,
            confirmPassword: null,
            errors: {}
        });
        cancel();
    }
    return (
        <Modal className="modal fade flex-modal" show={!!showModal} onHide={handleCancel} >
            {!!showModal && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">Change Password</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Old Password</label>
                                <div className="name">
                                    <input type="password" className="form-control" autoComplete="off" required="required" value={formData.oldPassword} onChange={(event) => { handleChangeField(event, 'oldPassword') }} placeholder={"Enter old password"} />
                                    <span className="validation-text text-danger">{formData.errors?.oldPassword}</span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">New Password</label>
                                <div className="name">
                                    <input type="password" className="form-control" autoComplete="off" required="required" value={formData.newPassword} onChange={(event) => { handleChangeField(event, 'newPassword') }} placeholder={"Enter new password"} />
                                    <span className="validation-text text-danger">{formData.errors?.newPassword}</span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Confirm Password</label>
                                <div className="name">
                                    <input type="password" className="form-control" autoComplete="off" required="required" value={formData.confirmPassword} onChange={(event) => { handleChangeField(event, 'confirmPassword') }} placeholder={"Enter new password again"} />
                                    <span className="validation-text text-danger">{formData.errors?.confirmPassword}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>}
        </Modal>
    );
};

export default ChangePasswordModal;
