import axiosInstance from './AxiosInstance';

export function getDiscountRequestCategories() {
    return axiosInstance.get(`/admin/user/discountCategory`);
}

export function addDiscountRequestCategories(request) {
    return axiosInstance.post(`/admin/user/discountCategory`, request);
}

export function editDiscountRequestCategories(request) {
    return axiosInstance.put(`/admin/user/discountCategory`, request);
}

export function deleteDiscountRequestCategories(categoryId) {
    return axiosInstance.delete(`/admin/user/discountCategory?categoryId=${categoryId}`);
}

export function getDiscountRequests() {
    return axiosInstance.get(`/admin/user/discountRequests`);
}

export function acceptRejectDiscountRequests(requestid, response, reason = "") {
    const request = {
        requestid: requestid, 
        response: response == "accept" ? 'accept' : 'reject'
    };
    if(reason && response != "accept") {
        request.reason = reason;
    }
    return axiosInstance.post(`/admin/user/discountRequests`, request);
}

export function updateUserDiscountCategory(categoryId, userId) {
    const request = {
        categoryId,
        userId
    };
    return axiosInstance.put(`/admin/user/discountCategory/update`, request);
}