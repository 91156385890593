import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { useParams } from "react-router-dom";
import {
  currencyFormat,
  humanReadableFormattedDateString,
  isMobile,
} from "../../services/UtilsService";
import ReactPaginate from "react-paginate";
import { getUserTransactions } from "../../services/UserService";
import RechargeWalletModal from "../modals/RechargeWalletModal";

const TransactionsHistory = ({ selectionRange, type, rechargeDone, resetRechargeDone }) => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const [paginator, setPaginator] = useState({
    current: 0,
    pageCount: 0,
    totalOrders: 0,
    limit: 10,
    showing: {
      from: 0,
      to: 0,
    },
  });
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    populateTransactionsData();
  }, [selectionRange]);

  useEffect(() => {
    if(rechargeDone){
      populateTransactionsData();
      resetRechargeDone();
    }
  }, [rechargeDone]);

  const populateTransactionsData = (page = 0) => {
    setLoading(true);
    getUserTransactions(
      params?.id,
      type,
      selectionRange?.startDate,
      selectionRange?.endDate,
      page,
      paginator.limit
    )
      .then((response) => {
        const responseData = response.data?.data;
        const totalOrders = response.data?.value;
        const transactions =
          type == "wallet"
            ? responseData.wallet?.history
            : responseData.birchCoins?.history;
        setTransactions(transactions || []);
        paginator.current = page;
        paginator.pageCount = Math.ceil(totalOrders / paginator.limit);
        paginator.totalOrders = totalOrders;
        paginator.showing.from = paginator.current * paginator.limit + 1;
        paginator.showing.to = (paginator.current + 1) * paginator.limit;
        if (paginator.showing.to > paginator.totalOrders) {
          paginator.showing.to = paginator.totalOrders;
        }
        setPaginator(paginator);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

 
  return (
    <>
      
      <div className="row">
        <div className="col-lg-12">
          {loading && !transactions.length && (
            <Spinner animation="border" variant="primary" />
          )}
          {!(loading && !transactions.length) && (
            <div className="table-responsive">
              {!loading && !transactions.length && <>Nothing Found</>}
              {!!transactions.length && (
                <div id="order_list" className="dataTables_wrapper no-footer">
                  <table className="display mb-4 dataTablesCard dataTable no-footer w-100 position-relative pb-3">
                    <thead>
                      <tr role="row">
                        <th>Date</th>
                        <th>{type == "wallet" ? "Amount" : "Coins"}</th>
                        <th>Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions?.map((row, key) => {
                        return (
                          <tr
                            key={key}
                            role="row"
                            className={key % 2 ? "odd" : "even"}
                          >
                            <td>
                              <label className="date-label">
                                {humanReadableFormattedDateString(
                                  row.timeAdded
                                )}
                              </label>
                            </td>
                            {type == "wallet" && (
                              <td>{currencyFormat(row.amount, 2)}</td>
                            )}
                            {type !== "wallet" && <td>{row.amount}</td>}
                            <td>
                              {row.type == "Credit" ? (
                                <span className="badge badge-sm text-success">
                                  Credited
                                </span>
                              ) : (
                                <span className="badge badge-sm badge-primary">
                                  Debited
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                    <div className="dataTables_info">
                      Showing {paginator.showing.from} to {paginator.showing.to}{" "}
                      of {paginator.totalOrders} entries
                    </div>
                    {paginator.pageCount && paginator.pageCount > 1 && (
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel=">"
                        onPageChange={(event) => {
                          populateTransactionsData(event.selected);
                        }}
                        pageRangeDisplayed={isMobile() ? 2 : 4}
                        marginPagesDisplayed={1}
                        initialPage={paginator.current}
                        pageCount={paginator.pageCount}
                        previousLabel="<"
                        renderOnZeroPageCount={null}
                        pageClassName="paginate_button"
                        pageLinkClassName=""
                        previousClassName=""
                        previousLinkClassName="btn btn-sm btn-light"
                        nextClassName=""
                        nextLinkClassName="btn btn-sm btn-light"
                        breakClassName=""
                        breakLinkClassName=""
                        containerClassName="dataTables_paginate paging_simple_numbers d-flex align-items-center"
                        activeClassName="current"
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TransactionsHistory;
