import { createSelector } from 'reselect';

export const selectLocation = (state) =>
    state?.location.locations.find((location) => location._id === state?.location.selectedLocationId);

export const selectLocationOptions = (state) => state?.location.locations.map(x => {
    return {
        label: x.name,
        value: x._id
    }
});

// TODO: selector not in use
export const selectLocationOption = () => createSelector(
    [
        selectLocationOption,
        (state) => state?.location.selectedLocationId
    ],
    (locationOptions, selectedLocationId) => {
        return locationOptions.find(location => location.value === selectedLocationId);
    }
);
