import React, { Fragment, useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";

import { Link } from "react-router-dom";
import swal from "sweetalert";
import { currencyFormat, humanReadableFormattedDateString, numberThFormat } from "../../services/UtilsService";
import { s3Url } from "../../services/AWSS3";
import noImg from "../../img/no-image-available.png";
import { deleteRewards, getRewards } from "../../services/NewRewardsService";
import NewRewardsSettings from "./NewRewardSettings";

const RewardsSettings = () => {
    const [rewards, setRewards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedReward, setSelectedReward] = useState(null);

    useEffect(() => {
        populateRewardData();
    }, []);

    const populateRewardData = () => {
        setLoading(true);
        getRewards().then(response => {
            const responseData = response.data;
            setRewards(responseData.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const handleEditClick = (event, row) => {
        setSelectedReward({ ...row });
    }
    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the reward",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteRewards(id).then((response) => {
                    const newRewards = rewards.filter(x => {
                        return x._id !== id;
                    });
                    setRewards([...newRewards]);
                    swal(
                        "Poof! Your reward is deleted",
                        {
                            icon: "success",
                        }
                    );
                });
            }
        })
    };

    const saveModal = (event) => {
        setSelectedReward(null);
        populateRewardData();
    }
    const cancelModal = (event) => {
        setSelectedReward(null);
    }




    return (
        <Fragment>
            <div className="form-head d-flex mb-2 mb-lg-4 align-items-center">
                <div className="mr-auto">
                    {!selectedReward &&
                        <>
                            <h2 className="text-black font-w600 mb-0">Rewards Settings</h2>
                            <p className="mb-0 d-none d-md-block d-lg-block">Manage rewards settings for users!</p>
                        </>
                    }
                    {!!selectedReward &&
                        <>
                            <h2 className="text-black font-w600 mb-0 text-capitalize">{selectedReward?.title || "New Reward Settings"}</h2>
                            <ol className="breadcrumb d-none d-md-flex d-lg-flex">
                                <li className="breadcrumb-item">
                                    <Link to="/rewards">
                                        Rewards Settings
                                    </Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to="#" onClick={() => cancelModal()}>
                                        Rewards
                                    </Link>
                                </li>
                            </ol>
                        </>
                    }
                </div>
                {!!selectedReward && <Link to="#" className="btn btn-light mr-2" onClick={cancelModal}>
                    <i className="las la-undo mr-0 mr-lg-2"></i>
                    <span className="d-none d-lg-inline-block">Cancel</span>
                </Link>}
                {!selectedReward &&
                    <Button onClick={() => { setSelectedReward({ type: null, title: '' }) }}>+ New Settings</Button>
                }
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {loading && !rewards.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !rewards.length) &&
                        <div className="card">
                            <div className="card-body">
                                {!!selectedReward && <NewRewardsSettings selectedReward={selectedReward} setSelectedReward={setSelectedReward} save={saveModal} cancel={cancelModal} ></NewRewardsSettings>}
                                {!selectedReward &&
                                    <div className="table-responsive">
                                        {
                                            !loading && !rewards.length && <>Nothing Found</>
                                        }
                                        {
                                            !!rewards.length &&
                                            <table className="table mb-0 table-striped respon-table-data">
                                                <thead>
                                                    <tr>
                                                        <th>Type</th>
                                                        <th>Title</th>
                                                        <th>Created At</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody id="customers">
                                                    {rewards.map(row => {
                                                        return (
                                                            <tr className="btn-reveal-trigger" key={row.createdAt}>
                                                                <td className="py-2">
                                                                    {row.type}
                                                                </td>
                                                                <td className="py-3">
                                                                    <Link to="#" onClick={(event) => handleEditClick(event, row)}>
                                                                        <div className="media d-flex align-items-center">
                                                                            <div className="avatar avatar-xl mr-2">
                                                                                <img
                                                                                    className="rounded-circle"
                                                                                    src={!!row.image ? `${s3Url}/${row.image}` : noImg}
                                                                                    width="30"
                                                                                    height="30"
                                                                                    alt=""
                                                                                />
                                                                            </div>
                                                                            <div className="media-body">
                                                                                <h5 className="mb-0 fs--1">
                                                                                    {row.title}
                                                                                </h5>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                </td>
                                                                <td className="py-2">
                                                                    <label className="date-label">
                                                                        {humanReadableFormattedDateString(row.createdAt)}
                                                                    </label>
                                                                </td>
                                                                <td className="py-2 text-right">
                                                                    <div className="d-flex align-items-center justify-content-right">
                                                                        <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                            onClick={(event) => handleEditClick(event, row)}
                                                                        >
                                                                            <i className="fa fa-pencil"></i>
                                                                        </Link>
                                                                        <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                            onClick={() => handleDeleteClick(row._id)}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </Link>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default RewardsSettings;
