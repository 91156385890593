import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from "sweetalert";
import { createSubMenu, updateSubMenu } from '../../services/MenuService';
import { Link } from 'react-router-dom';
import OpenCloseTime from '../reusable/OpenCloseTime';
import { TextInput } from '../reusable/TextInput';

const SubMenuModal = ({ selectedSubMenu, setSelectedSubMenu, save, cancel }) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [showHours, setShowHours] = useState(false);
    const [nameError, setNameError] = useState("");

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        var error = false;
        setNameError("");
        if (!selectedSubMenu?.name) {
            error = true;
            setNameError('Please fill sub-menu name');
        }
        if (!error) {
            if (selectedSubMenu._id) {
                // editing
                const request = {
                    menuId: selectedSubMenu.menuId,
                    submenuId: selectedSubMenu._id,
                    name: selectedSubMenu?.name,
                    // isAvailable: !!selectedSubMenu.isAvailable,
                };
                days.map((val) => {
                    request[val] = {
                        "availability": selectedSubMenu.submenuHours[val].availability,
                        "start_hours": selectedSubMenu.submenuHours[val].firstPickupTime.hours,
                        "start_minutes": selectedSubMenu.submenuHours[val].firstPickupTime.minutes,
                        "end_hours": selectedSubMenu.submenuHours[val].lastPickupTime.hours,
                        "end_minutes": selectedSubMenu.submenuHours[val].lastPickupTime.minutes,
                    };
                });

                updateSubMenu(request).then((response) => {
                    swal('Good job!', 'Successfully Updated', "success");
                    save(selectedSubMenu);
                    setShowHours(false);
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            } else {
                const newSubMenu = {
                    menuId: selectedSubMenu.menuId,
                    name: selectedSubMenu?.name,
                };

                days.map((val) => {
                    newSubMenu[val] = {
                        "availability": selectedSubMenu.submenuHours[val].availability,
                        "start_hours": selectedSubMenu.submenuHours[val].firstPickupTime.hours,
                        "start_minutes": selectedSubMenu.submenuHours[val].firstPickupTime.minutes,
                        "end_hours": selectedSubMenu.submenuHours[val].lastPickupTime.hours,
                        "end_minutes": selectedSubMenu.submenuHours[val].lastPickupTime.minutes,
                    };
                });

                createSubMenu(newSubMenu).then((response) => {
                    swal('Good job!', 'Successfully Added', "success");
                    save();
                    setShowHours(false);
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        }

    };

    const handleCancel = () => {
        setNameError("");
        setShowHours(false);
        cancel();
    };

    const handleChangeName = (event) => {
        selectedSubMenu.name = event.target.value;
        setSelectedSubMenu({ ...selectedSubMenu });
    };

    const handleDayChange = (event, key, day) => {
        day[key] = event.target.value;
        setSelectedSubMenu({ ...selectedSubMenu });
    };

    const handleDayTimeChange = (timeData, day) => {
        day.firstPickupTime.hours = timeData.startTime.getHours();
        day.firstPickupTime.minutes = timeData.startTime.getMinutes();
        day.lastPickupTime.hours = timeData.endTime.getHours();
        day.lastPickupTime.minutes = timeData.endTime.getMinutes();
        setSelectedSubMenu({ ...selectedSubMenu });
    };

    return (
        <Modal className={"modal fade " + (!showHours ? "flex-modal" : "")} show={!!selectedSubMenu} onHide={handleCancel} >
            <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">{!!selectedSubMenu?._id ? 'Edit' : 'Add'} Submenu</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Name</label>
                                <div className="name">
                                    <TextInput type="text" className="form-control" autoComplete="off" required="required" value={selectedSubMenu?.name} onChange={handleChangeName} placeholder="Enter Name" />
                                    <span className="validation-text text-danger">{nameError}</span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <div className='form-control d-flex align-items-center justify-content-between'>
                                    <label className="text-black font-w500 fs-16 mb-0">Submenu hours</label>
                                    <Link to="#" onClick={() => { setShowHours(!showHours) }}>
                                        <div className="menu-arrow submenu-hours">
                                            {!!showHours && <i className="fs-32 las la-angle-down"></i>}
                                            {!showHours && <i className="fs-32 las la-angle-right"></i>}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {!!showHours &&
                                <>
                                    {selectedSubMenu?.submenuHours && Object.values(selectedSubMenu?.submenuHours).map((day, dayIndex) => {
                                        return (
                                            <>
                                                <h4 className="form-title pt-2">
                                                    {days[dayIndex]}
                                                </h4>
                                                <div className="form-group px-2 py-2">
                                                    <div className='d-flex justify-content-between align-items-center mb-2'>
                                                        <div className="d-flex">
                                                            <h4 className="text-black font-w500">Availability</h4>
                                                        </div>
                                                        <select className="form-control ml-auto w-auto" onChange={(event) => { handleDayChange(event, 'availability', day) }}>
                                                            <option value="all_day" selected={day.availability === 'all_day'}>All Day</option>
                                                            <option value="limited" selected={day.availability === 'limited'}>Limited</option>
                                                            <option value="unavailable" selected={day.availability === 'unavailable'}>Unavailable</option>
                                                        </select>
                                                    </div>
                                                    <OpenCloseTime initData={{
                                                        startTimeLabel: 'First Pickup Time',
                                                        endTimeLabel: 'Last Pickup Time',
                                                        startHours: day.firstPickupTime.hours,
                                                        startMinutes: day.firstPickupTime.minutes,
                                                        endHours: day.lastPickupTime.hours,
                                                        endMinutes: day.lastPickupTime.minutes,
                                                        disabled: day.availability !== 'limited'
                                                    }} save={(timeData) => {
                                                        handleDayTimeChange(timeData, day);
                                                    }}></OpenCloseTime>
                                                </div>
                                            </>
                                        );
                                    })}
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>
        </Modal>
    );
};

export default SubMenuModal;
