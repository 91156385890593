import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import MenuModal from "../modals/MenuModal";
import swal from "sweetalert";
import { deleteMenu, getMenus, menusOrder, toggleMenuAvailable, updateMenu } from "../../services/MenuService";
import { setMenuAction } from "../../store/actions/BreadcrumbActions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Menus = () => {
    const dispatch = useDispatch();
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(false);
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [selectedMenu, setSelectedMenu] = useState(null);
    const history = useHistory();
    const { onLocationId } = useSelector((state) => state.breadcrumbs);
    const [willRearrange, setWillRearrange] = useState(false);

    useEffect(() => {
        // if (onLocationId != locationId) {
        //     history.push('/menu');
        // }
        if (locationId) {
            populateMenusData();
        }
    }, [locationId]);

    const populateMenusData = () => {
        setLoading(true);
        getMenus(locationId).then(response => {
            const responseData = response.data.data;
            if (responseData.menu) {
                setMenus(responseData.menu);
            }
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onOff = (checked, id) => {
        toggleMenuAvailable(id, checked).then((reponse) => {
            const newMenus = menus.map(x => {
                if (x._id == id) {
                    x.categoryData.isAvailable = checked;
                }
                return x;
            });
            setMenus([...newMenus]);
        });
    };

    const handleEditClick = (event, menu) => {
        const selectedMenu = {
            _id: menu._id,
            name: menu?.categoryData?.name
        };
        setSelectedMenu(selectedMenu);
    };
    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the menu",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteMenu(id).then((response) => {
                    const newMenus = menus.filter(x => {
                        return x._id !== id;
                    });
                    setMenus([...newMenus]);
                    swal(
                        "Poof! Your menu is deleted",
                        {
                            icon: "success",
                        }
                    );
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const cancelModal = () => {
        setSelectedMenu(null);
    };

    const saveModal = (updatedMenu) => {
        setSelectedMenu(null);
        if (updatedMenu) {
            // updated
            const newMenus = menus.map(x => {
                if (x._id == updatedMenu._id) {
                    x.categoryData.name = updatedMenu.name;
                }
                return x;
            });
            setMenus([...newMenus]);
        } else {
            // refresh
            populateMenusData();
        }
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        stopDragging(destination.index, source.index);
    }

    const compare = (a, b) => {
        return a.orderNumber - b.orderNumber;
    }

    const stopDragging = (destinationIndex, sourceIndex) => {
        if (sourceIndex == destinationIndex) {
            return;
        }
        let newMenus = menus.sort(compare).map((menu, index) => {
            menu.orderNumber = index + 1;
            if (menu.orderNumber == sourceIndex) {
                return {
                    ...menu,
                    orderNumber: destinationIndex
                }
            }
            if (sourceIndex < destinationIndex && menu.orderNumber <= destinationIndex) {
                return {
                    ...menu,
                    orderNumber: menu.orderNumber - 1
                }
            }

            if (sourceIndex > destinationIndex && menu.orderNumber >= destinationIndex) {
                return {
                    ...menu,
                    orderNumber: menu.orderNumber + 1
                }
            }
            return menu;
        });
        setMenus([]);
        setTimeout(() => {
            setMenus([...newMenus]);
            setWillRearrange(true);
        }, 0);
    };

    const handleCancelRerrange = () => {
        setMenus([]);
        setWillRearrange(false);
        populateMenusData();
    };

    const handleSaveRearrange = () => {
        setLoading(true);
        const request = menus.sort(compare).map((menu, index) => {
            return {
                menuId: menu._id,
                orderNumber: index + 1
            }
        });
        menusOrder(request).then(response => {
            setLoading(false);
            setWillRearrange(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    return (
        <>
            <MenuModal selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} cancel={cancelModal} save={saveModal}></MenuModal>
            <div className="form-head d-flex mb-0 align-items-start">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Menus</h2>
                    <ol className="breadcrumb d-none d-md-flex d-lg-flex">
                        <li className="breadcrumb-item">
                            <Link to="/menu">
                                Main
                            </Link>
                        </li>
                    </ol>
                </div>
                <Link className="btn mr-2" to="/menu">
                    <i className="las la-undo mr-0 mr-lg-2"></i>
                    <span className="d-none d-lg-inline-block">Back</span>
                </Link>
                <Button onClick={() => { setSelectedMenu({}); }}>+ New Menu</Button>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {loading && !menus.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !menus.length) &&
                        <div className="card">
                            <div className="card-body " style={{ padding: "1.25rem" }}>
                                <div className="table-responsive no-overflow">
                                    {
                                        !loading && !menus.length && <>Nothing Found</>
                                    }
                                    {
                                        !!menus.length &&
                                        (
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <table className="table mb-0 text-black">
                                                    <thead>
                                                        <tr>
                                                            <th className="align-middle td-sm"></th>
                                                            <th className="align-middle"></th>
                                                            <th className="align-middle text-left td-sm">Actions</th>
                                                            <th className="no-sort" />
                                                        </tr>
                                                    </thead>
                                                    <Droppable droppableId="orders">
                                                        {(provided) => {
                                                            return (
                                                                <tbody id="orders" {...provided.droppableProps} ref={provided.innerRef}>
                                                                    {menus.sort(compare).map((menu, index) => {
                                                                        return (
                                                                            <Draggable draggableId={menu._id} index={index + 1} key={index}>
                                                                                {(provided2) => {
                                                                                    return (
                                                                                        <tr {...provided2.draggableProps}
                                                                                            {...provided2.dragHandleProps}
                                                                                            ref={provided2.innerRef}
                                                                                            key={menu._id} className="btn-reveal-trigger">
                                                                                            <td className="py-2">
                                                                                                <i className="fs-32 las la-coffee"></i>
                                                                                            </td>
                                                                                            <td className="py-2">
                                                                                                <h4 className="mb-0">{menu.categoryData.name} <span className="mx-2">•</span> {menu.subMenu?.length || 0} Items</h4>
                                                                                            </td>
                                                                                            <td className="py-2 text-right">

                                                                                                <div className="d-flex align-items-center justify-content-right">
                                                                                                    <BootstrapSwitchButton
                                                                                                        checked={menu?.categoryData?.isAvailable !== false}
                                                                                                        onlabel='ON'
                                                                                                        offlabel='OFF'
                                                                                                        size="sm"
                                                                                                        onChange={(checked) => {
                                                                                                            onOff(checked, menu._id);
                                                                                                        }}
                                                                                                    />
                                                                                                    <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                                                        onClick={(event) => handleEditClick(event, menu)}
                                                                                                    >
                                                                                                        <i className="fa fa-pencil"></i>
                                                                                                    </Link>
                                                                                                    <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                                                        onClick={() => handleDeleteClick(menu._id)}
                                                                                                    >
                                                                                                        <i className="fa fa-trash"></i>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="menu-arrow-box">
                                                                                                <Link to={`/menu/menus/${menu._id}/submenus`}>
                                                                                                    <div className="menu-arrow" onClick={() => dispatch(setMenuAction(menu, locationId))}>
                                                                                                        <i className="fs-32 las la-angle-double-right"></i>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }}
                                                                            </Draggable>
                                                                        );
                                                                    })}
                                                                    {provided.placeholder}
                                                                </tbody>);
                                                        }}
                                                    </Droppable>
                                                </table>
                                            </DragDropContext>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {!!willRearrange &&
                <div className="card-footer text-center save-cancel">
                    {!loading &&
                        <>
                            <button type="button" onClick={() => handleCancelRerrange()} className="btn btn-light mr-3 w-25"> <i className="flaticon-delete-1"></i> Cancel</button>
                            <button type="submit" className="btn btn-primary w-25" onClick={handleSaveRearrange}>Save</button>
                        </>
                    }
                    {!!loading && <Spinner animation="border" variant="primary" />}
                </div>
            }
        </>
    );
};

export default Menus;