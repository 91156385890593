import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import swal from "sweetalert";
import { deleteModifierData, getModifierData, toggleModifierDataAvailable } from "../../services/ModifierService";
import ModifierModal from "../modals/ModifierModal";

const Modifiers = () => {
    const params = useParams();
    const [modifiers, setModifiers] = useState([]);
    const [loading, setLoading] = useState(false);
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [selectedModifer, setSelectedModifer] = useState(null);
    const { selectedModifierList: breadcrumbModifier, onLocationId } = useSelector((state) => state.breadcrumbs);

    const history = useHistory();

    useEffect(() => {
        if (onLocationId != locationId) {
            history.push('/menu');
        }
    }, [locationId]);

    useEffect(() => {
        populateModifiersData();
    }, []);

    const populateModifiersData = () => {
        setLoading(true);
        getModifierData(params.id).then(response => {
            const responseData = response.data.data;
            if (responseData) {
                setModifiers(responseData);
            }
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onOff = (checked, id) => {
        toggleModifierDataAvailable(params.id, id, checked).then((reponse) => {
            const newModifiers = modifiers.map(x => {
                if (x._id == id) {
                    x.isAvailable = checked;
                }
                return x;
            });
            setModifiers([...newModifiers]);
        });
    };

    const handleAddClick = (event) => {
        const selected = {
            modifierId: params.id,
            name: "",
            amount: null
        };
        setSelectedModifer(selected);
    };

    const handleEditClick = (event, modifier) => {
        const selectedModifer = {
            _id: modifier._id,
            modifierId: params.id,
            name: modifier.modifierData.name,
            amount: modifier.modifierData.priceMoney.amount
        };
        setSelectedModifer(selectedModifer);
    };

    const handleCopyClick = (modifier) => {
        const selectedModifer = {
            modifierId: params.id,
            name: modifier.modifierData.name,
            amount: modifier.modifierData.priceMoney.amount
        };
        setSelectedModifer(selectedModifer);
    };

    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the modifier",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteModifierData(params.id, id).then((response) => {
                    const newModifiers = modifiers.filter(x => {
                        return x._id !== id;
                    });
                    setModifiers([...newModifiers]);
                    swal(
                        "Poof! Your modifier is deleted",
                        {
                            icon: "success",
                        }
                    );
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const saveModal = (updatedModifier) => {
        setSelectedModifer(null);
        if (updatedModifier) {
            // updated
            const newModifiers = modifiers.map(x => {
                if (x._id == updatedModifier._id) {
                    x.modifierData.name = updatedModifier.name;
                    x.modifierData.priceMoney.amount = updatedModifier.amount;
                }
                return x;
            });
            setModifiers([...newModifiers]);
        } else {
            // refresh
            populateModifiersData();
        }
    };
    return (
        <>
            <ModifierModal modifierData={selectedModifer} setModifierData={setSelectedModifer} save={saveModal}></ModifierModal>
            <div className="form-head d-flex mb-0 align-items-start">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Modifiers</h2>
                    <ol className="breadcrumb d-none d-md-flex d-lg-flex">
                        <li className="breadcrumb-item">
                            <Link to="/menu">
                                Main
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/menu/lists">
                                Lists
                            </Link>
                        </li>
                        {!!breadcrumbModifier?.modifierListData?.name &&
                            <li className="breadcrumb-item text-capitalize">
                                {breadcrumbModifier?.modifierListData?.name}
                            </li>
                        }
                    </ol>
                </div>
                <Link className="btn mr-2" to="/menu/lists">
                <i className="las la-undo mr-0 mr-lg-2"></i>
                    <span className="d-none d-lg-inline-block">Back</span>
                </Link>
                <Button onClick={handleAddClick}>+ New Modifier</Button>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {loading && !modifiers.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !modifiers.length) &&
                        <div className="card">
                            <div className="card-body " style={{ padding: "1.25rem" }}>
                                <div className="table-responsive no-overflow">
                                    {
                                        !loading && !modifiers.length && <>Nothing Found</>
                                    }
                                    {
                                        !!modifiers.length &&
                                        (<table className="table mb-0 text-black">
                                            <thead>
                                                <tr>
                                                    <th className="align-middle td-sm"></th>
                                                    <th className="align-middle"></th>
                                                    <th className="align-middle text-left td-sm">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody id="orders">
                                                {modifiers.map(row => {
                                                    return (
                                                        <tr key={row._id} className="btn-reveal-trigger">
                                                            <td className="py-2">
                                                                <i className="fs-32 las la-coffee"></i>
                                                            </td>
                                                            <td className="py-2">
                                                                <h4 className="mb-0">{row.modifierData.name}</h4>
                                                            </td>
                                                            <td className="py-2 text-right">

                                                                <div className="d-flex align-items-center justify-content-right">
                                                                    <BootstrapSwitchButton
                                                                        checked={row.isAvailable !== false}
                                                                        onlabel='ON'
                                                                        offlabel='OFF'
                                                                        size="sm"
                                                                        onChange={(checked) => {
                                                                            onOff(checked, row._id);
                                                                        }}
                                                                    />
                                                                    <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                        onClick={(event) => handleEditClick(event, row)}
                                                                    >
                                                                        <i className="fa fa-pencil"></i>
                                                                    </Link>
                                                                    <Link to="#" className="btn btn-secondary shadow btn-xs sharp mr-2"
                                                                        onClick={() => handleCopyClick(row)}
                                                                    >
                                                                        <i className="fa fa-copy"></i>
                                                                    </Link>
                                                                    <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                        onClick={() => handleDeleteClick(row._id)}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>)
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default Modifiers;