import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import { createModifierData, updateModifierData } from '../../services/ModifierService';
import CurrencyInput from 'react-currency-input-field';
import { TextInput } from '../reusable/TextInput';

const ModifierModal = ({ modifierData, setModifierData, save }) => {
    const [nameError, setNameError] = useState("");

    const handleChangeField = (event, key) => {
        if (event?.target) {
            modifierData[key] = event.target.value;
        } else {
            modifierData[key] = event;
        }

        setModifierData({ ...modifierData });
    };

    const handleCheckbox = (checked) => {
        modifierData.addToMenuItem = checked;
        setModifierData({ ...modifierData });
    };

    const handleCancel = () => {
        setNameError("");
        setModifierData(null);
    };

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        setNameError('');
        var error = false;
        if (!modifierData?.name) {
            error = true;
            setNameError('Please fill name');
        }
        if (!error) {
            if (modifierData._id) {
                // editing
                const request = {
                    modifierId: modifierData.modifierId,
                    modifierDataId: modifierData._id,
                    name: modifierData.name,
                    amount: modifierData.amount + '',
                    currency: 'USD',
                    updateOnMenuItem: !!modifierData.addToMenuItem
                };
                updateModifierData(request).then(response => {
                    swal('Good job!', 'Successfully Updated', "success");
                    save(modifierData);
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            } else {
                const request = {
                    modifierId: modifierData.modifierId,
                    modifier_data_name: modifierData.name,
                    amount: modifierData.amount,
                    currency: 'USD',
                    addToMenuItem: !!modifierData.addToMenuItem
                };
                createModifierData(request).then(response => {
                    swal('Good job!', 'Successfully Added', "success");
                    save();
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        }
    };
    return (
        <Modal className="modal fade flex-modal" show={!!modifierData} onHide={handleCancel} >
            {!!modifierData && <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">{modifierData._id ? 'Edit' : 'Add'} Modifier</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Name</label>
                                <div className="name">
                                    <TextInput type="text" className="form-control" autoComplete="off" required="required" value={modifierData?.name} onChange={(event) => { handleChangeField(event, 'name') }} placeholder={"Enter name"} />
                                    <span className="validation-text text-danger">{nameError}</span>
                                </div>
                            </div>
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Surcharge</label>
                                <div className="name">
                                    <CurrencyInput id="input-example"
                                        className="form-control"
                                        prefix="$"
                                        decimalsLimit={2}
                                        placeholder="Enter surcharge"
                                        defaultValue={modifierData?.amount}
                                        onValueChange={(value) => handleChangeField(value, 'amount')}
                                        autoComplete='off'
                                    />
                                </div>
                            </div>
                            <div className="custom-control custom-checkbox mb-3 checkbox-primary check-xl" onClick={() => handleCheckbox(!modifierData.addToMenuItem)}>
                                <input type="checkbox" className="custom-control-input" checked={!!modifierData.addToMenuItem} />
                                <label className="custom-control-label pl-3 pt-1">{modifierData._id ? 'Update' : 'Add'} to menu items?</label>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>}
        </Modal>
    );
};

export default ModifierModal;
