import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { Link, useParams } from "react-router-dom";
import { currencyFormat, humanReadableFormattedDateString, isMobile } from "../../services/UtilsService";
import OrderDetailModal from "../modals/OrderDetailModal";
import ReactPaginate from 'react-paginate';
import { getOrders } from "../../services/DashboardService";
import RequestRefundModal from "../modals/RequestRefundModal";

const OrderManagement = ({ selectionRange }) => {
    const [loading, setLoading] = useState(false);
    const params = useParams();
    const [paginator, setPaginator] = useState({
        current: 0,
        pageCount: 0,
        totalOrders: 0,
        limit: 10,
        showing: {
            from: 0,
            to: 0
        }
    });
    const [salesReports, setSalesReports] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [refundOrder, setRefundOrder] = useState(false);

    useEffect(() => {
        populateOrdersData();
    }, [selectionRange]);

    const populateOrdersData = (page = 0) => {
        setLoading(true);
        getOrders(null, selectionRange?.startDate, selectionRange?.endDate, page, paginator.limit, params?.id).then(response => {
            const responseData = response.data?.data;
            const orders = responseData.store?.orders;
            setSalesReports(orders || []);
            paginator.current = page;
            paginator.pageCount = Math.ceil(responseData.store?.totalOrders / paginator.limit);
            paginator.totalOrders = responseData.store?.totalOrders;
            paginator.showing.from = (paginator.current * paginator.limit) + 1;
            paginator.showing.to = ((paginator.current + 1) * paginator.limit);
            if (paginator.showing.to > paginator.totalOrders) {
                paginator.showing.to = paginator.totalOrders;
            }
            setPaginator(paginator);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }

    const viewOrderDetail = (order) => {
        setSelectedOrder(order);
    };

    const handleRequestRefund = (report) => {
        setRefundOrder(report);
    };

    const handleCancelRefund = () => {
        setRefundOrder(null);
    };

    return (
        <>
            <OrderDetailModal selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder}></OrderDetailModal>
            {!!refundOrder && <RequestRefundModal refundOrder={refundOrder} cancel={handleCancelRefund}></RequestRefundModal>}
            <div className="row">
                <div className="col-lg-12">
                    {loading && !salesReports.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !salesReports.length) &&
                        <div className="table-responsive">
                            {
                                !loading && !salesReports.length && <>Nothing Found</>
                            }
                            {
                                !!salesReports.length &&
                                <div id="order_list" className="dataTables_wrapper no-footer">
                                    <table
                                        className="display mb-4 dataTablesCard dataTable no-footer w-100"
                                    >
                                        <thead>
                                            <tr role="row">
                                                <th>Location</th>
                                                <th>Date</th>
                                                <th>Tax</th>
                                                <th>Tip</th>
                                                <th>Discount</th>
                                                <th>Gross</th>
                                                {/* <th>Due</th> */}
                                                <th>Refund</th>
                                                <th />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {salesReports?.map((report, key) => {
                                                return (
                                                    <tr key={key} role="row" className={key % 2 ? "odd" : "even"}>
                                                        <td><span className="fs-14">{report.location?.name}</span></td>
                                                        <td><label className="date-label">{humanReadableFormattedDateString(report.createdAt)}</label></td>
                                                        <td>{currencyFormat(report.totalTaxMoney?.amount)}</td>
                                                        <td>{currencyFormat(report.totalTipMoney?.amount)}</td>
                                                        <td>{currencyFormat(report.totalDiscountMoney?.amount)}</td>
                                                        <td>{currencyFormat(report.totalMoney?.amount + report.totalTaxMoney?.amount + report.totalTipMoney?.amount - report.totalDiscountMoney?.amount)}</td>
                                                        {/* <td>{currencyFormat(report.netAmountDueMoney?.amount)}</td> */}
                                                        <td>
                                                            {!!report.refundedAmountMoney?.amount ?
                                                                <span className="fs-14">
                                                                    {currencyFormat(report.refundedAmountMoney?.amount)} Refunded
                                                                </span> :
                                                                <span className="btn btn-sm light btn-warning" onClick={() => handleRequestRefund(report)}>
                                                                    Request
                                                                </span>}
                                                        </td>
                                                        <td className="menu-arrow-box">
                                                            <Link to="#" onClick={() => viewOrderDetail(report)}>
                                                                <div className="menu-arrow">
                                                                    <i className="fs-32 las la-eye"></i>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                                        <div className="dataTables_info">
                                            Showing {paginator.showing.from} to {paginator.showing.to}{" "}
                                            of {paginator.totalOrders} entries
                                        </div>
                                        {!!paginator.pageCount && paginator.pageCount > 1 &&
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={(event) => { populateOrdersData(event.selected); }}
                                                pageRangeDisplayed={isMobile() ? 2 : 4}
                                                marginPagesDisplayed={1}
                                                initialPage={paginator.current}
                                                pageCount={paginator.pageCount}
                                                previousLabel="<"
                                                renderOnZeroPageCount={null}
                                                pageClassName="paginate_button"
                                                pageLinkClassName=""
                                                previousClassName=""
                                                previousLinkClassName="btn btn-sm btn-light"
                                                nextClassName=""
                                                nextLinkClassName="btn btn-sm btn-light"
                                                breakClassName=""
                                                breakLinkClassName=""
                                                containerClassName="dataTables_paginate paging_simple_numbers d-flex align-items-center"
                                                activeClassName="current"
                                            />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default OrderManagement;
