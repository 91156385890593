import { SELECT_MENU_ACTION, SELECT_MODIFIERLIST_ACTION, SELECT_NONE_ACTION, SELECT_SUBMENU_ACTION } from "../actions/BreadcrumbActions";

const initialState = {
    onLocationId: null,
    selectedMenu: null,
    selectedSubMenu: null,
    selectedModifierList: null
};

export const BreadcrumbReducer = (state = initialState, actions) => {
    if (actions.type === SELECT_MENU_ACTION) {
        state.selectedMenu = actions.payload;
        state.selectedSubMenu = null;
        state.selectedModifierList = null;
        state.onLocationId = actions.onLocationId;
        return { ...state };
    }
    if (actions.type === SELECT_SUBMENU_ACTION) {
        state.selectedSubMenu = actions.payload;
        state.selectedModifierList = null;
        return { ...state };
    }
    if (actions.type === SELECT_MODIFIERLIST_ACTION) {
        state.selectedMenu = null;
        state.selectedSubMenu = null;
        state.selectedModifierList = actions.payload;
        state.onLocationId = actions.onLocationId;
        return { ...state };
    }
    if(actions.type === SELECT_NONE_ACTION) {
        return {
            onLocationId: null,
            selectedMenu: null,
            selectedSubMenu: null,
            selectedModifierList: null
        };
    }
    return state;
};