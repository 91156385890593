import React, { useState } from 'react'
/// React router dom
import { Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import MenuManagement from './pages/MenuManagement'
import Menus from './pages/Menus'
import SubMenus from './pages/SubMenus'
import Items from './pages/Items'
import Settings from './pages/Settings'
import Dashboard from './pages/Dashboard'
import ModifierLists from './pages/ModifierLists'
import Modifiers from './pages/Modifiers'
import AdminUsers from './pages/AdminUsers'
import UserManagement from './pages/UserManagement'
import UserProfile from './pages/UserProfile'
import RewardsStatus from './pages/RewardsStatus'
import DiscountRequests from './pages/DiscountRequests'
import DiscountCategories from './pages/DiscountCategories'
import RewardsSettings from './pages/RewardsSettings'
import SendNotifications from './pages/Notifications'

const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: "", component: Dashboard },
    { url: "settings", component: Settings },
    { url: "admins", component: AdminUsers },
    { url: "users", component: UserManagement },
    { url: "users/:id", component: UserProfile },
    { url: "discount/requests", component: DiscountRequests },
    { url: "discount/categories", component: DiscountCategories },
    { url: "rewards/old", component: RewardsStatus },
    { url: "rewards", component: RewardsSettings },
    { url: "menu", component: MenuManagement },
    { url: "menu/menus", component: Menus },
    { url: "menu/menus/:id/submenus", component: SubMenus },
    { url: "menu/menus/:id/submenus/:id2/items", component: Items },
    { url: "menu/lists", component: ModifierLists },
    { url: "menu/lists/:id/modifiers", component: Modifiers },
    { url: "notifications", component: SendNotifications },
  ]

  return (
    <>
      <div
        id={`${!pagePath ? 'main-wrapper' : ''}`}
        className={`${!pagePath ? 'show' : 'mh100vh'}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? 'rightside-event' : ''} ${!pagePath ? 'content-body' : ''
            }`}
        >
          <div
            className={`${!pagePath ? 'container-fluid' : ''}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  )
}

export default Markup
