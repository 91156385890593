import { getLocations } from '../../services/LocationService';
import {
    CONFIRMED_GET_LOCATIONS,
    CONFIRMED_SET_LOCATIONID
} from './LocationTypes';


export function setLocationIdAction(locationId) {
    return {
        type: CONFIRMED_SET_LOCATIONID,
        payload: locationId
    };
}

export function getLocationsAction(selectedLocationId = null) {
    return (dispatch) => {
        getLocations().then((response) => {
            const locations = response?.data?.data;
            if (locations) {
                dispatch(confirmedGetLocationsAction(locations));
                if(locations.length) {
                    if(selectedLocationId) {
                        dispatch(setLocationIdAction(selectedLocationId));
                    } else {
                        dispatch(setLocationIdAction(locations[0]._id));
                    }
                }
            } else {
                dispatch(confirmedGetLocationsAction([]));
            }
        }).catch((error) => {
            console.error(error);
            dispatch(confirmedGetLocationsAction([]));
        });
    };
}

export function confirmedGetLocationsAction(locations) {
    return {
        type: CONFIRMED_GET_LOCATIONS,
        payload: locations
    };
}