import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import swal from "sweetalert";
import CurrencyInput from "react-currency-input-field";
import { rechargeUserWallet } from "../../services/UserService";

const RechargeWalletModal = ({ show, onHide, userId, refetchData }) => {
  const [amountError, setAmountError] = useState("");
  const [amount, setAmount] = useState("");


const onSubmitRecharge  = (e) => {
    e.preventDefault();
    if(!amount) {
     setAmountError("Enter rechage amount!")
     return
    };
    rechargeUserWallet(userId, Number(amount)).then((res) => {
    //   console.log(res)
    swal("Good job!", "The recharge was successful", "success")
      refetchData()
      setAmount("")
      onHide();   
    }).catch((error) => {
        swal('Something went wrong!', error.response?.data?.message || 'API failed', "error")
    })

}
  useEffect(() => {
console.log(userId)
  },
  [])
  return (
    <Modal className="modal fade flex-modal" show={show} onHide={onHide}>
      {show && (
        <form onSubmit={onSubmitRecharge}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Recharge Wallet</h4>
            <button
              type="button"
              className="btn close"
              onClick={() => onHide()}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
          <div className="form-group mb-3">
                                <div className="name">
                                    <input type="number" className="form-control" autoComplete="off" required="required" onChange={(e)=> setAmount(e.target.value)} value={amount} placeholder={"Enter Amount"} />
                                    <span className="validation-text text-danger">{amountError}</span>
                                </div>
                            </div>
          </div>
          <div className="modal-footer d-flex text-center">
            <button type="button" className="btn btn-light" onClick={() => onHide()}>
              <i className="flaticon-delete-1"></i> Cancel
            </button>

            <button
              type="submit"
              className="btn btn-primary"
                // onClick={onSubmitRecharge}
            >
              Save
            </button>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default RechargeWalletModal;
