import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { Link, useLocation } from "react-router-dom";
import swal from "sweetalert";
import { isMobile } from "../../../services/UtilsService";
import { getUsers } from "../../../services/UserService";
import ReactPaginate from 'react-paginate';
import useDebounce from "../../../services/Debounce";
import { s3Url } from "../../../services/AWSS3";
import { sendNotificationsAPI } from "../../../services/NotificationsService";

const StepTwo = ({ stepNo, next, prev, formData, setFormData }) => {
    const [errors, setErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [btnLoading, setBtnLoading] = useState(false);
    const [search, setSearch] = useState("");
    const q = new URLSearchParams(useLocation().search);
    const [paginator, setPaginator] = useState({
        current: 0,
        pageCount: 0,
        totalUsers: 0,
        limit: 10,
        showing: {
            from: 0,
            to: 0
        }
    });

    useEffect(() => {
        if (q.get('search') && !search != q.get('search')) {
            setSearch(q.get('search'));
        }
    }, [q.get('search')]);

    useDebounce(() => {
        populateUsersData(q.get('page') && q.get('page') > 0 ? q.get('page') : 0, search);
    }, [search], 300);

    const populateUsersData = (page = 0) => {
        setLoading(true);
        getUsers(search, page, paginator.limit).then(response => {
            const responseData = response.data?.data;
            setUsers(responseData.users || []);
            paginator.current = parseInt(page);
            paginator.pageCount = Math.ceil(responseData.totalUsers / paginator.limit);
            paginator.totalUsers = responseData.totalUsers;
            paginator.showing.from = (paginator.current * paginator.limit) + 1;
            paginator.showing.to = ((paginator.current + 1) * paginator.limit);
            if (paginator.showing.to > paginator.totalUsers) {
                paginator.showing.to = paginator.totalUsers;
            }
            setPaginator(paginator);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const selectUsers = (id = null) => {
        if (id) {
            formData.allUsers = false;
            formData.users[id] = !!!formData.users[id];
        } else {
            formData.allUsers = !!!formData.allUsers;
        }
        setFormData({ ...formData });
    };

    const handleCancel = () => {
        setFormData({ users: {} });
        prev();
    };

    const handleSaveFormSubmit = () => {
        console.log('handleSaveFormSubmit formData', formData);
        const request = {
            title: formData.title,
            body: formData.body
        };
        if (formData.image) {
            request.image = formData.image;
        }
        if (!!formData.allUsers) {

        } else {
            const users = Object.keys(formData.users).filter(id => !!formData.users[id]);
            if (users.length) {
                request.users = users;
            } else {
                const newErrors = {};
                newErrors["selectUsers"] = "Please select atleast one user";
                setErrors({ ...newErrors });
                return;
            }
        }
        setBtnLoading(true);
        sendNotificationsAPI(request).then(response => {
            swal("Good job!", "Successfully Sent", "success");
            setBtnLoading(false);
        }).catch((error) => {
            setBtnLoading(false);
            console.error(error);
        });
    };

    return (
        <Fragment>
            <div className="form-head d-flex my-2 mb-lg-4 align-items-center">
                <div className="mr-auto">
                    <h3 className="form-title mb-0">Users</h3>
                </div>
                <div className={isMobile() ? 'w-75' : `w-25`}>
                    <input type="text" className="form-control w-100" placeholder="Search users by name/email" value={search} onChange={handleSearch} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {loading && !users.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !users.length) &&
                        <div className="table-responsive">
                            {
                                !loading && !users.length && <>Nothing Found</>
                            }
                            {
                                !!users.length &&
                                <div className="dataTables_wrapper no-footer">
                                    <table className="table mb-0 table-striped respon-table-data">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Email</th>
                                                <th>Phone</th>
                                                <th>Status</th>
                                                <th className="text-right">
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input"
                                                        id="custom-checkbox-id-all"
                                                        onChange={() => selectUsers()}
                                                        required=""
                                                        checked={!!formData.allUsers}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor="custom-checkbox-id-all"
                                                    ></label>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody id="customers">
                                            {users.map((row) => {
                                                return (
                                                    <tr className="btn-reveal-trigger" key={row._id}>
                                                        <td className="py-3">
                                                            <Link to={`/users/${row._id}`}>
                                                                <div className="media d-flex align-items-center">
                                                                    <div className="avatar avatar-xl mr-2">
                                                                        <div className="position-relative">
                                                                            {!!row.profilePic ?
                                                                                <img
                                                                                    className="rounded-circle img-fluid"
                                                                                    src={`${s3Url}/${row.profilePic}`}
                                                                                    width="30"
                                                                                    alt=""
                                                                                /> : <div className="user-icon border rounded-circle"><i className="fs-24 las la-user"></i></div>
                                                                            }
                                                                            {!!row.userType?.status?.image &&
                                                                                <img
                                                                                    className="rounded-circle img-fluid status-img"
                                                                                    src={`${s3Url}/${row.userType?.status?.image}`}
                                                                                    width="30"
                                                                                    alt={row.userType?.status?.title}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <h5 className="mb-0 fs--1">
                                                                            {row.firstName} {row.lastName}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </td>
                                                        <td className="py-2">
                                                            <a href={"mailto:" + row.email}>
                                                                {row.email}
                                                            </a>
                                                        </td>
                                                        <td>{row.countryCode} {row.phoneNumber}</td>
                                                        <td>{!!row.isVerified ? <span className="badge text-success">Verified</span> : <span className="badge text-danger">Verification Pending</span>}</td>
                                                        <td className="text-right">
                                                            <div className="custom-control custom-checkbox checkbox-primary check-lg">
                                                                <input
                                                                    type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={"custom-checkbox-id-" + row._id}
                                                                    onChange={() => selectUsers(row._id)}
                                                                    required=""
                                                                    checked={!!formData.users[row._id] || !!formData.allUsers}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={"custom-checkbox-id-" + row._id}
                                                                ></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                                        <div className="dataTables_info">
                                            Showing {paginator.showing.from} to {paginator.showing.to}{" "}
                                            of {paginator.totalUsers} entries
                                        </div>
                                        {!!paginator.pageCount && paginator.pageCount > 1 &&
                                            <ReactPaginate
                                                breakLabel="..."
                                                nextLabel=">"
                                                onPageChange={(event) => { populateUsersData(event.selected); }}
                                                pageRangeDisplayed={isMobile() ? 2 : 4}
                                                marginPagesDisplayed={1}
                                                initialPage={paginator.current}
                                                pageCount={paginator.pageCount}
                                                previousLabel="<"
                                                renderOnZeroPageCount={null}
                                                pageClassName="paginate_button"
                                                pageLinkClassName=""
                                                previousClassName=""
                                                previousLinkClassName="btn btn-sm btn-light"
                                                nextClassName=""
                                                nextLinkClassName="btn btn-sm btn-light"
                                                breakClassName=""
                                                breakLinkClassName=""
                                                containerClassName="dataTables_paginate paging_simple_numbers d-flex align-items-center"
                                                activeClassName="current"
                                            />
                                        }
                                    </div>
                                    <div className="validation-text text-danger fs-18 text-center my-3">
                                        {errors?.selectUsers}
                                    </div>
                                    <div className="d-flex align-items-center justify-content-center mt-2">
                                        {!!btnLoading && <Spinner animation="border" variant="primary" />}
                                        {!btnLoading &&
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-light mr-2"
                                                    onClick={handleCancel}
                                                >
                                                    {" "}
                                                    <i className="flaticon-delete-1"></i> Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleSaveFormSubmit}
                                                >
                                                    Send to {!!formData.allUsers ? "all" : Object.keys(formData.users).filter(id => !!formData.users[id]).length} users
                                                </button>
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default StepTwo;