import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import Select from "react-select";
import { selectLocationOptions } from "../../store/selectors/LocationSelectors";
import { addStatus, editStatus } from "../../services/RewardsService";
import CurrencyInput from "react-currency-input-field";
import { getMenus, getSubMenus } from "../../services/MenuService";
import { getItems } from "../../services/ItemService";
import noImg from "../../img/no-image-available.png";
import { randomStr } from "../../services/UtilsService";
import { ReactS3Client, s3Url } from "../../services/AWSS3";
import { Spinner } from "react-bootstrap";
import { addDiscountRequestCategories, editDiscountRequestCategories } from "../../services/DiscountRequestsService";

const NewDiscountCategory = ({
  selectedDiscountCategory,
  setSelectedDiscountCategory,
  save,
  cancel,
}) => {
  // const [selectedOption, setSelectedOption] = useState(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const locationOptions = useSelector(selectLocationOptions);
  const [menus, setMenus] = useState([]);
  const [subMenus, setSubMenus] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    setFormData({ ...selectedDiscountCategory });
    populateSelectedItem();
  }, [selectedDiscountCategory]);

  useEffect(() => {
    if (selectedItem?.locationId) {
      populateMenuDropDown();
    } else {
      setMenus([]);
    }

    if (selectedItem?.menuId) {
      populateSubMenusDropDown();
    } else {
      setSubMenus([]);
    }

    if (selectedItem?.menuId && selectedItem?.subMenuId) {
      populateItemsDropdown();
    } else {
      setItems([]);
    }
  }, [selectedItem]);

  const populateMenuDropDown = () => {
    getMenus(selectedItem.locationId)
      .then((response) => {
        const responseData = response.data.data;
        if (responseData.menu) {
          setMenus(responseData.menu);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const populateSubMenusDropDown = () => {
    getSubMenus(selectedItem.menuId)
      .then((response) => {
        const responseData = response.data.data;
        if (responseData.subMenu) {
          setSubMenus(responseData.subMenu);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const populateItemsDropdown = () => {
    setLoading(true);
    getItems(selectedItem.menuId, selectedItem.subMenuId)
      .then((response) => {
        const responseData = response.data.data;
        if (responseData.items) {
          setItems(Object.values(responseData.items));
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const populateSelectedItem = () => {
    if (selectedDiscountCategory?._id) {
      selectedItem.locationId = selectedDiscountCategory.locationId;
      selectedItem.menuId = selectedDiscountCategory.menuId;
      selectedItem.subMenuId = selectedDiscountCategory.submenuId;
      selectedItem.itemId = selectedDiscountCategory.item?._id;
      setSelectedItem({ ...selectedItem });
    }
  };

  const handleChangeDropdown = (event, key) => {
    console.log("handleChangeDropdown", event);
    if (key == "locationId") {
      selectedItem[key] = event.value;
    } else {
      selectedItem[key] = event.target.value;
    }
    setSelectedItem({ ...selectedItem });
    if (formData) {
      formData.saving = true;
      setFormData({ ...formData });
    }
  };

  const handleSaveFormSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
    if (!formData?.title) {
      newErrors["title"] = "Please fill title";
    }
    if (!formData?.percentage) {
      newErrors["amount"] = "Please fill discount percentage";
    }
    setErrors({ ...newErrors });
    console.log("formData", formData);
    console.log("newErrors", newErrors);
    if (!newErrors || !Object.values(newErrors).length) {
      setLoading(true);
      const request = {
        title: formData.title,
      };
      request.percentage = formData.percentage;
      if (!!selectedImage?.source) {
        // upload image
        const name = await uploadImageToS3();
        if (!name) {
          setLoading(false);
          return;
        }
      }
      request.icon = formData.icon;
      if (formData._id) {
        // editing
        request.categoryId = formData._id;
        editDiscountRequestCategories(request)
          .then((response) => {
            swal("Good job!", "Successfully Updated", "success");
            setFormData(null);
            setLoading(false);
            save();
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      } else {
        addDiscountRequestCategories(request)
          .then((response) => {
            swal("Good job!", "Successfully Created", "success");
            setFormData(null);
            setLoading(false);
            save();
          })
          .catch((error) => {
            setLoading(false);
            console.error(error);
          });
      }
    }
  };

  const handleChangeField = (event, key, obj = null) => {
    let value = event && event.target ? event.target.value : event;
    if (obj) {
      obj[key] = value;
    } else {
      formData[key] = value;
    }
    formData.saving = true;
    setFormData({ ...formData });
    setErrors({});
  };

  const handleCancel = () => {
    console.log("handleCancel");
    setErrors({});
    setFormData(null);
    cancel();
  };

  const handleImageUpload = (event) => {
    setSelectedImage({
      source: event.target.files[0],
      fileURL: URL.createObjectURL(event.target.files[0]),
    });
    formData.saving = true;
    setFormData({ ...formData });
  };

  const uploadImageToS3 = async () => {
    const newErrors = {};
    if (!selectedImage?.source) {
      newErrors["uploadImage"] = "Please upload an image from your computer";
      setErrors({ ...newErrors });
      return;
    }
    let newFileName = randomStr(12) + "_" + selectedImage?.source?.name;
    try {
      const data = await ReactS3Client.uploadFile(
        selectedImage?.source,
        newFileName
      );
      setSelectedImage(undefined);
      formData.icon = data.key;
      setFormData({ ...formData });
      return data.key;
    } catch (err) {
      console.error(err);
      newErrors["uploadImage"] = "Something went wrong with this image";
      setErrors({ ...newErrors });
      return;
    }
  };

  return (
    <form className="position-relative">
      {loading && (
        <div className="loading-overlay pb-5">
          <Spinner className="mb-5" animation="border" variant="primary" />
        </div>
      )}
      <div>
        <i className="flaticon-cancel-12 close"></i>
        <div className="add-contact-box">
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">Upload Icon</label>
              <div className="name">
                <input
                  type="file"
                  autoComplete="off"
                  onChange={handleImageUpload}
                />
                <div className="validation-text text-danger">
                  {errors?.uploadImage}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <div className="image-wrapper">
                <img
                  src={
                    selectedImage?.fileURL ||
                    (formData?.icon ? s3Url + "/" + formData?.icon : noImg)
                  }
                  width={"100%"}
                />
              </div>
            </div>
          </div>
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">Title</label>
              <div className="name">
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  required="required"
                  value={formData?.title}
                  onChange={(event) => handleChangeField(event, "title")}
                  placeholder="Enter Title"
                />
                <span className="validation-text text-danger">
                  {errors?.title}
                </span>
              </div>
            </div>
          </div>
          <div className="add-contact-content">
            <div className="form-group mb-3">
              <label className="text-black font-w500">Discount Percentage</label>
              <div className="name">
                <div className="d-flex align-items-center justify-content-space">
                  <input
                    type="number"
                    className="form-control w-100"
                    autoComplete="off"
                    value={formData?.percentage}
                    onChange={(event) => handleChangeField(event, "percentage")}
                    placeholder="Enter Discount in %age"
                  />
                </div>
                <span className="validation-text text-danger">
                  {errors?.percentage}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!formData?.saving && (
        <div className="card-footer text-center save-cancel">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn btn-light mr-3 w-25"
          >
            {" "}
            <i className="flaticon-delete-1"></i> Cancel
          </button>
          <button
            type="submit"
            className="btn btn-primary w-25"
            onClick={handleSaveFormSubmit}
          >
            Save
          </button>
        </div>
      )}
    </form>
  );
};

export default NewDiscountCategory;
