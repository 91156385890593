import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Spinner } from 'react-bootstrap';
import swal from "sweetalert";
import { useSelector } from 'react-redux';
import { addAdminUser, editAdminUser } from '../../services/AdminUsersService';
import Select from 'react-select';
import { selectLocationOptions } from '../../store/selectors/LocationSelectors';
import { TextInput } from '../reusable/TextInput';

const AdminModal = ({ selectedAdmin, setSelectedAdmin, save, cancel }) => {
    const locationOptions = useSelector(selectLocationOptions);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState(null);
    const [removing, setRemoving] = useState(false);

    useEffect(() => {
        setFormData({ ...selectedAdmin });
        populateSelectedLocations();
    }, [selectedAdmin])

    const populateSelectedLocations = () => {

        selectedAdmin?.locations?.map(loc => {
            selectedOptions.push({
                label: loc.name,
                value: loc._id
            });
        });
    };

    const handleCheckbox = (event) => {
        setRemoving(true);
        if (selectedOptions.length === locationOptions.length) {
            // checked
            setSelectedOptions([]);
        } else {
            const newSelectedOptions = [];
            locationOptions.map(option => {
                newSelectedOptions.push(option);
            });
            setSelectedOptions([...newSelectedOptions]);
            
        }
        setTimeout(() => {
            setRemoving(false);
        }, 100);
    };

    const changeLocation = (option, key) => {
        if (option.value) {
            if (selectedOptions.length == key) {
                selectedOptions.push(option);
            } else {
                selectedOptions[key] = option;
            }
            setSelectedOptions([...selectedOptions]);
        }
    };

    const removeLocation = (value) => {
        setRemoving(true);
        setSelectedOptions([...selectedOptions.filter((x) => x.value != value)]);
        setTimeout(() => {
            setRemoving(false);
        }, 100);
    };

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        const newErrors = {};
        if (!formData?.name) {
            newErrors['name'] = "Please fill name";
        }
        if (!formData?.email) {
            newErrors['email'] = "Please fill email";
        }
        if (!formData._id || !!formData.changePassword) {
            if (!formData?.password) {
                newErrors['password'] = "Please fill password";
            }
            if (formData?.password?.length < 6) {
                newErrors['password'] = "Password must be atleast 6 characters long";
            }
            if (formData?.password !== formData?.confirmPassword) {
                newErrors['confirmPassword'] = "Confirm password doesn't match with new password";
            }
        }
        setErrors({ ...newErrors });
        console.log(formData);
        if (!newErrors || !Object.values(newErrors).length) {
            const request = {
                name: formData.name,
                locations: selectedOptions.map(opt => opt.value)
            };
            if (formData._id) {
                // editing
                request.adminId = formData._id;
                if (formData.email != selectedAdmin.email) {
                    request.email = formData.email;
                }
                if (!!formData.changePassword) {
                    request.password = formData.password;
                }
                editAdminUser(request).then(response => {
                    swal('Good job!', 'Successfully Updated', "success");
                    setSelectedOptions([]);
                    setFormData(null);
                    save();
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            } else {
                request.email = formData.email;
                request.password = formData.password;
                addAdminUser(request).then(response => {
                    swal('Good job!', 'Successfully Created', "success");
                    setSelectedOptions([]);
                    setFormData(null);
                    save();
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        }

    };

    const handleChangeField = (event, key, obj = null) => {
        if (obj) {
            obj[key] = event.target.value;
        } else {
            formData[key] = event.target.value;
        }
        console.log(formData);
        setFormData({ ...formData });
        setErrors({});
    };

    const handleCancel = () => {
        console.log('handleCancel');
        setErrors({});
        setFormData(null);
        setSelectedOptions([]);
        cancel();
    };
    return (
        <Modal className="modal fade flex-modall" show={!!selectedAdmin} onHide={handleCancel} >
            <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">{!!formData?._id ? 'Edit' : 'Add'} {formData?.label || 'Admin'}</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Name</label>
                                <div className="name">
                                    <TextInput type="text" className="form-control" autoComplete="off" required="required" value={formData?.name} onChange={(event) => handleChangeField(event, 'name')} placeholder="Enter Name" />
                                    <span className="validation-text text-danger">{errors?.name}</span>
                                </div>
                            </div>
                        </div>
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Email</label>
                                <div className="name">
                                    <TextInput type="email" className="form-control" autoComplete="off" required="required" value={formData?.email} onChange={(event) => handleChangeField(event, 'email')} placeholder="Enter Email" />
                                    <span className="validation-text text-danger">{errors?.email}</span>
                                </div>
                            </div>
                        </div>
                        {!!formData?._id && <Button className="btn btn-sm mb-3" onClick={() => setFormData({ ...formData, changePassword: !formData.changePassword })}>{!formData.changePassword ? 'Change' : 'Do not change'} password</Button>}
                        {(!formData?._id || !!formData.changePassword) &&
                            <>
                                <div className="add-contact-content">
                                    <div className="form-group mb-3">
                                        <label className="text-black font-w500">Password</label>
                                        <div className="name">
                                            <input type="password" className="form-control" autoComplete="off" required="required" value={formData?.password} onChange={(event) => handleChangeField(event, 'password')} placeholder="Enter Password" />
                                            <span className="validation-text text-danger">{errors?.password}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="add-contact-content">
                                    <div className="form-group mb-3">
                                        <label className="text-black font-w500">Confirm Password</label>
                                        <div className="name">
                                            <input type="password" className="form-control" autoComplete="off" required="required" value={formData?.confirmPassword} onChange={(event) => handleChangeField(event, 'confirmPassword')} placeholder="Enter Confirm Password" />
                                            <span className="validation-text text-danger">{errors?.confirmPassword}</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <div className="d-flex align-items-center justify-content-between mb-2">
                                    <label className="text-black font-w500 mb-0">Add Locations</label>
                                    <div className="custom-control custom-checkbox checkbox-primary border" onClick={handleCheckbox}>
                                        <input type="checkbox" className="custom-control-input" value="all" checked={selectedOptions.length === locationOptions.length} />
                                        <label className="custom-control-label pl-1 text-capitalize">{selectedOptions.length !== locationOptions.length ? 'Select' : 'Remove'} All</label>
                                    </div>
                                </div>
                                <div className="name">
                                    <Select
                                        className="location-select"
                                        onChange={(option) => changeLocation(option, selectedOptions.length)}
                                        options={locationOptions.filter(opt => {
                                            return !selectedOptions.map(x => x.value).includes(opt.value);
                                        })}
                                        value={null}
                                        placeholder="Add locations"
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                text: 'white',
                                                primary25: '#ffeae6',
                                                primary: '#FF3333',
                                            },
                                        })}
                                    />
                                </div>
                            </div>
                            {!!removing && <Spinner animation="border" variant="primary" />}
                            {!removing && selectedOptions.map((selectedOption, key) => {
                                return (
                                    <div className="form-group mb-3 d-flex align-items-center justify-content-between" key={key}>
                                        <div className="name">
                                            <Select
                                                className="location-select"
                                                defaultValue={selectedOption}
                                                onChange={(option) => changeLocation(option, key)}
                                                options={locationOptions.filter(opt => {
                                                    return !selectedOptions.map(x => x.value).includes(opt.value);
                                                })}
                                                placeholder="Select location"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: 0,
                                                    colors: {
                                                        ...theme.colors,
                                                        text: 'white',
                                                        primary25: '#ffeae6',
                                                        primary: '#FF3333',
                                                    },
                                                })}
                                            />
                                        </div>
                                        <Button className='btn btn-xs' onClick={(event) => removeLocation(selectedOption.value)}>Remove</Button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>
        </Modal>
    );
};

export default AdminModal;
