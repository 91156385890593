import axiosInstance from './AxiosInstance';

export function getModifiers(locationId, itemId = null) {
    if(itemId) {
        return axiosInstance.get(`/admin/modifier?locationId=${locationId}&itemId=${itemId}`);    
    }
    return axiosInstance.get(`/admin/modifier?locationId=${locationId}`);
}

export function createModifier(modifierListData) {
    return axiosInstance.post(`/admin/modifier`, modifierListData);
}

export function updateModifier(modifierListData) {
    return axiosInstance.put(`admin/modifier`, modifierListData);
}

export function deleteModifier(modifierId) {
    return axiosInstance.delete(`admin/modifier?modifierId=${modifierId}`);
}

export function toggleModifierAvailable(modifierListId, isAvailable) {
    const request = {
        modifierListId: modifierListId,
        isAvailable: isAvailable
    };
    return axiosInstance.put(`admin/isAvailable`, request);
}

export function getModifierData(modifierId = null) {
    return axiosInstance.get(`/admin/modifierData?modifierId=${modifierId}`);    
}

export function createModifierData(modifierData) {
    return axiosInstance.post(`admin/modifierData`, modifierData);
}

export function updateModifierData(modifierData) {
    return axiosInstance.put(`admin/modifierData`, modifierData);
}

export function deleteModifierData(modifierId, modifierDataId) {
    return axiosInstance.delete(`admin/modifierData?modifierId=${modifierId}&modifierDataId=${modifierDataId}`);
}

export function toggleModifierDataAvailable(modifierListId, modifierDataId, isAvailable) {
    const request = {
        modifierListId: modifierListId,
        modifierDataId: modifierDataId,
        isAvailable: isAvailable
    };
    return axiosInstance.put(`admin/isAvailable`, request);
}