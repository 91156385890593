import axiosInstance from './AxiosInstance';

export function getAdminUsers() {
    return axiosInstance.get(`/admin/auth/subAdmin`);
}

export function addAdminUser(adminUserData) {
    return axiosInstance.post(`/admin/auth/subAdmin`, adminUserData);
}

export function editAdminUser(adminUserData) {
    return axiosInstance.put(`/admin/auth/subAdmin`, adminUserData);
}

export function toggleAdminUser(adminId, enable) {
    const request = { adminId: adminId, enable: !!enable };
    return axiosInstance.put(`/admin/auth/toggleAdmin`, request);
}

export function deleteAdminUser(adminId) {
    return axiosInstance.delete(`/admin/auth/subAdmin?adminId=${adminId}`);
}