import axiosInstance from './AxiosInstance';

export function getItems(menuId, subMenuId) {
    return axiosInstance.get(`/admin/items?menuId=${menuId}&submenuId=${subMenuId}`);
}

export function getItemDetail(itemId) {
    return axiosInstance.get(`/admin/item?itemId=${itemId}`);
}

export function createItem(itemData) {
    return axiosInstance.post(`/admin/items`, itemData);
}

export function updateItem(item) {
    return axiosInstance.put(`admin/item`, item);
}

export function itemsOrder(items) {
    const request = {
        items: items
    };
    return axiosInstance.put(`admin/itemsOrder`, request);
}

export function deleteItem(itemId) {
    return axiosInstance.delete(`admin/items?itemId=${itemId}`);
}

export function addItemModifierList(modifierListsData) {
    return axiosInstance.post(`/admin/itemModifierList`, modifierListsData);
}

export function deleteItemModifierList(itemId, modifierListId) {
    return axiosInstance.delete(`admin/itemModifierList?itemId=${itemId}&modifierId=${modifierListId}`);
}

export function updateItemModifierData(modifierData) {
    return axiosInstance.put(`/admin/itemModifierList`, modifierData);
}

export function getVariations(itemId) {
    return axiosInstance.get(`/admin/itemVariations?itemId=${itemId}`);
}

export function createItemVariation(variationData) {
    return axiosInstance.post(`/admin/itemVariations`, variationData);
}

export function updateItemVariation(variationData) {
    return axiosInstance.put(`admin/itemVariations`, variationData);
}

export function deleteItemVariation(itemId, variationId) {
    return axiosInstance.delete(`admin/itemVariations?itemId=${itemId}&variationId=${variationId}`);
}

export function toggleItemAvailable(itemId, isAvailable) {
    const request = {
        itemId: itemId,
        isAvailable: isAvailable
    };
    return axiosInstance.put(`admin/isAvailable`, request);
}