import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from "sweetalert";
import { useSelector } from 'react-redux';
import { createMenu, updateMenu } from '../../services/MenuService';
import { createModifier, updateModifier } from '../../services/ModifierService';
import { TextInput } from '../reusable/TextInput';

const MenuModal = ({ selectedMenu, setSelectedMenu, save, cancel }) => {
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [nameError, setNameError] = useState("");

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        var error = false;
        if (!selectedMenu?.name) {
            error = true;
            setNameError('Please fill name');
        }
        if (!error) {
            if (selectedMenu.label == 'List') { 
                if (selectedMenu._id) {
                    // editing
                    const request = {
                        modifierId: selectedMenu._id,
                        name: selectedMenu?.name
                    };

                    updateModifier(request).then((response) => {
                        swal('Good job!', 'Successfully Updated', "success");
                        save(selectedMenu);
                    }).catch(error => {
                        console.error(error);
                        swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                    });
                } else {
                    const request = {
                        locationId: locationId,
                        name: selectedMenu?.name
                    };

                    createModifier(request).then((response) => {
                        swal('Good job!', 'Successfully Added', "success");
                        save();
                    }).catch(error => {
                        console.error(error);
                        swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                    });
                }
            } else {
                if (selectedMenu._id) {
                    // editing
                    const request = {
                        menuId: selectedMenu._id,
                        name: selectedMenu?.name
                    };

                    updateMenu(request).then((response) => {
                        swal('Good job!', 'Successfully Updated', "success");
                        save(selectedMenu);
                    }).catch(error => {
                        console.error(error);
                        swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                    });
                } else {
                    const newMenu = {
                        locationId: locationId,
                        name: selectedMenu?.name
                    };

                    createMenu(newMenu).then((response) => {
                        swal('Good job!', 'Successfully Added', "success");
                        save();
                    }).catch(error => {
                        console.error(error);
                        swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                    });
                }
            }
        }

    };

    const handleChangeName = (event) => {
        selectedMenu.name = event.target.value;
        setNameError("");
        setSelectedMenu({ ...selectedMenu });
    };

    const handleCancel = () => {
        setNameError("");
        cancel();
    };
    return (
        <Modal className="modal fade flex-modal" show={!!selectedMenu} onHide={handleCancel} >
            <form >
                <div className="modal-header">
                    <h4 className="modal-title fs-20">{!!selectedMenu?._id ? 'Edit' : 'Add'} {selectedMenu?.label || 'Menu'}</h4>
                    <button type="button" className="btn close" onClick={() => handleCancel()}>
                        <span>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <i className="flaticon-cancel-12 close"></i>
                    <div className="add-contact-box">
                        <div className="add-contact-content">
                            <div className="form-group mb-3">
                                <label className="text-black font-w500">Name</label>
                                <div className="name">
                                    <TextInput type="text" className="form-control" autoComplete="off" required="required" value={selectedMenu?.name} onChange={handleChangeName} placeholder="Enter Name" />
                                    <span className="validation-text text-danger">{nameError}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer d-flex text-center">
                    <button type="button" onClick={() => handleCancel()} className="btn btn-light"> <i className="flaticon-delete-1"></i> Cancel</button>
                    <button type="submit" className="btn btn-primary" onClick={handleSaveFormSubmit}>Save</button>
                </div>
            </form>
        </Modal>
    );
};

export default MenuModal;
