import React, { Fragment, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { Link } from "react-router-dom";
import swal from "sweetalert";
import { humanReadableFormattedDateString } from "../../services/UtilsService";
import { s3Url } from "../../services/AWSS3";
import { acceptRejectDiscountRequests, getDiscountRequests } from "../../services/DiscountRequestsService";
import { SRLWrapper } from "simple-react-lightbox";
import ChangeDiscountCategoryModal from "../modals/ChangeDiscountCategoryModal";

const DiscountRequests = () => {
    const [statuses, setStatuses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDiscountCategory, setSelectedDiscountCategory] = useState(null);

    useEffect(() => {
        populateDiscountRequestsData();
    }, []);

    const populateDiscountRequestsData = () => {
        setLoading(true);
        getDiscountRequests().then(response => {
            const responseData = response.data;
            setStatuses(responseData.data);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const handleAcceptRejectClick = (id, accept = true) => {
        swal({
            title: accept ? "Accepting..." : "Rejecting...",
            text: "Once " + (accept ? 'accepted' : 'rejected') + ", you will not be able to change the request",
            content: accept ? undefined : "input",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (!accept && willDelete !== null && !willDelete) {
                swal({ icon: "error", text: "Please provide reason for rejection" });
                return;
            }
            if (willDelete) {
                // rejecting with reason only
                acceptRejectDiscountRequests(id, accept ? 'accept' : 'reject', willDelete).then(response => {
                    const newStatuses = statuses.filter(row => row._id !== id);
                    setStatuses([...newStatuses]);
                }).catch(error => {
                    console.error(error);
                });
            }
        });
    };

    const options = {
        settings: {
            overlayColor: "#000000",
        },
    };

    const handleChangeDiscountCategory = (row) => {
        setSelectedDiscountCategory({ ...row.user.discountCategory, userId: row.user._id });
    };

    const handleSaveDiscountCategory = () => {
        setSelectedDiscountCategory(null);
        populateDiscountRequestsData();
    };
    const handleCancelDiscountCategory = () => {
        setSelectedDiscountCategory(null);
    };

    return (
        <Fragment>
            <ChangeDiscountCategoryModal selectedDiscountCategory={selectedDiscountCategory} setSelectedDiscountCategory={setSelectedDiscountCategory} save={handleSaveDiscountCategory} cancel={handleCancelDiscountCategory}></ChangeDiscountCategoryModal>
            <div className="form-head d-flex mb-2 mb-lg-4 align-items-center">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Discount Requests</h2>
                    <p className="mb-0 d-none d-md-block d-lg-block">Manage discount requests of users!</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {loading && !statuses.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !statuses.length) &&
                        <div className="card">
                            <div className="card-body">

                                <div className="table-responsive">
                                    {
                                        !loading && !statuses.length && <>Nothing Found</>
                                    }
                                    {
                                        !!statuses.length &&
                                        <table className="table mb-0 table-striped respon-table-data">
                                            <thead>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>Email</th>
                                                    <th>Current Category</th>
                                                    <th>Photos</th>
                                                    <th>Requested at</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody id="customers">
                                                {statuses.map((row) => {
                                                    return (
                                                        <tr className="btn-reveal-trigger" key={row._id}>
                                                            <td className="py-3">
                                                                <Link to={`/users/${row.user._id}`}>
                                                                    <div className="media d-flex align-items-center">
                                                                        <div className="avatar avatar-xl mr-2">
                                                                            <div className="position-relative">
                                                                                {!!row.user.profilePic ?
                                                                                    <img
                                                                                        className="rounded-circle img-fluid"
                                                                                        src={`${s3Url}/${row.user.profilePic}`}
                                                                                        width="30"
                                                                                        alt=""
                                                                                    /> : <div className="user-icon border rounded-circle"><i className="fs-24 las la-user"></i></div>
                                                                                }
                                                                                {!!row.user.userType?.status?.image &&
                                                                                    <img
                                                                                        className="rounded-circle img-fluid status-img"
                                                                                        src={`${s3Url}/${row.user.userType?.status?.image}`}
                                                                                        width="30"
                                                                                        alt={row.user.userType?.status?.title}
                                                                                    />
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <h5 className="mb-0 fs--1">
                                                                                {row.user.firstName} {row.user.lastName}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </td>
                                                            <td className="py-2">
                                                                <a href={"mailto:" + row.user.email}>
                                                                    {row.user.email}
                                                                </a>
                                                            </td>
                                                            <td className="py-2 text-capitalize">
                                                                {row.user.discountCategory?.categoryId?.title}
                                                            </td>
                                                            <td className="py-2">
                                                                <div className="media d-flex align-items-center">
                                                                    <SRLWrapper options={options}>
                                                                        <div className="avatar avatar-xl mr-2">
                                                                            <div className="position-relative">
                                                                                {!!row.user.discountCategory.discountRequestPhotos ?
                                                                                    row.user.discountCategory.discountRequestPhotos.map(pic => {
                                                                                        return (
                                                                                            <a href={`${s3Url}/${pic}`} className="mr-1 border">
                                                                                                <img
                                                                                                    className="img-fluid"
                                                                                                    src={`${s3Url}/${pic}`}
                                                                                                    width="30"
                                                                                                    height="30"
                                                                                                    alt=""
                                                                                                />
                                                                                            </a>
                                                                                        );
                                                                                    }) : <div>No photos attached</div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </SRLWrapper>
                                                                </div>
                                                            </td>
                                                            <td className="py-2">
                                                                <label className="date-label">
                                                                    {humanReadableFormattedDateString(row.createdAt)}
                                                                </label>
                                                            </td>
                                                            <td className="py-2 text-right">
                                                                <div className="d-flex align-items-center justify-content-right">
                                                                    <Link to="#" className="mr-2"
                                                                        onClick={() => handleChangeDiscountCategory(row)}
                                                                    >
                                                                        <i className="ti-close fs-28 text-success las la-check-circle"></i>
                                                                    </Link>
                                                                    <Link to="#" className="mr-2"
                                                                        onClick={() => {
                                                                            handleAcceptRejectClick(row._id, false);
                                                                        }}
                                                                    >
                                                                        <i className="ti-close fs-28 text-danger las la-times-circle"></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    );
};

export default DiscountRequests;
