import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import MenuModal from "../modals/MenuModal";
import swal from "sweetalert";
import { deleteModifier, getModifiers, toggleModifierAvailable } from "../../services/ModifierService";
import { setModifierListAction } from "../../store/actions/BreadcrumbActions";

const ModifierLists = () => {
    const dispatch = useDispatch();
    const [modifierLists, setModifierLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [selectedModifierList, setSelectedModifierList] = useState(null);

    useEffect(() => {
        populateModifiersData();
    }, [locationId]);

    const populateModifiersData = () => {
        setLoading(true);
        getModifiers(locationId).then(response => {
            const responseData = response.data.data;
            if (responseData) {
                setModifierLists(responseData.modifiers);
            }
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onOff = (checked, id) => {
        toggleModifierAvailable(id, checked).then((reponse) => {
            const newModifierLists = modifierLists.map(x => {
                if (x._id == id) {
                    x.isAvailable = checked;
                }
                return x;
            });
            setModifierLists([...newModifierLists]);
        });
    };

    const handleEditClick = (event, modifierList) => {
        const selectedModifierList = {
            _id: modifierList._id,
            name: modifierList.modifierListData.name,
            label: "List"
        };
        setSelectedModifierList(selectedModifierList);
    };
    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the modifier list",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteModifier(id).then((response) => {
                    const newModifierLists = modifierLists.filter(x => {
                        return x._id !== id;
                    });
                    setModifierLists([...newModifierLists]);
                    swal(
                        "Poof! Your menu is deleted",
                        {
                            icon: "success",
                        }
                    );
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const cancelModal = () => {
        setSelectedModifierList(null);
    };

    const saveModal = (updatedModifierList) => {
        setSelectedModifierList(null);
        if (updatedModifierList) {
            // updated
            const newModifierLists = modifierLists.map(x => {
                if (x._id == updatedModifierList._id) {
                    x.modifierListData.name = updatedModifierList.name;
                }
                return x;
            });
            setModifierLists([...newModifierLists]);
        } else {
            // refresh
            populateModifiersData();
        }
    };
    return (
        <>
            <MenuModal selectedMenu={selectedModifierList} setSelectedMenu={setSelectedModifierList} cancel={cancelModal} save={saveModal}></MenuModal>
            <div className="form-head d-flex mb-0 align-items-start">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Lists</h2>
                    <ol className="breadcrumb d-none d-md-flex d-lg-flex">
                        <li className="breadcrumb-item">
                            <Link to="/menu">
                                Main
                            </Link>
                        </li>
                    </ol>
                </div>
                <Link className="btn mr-2" to="/menu">
                    <i className="las la-undo mr-0 mr-lg-2"></i>
                    <span className="d-none d-lg-inline-block">Back</span>
                </Link>
                <Button onClick={() => { setSelectedModifierList({ label: "List" }); }}>+ New Modifier</Button>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {loading && !modifierLists.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !modifierLists.length) &&
                        <div className="card">
                            <div className="card-body " style={{ padding: "1.25rem" }}>
                                <div className="table-responsive no-overflow">
                                    {
                                        !loading && !modifierLists.length && <>Nothing Found</>
                                    }
                                    {
                                        !!modifierLists.length &&
                                        (<table className="table mb-0 text-black">
                                            <thead>
                                                <tr>
                                                    <th className="align-middle td-sm"></th>
                                                    <th className="align-middle"></th>
                                                    <th className="align-middle text-left td-sm">Actions</th>
                                                    <th className="no-sort" />
                                                </tr>
                                            </thead>
                                            <tbody id="orders">
                                                {modifierLists.map(row => {
                                                    return (
                                                        <tr key={row._id} className="btn-reveal-trigger">
                                                            <td className="py-2">
                                                                <i className="fs-32 las la-coffee"></i>
                                                            </td>
                                                            <td className="py-2">
                                                                <h4 className="mb-0">{row.modifierListData.name} <span className="mx-2">•</span> {row.modifierListData.modifiers.length} Items</h4>
                                                            </td>
                                                            <td className="py-2 text-right">

                                                                <div className="d-flex align-items-center justify-content-right">
                                                                    <BootstrapSwitchButton
                                                                        checked={row.isAvailable !== false}
                                                                        onlabel='ON'
                                                                        offlabel='OFF'
                                                                        size="sm"
                                                                        onChange={(checked) => {
                                                                            onOff(checked, row._id);
                                                                        }}
                                                                    />
                                                                    <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                        onClick={(event) => handleEditClick(event, row)}
                                                                    >
                                                                        <i className="fa fa-pencil"></i>
                                                                    </Link>
                                                                    <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                        onClick={() => handleDeleteClick(row._id)}
                                                                    >
                                                                        <i className="fa fa-trash"></i>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td className="menu-arrow-box">
                                                                <Link to={`/menu/lists/${row._id}/modifiers`}>
                                                                    <div className="menu-arrow" onClick={() => dispatch(setModifierListAction(row, locationId))}>
                                                                        <i className="fs-32 las la-angle-double-right"></i>
                                                                    </div>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>)
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
};

export default ModifierLists;