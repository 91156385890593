import React, { useState, useRef, useEffect } from "react";
import { Dropdown, Tab, Nav, TabContainer, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import OrderDetailModal from "../modals/OrderDetailModal";
import { getDepositReport, getItemsReport, getMenuReport, getOrders, getSalesReport } from "../../services/DashboardService";
import { useSelector } from "react-redux";
import { currencyFormat, humanReadableFormattedDateString, isMobile } from "../../services/UtilsService";
import BarChart from "../reusable/BarChart";
import ReactPaginate from 'react-paginate';
import ContentLoader from 'react-content-loader';
import RequestRefundModal from "../modals/RequestRefundModal";
import DownloadReportModal from "../modals/DownloadReportModal";

const Dashboard = () => {
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [selectionRange, setSelectionRange] = useState({
        endDate: new Date(),
        startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
        key: 'selection',
        label: 'Last 7 days'
    });

    const [salesReport, setSalesReport] = useState({});
    const [itemsReport, setItemsReport] = useState([]);
    const [menuReport, setMenuReport] = useState([]);
    const [depositReport, setDepositReport] = useState([]);
    const [graphType, setGraphType] = useState('volume')
    const [loading, setLoading] = useState(false);
    const [refundOrder, setRefundOrder] = useState(false);
    const [contentLoading, setContentLoading] = useState(true);
    const [salesReports, setSalesReports] = useState([]);
    const [paginator, setPaginator] = useState({
        current: 0,
        pageCount: 0,
        totalOrders: 0,
        limit: 10,
        showing: {
            from: 0,
            to: 0
        }
    });
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [showDownloadModal, setShowDownloadModal] = useState(false);

    // use effect
    useEffect(() => {
        if (locationId) {
            populateSalesData();
            setTimeout(() => {
                loadLater();
            }, 1000);
        }
    }, [locationId, selectionRange]);

    const handleSelect = (ranges) => {
        selectionRange.startDate = ranges.selection.startDate;
        selectionRange.endDate = ranges.selection.endDate;
        selectionRange.label = selectionRange.startDate.toDateString();
        if (selectionRange.startDate.toDateString() != selectionRange.endDate.toDateString()) {
            selectionRange.label += " - " + selectionRange.endDate.toDateString();
        }
        setSelectionRange({ ...selectionRange });
        document.querySelector('.date-range-dropdown button.dropdown-toggle').click();
    }

    const populateSalesData = () => {
        setLoading(true);
        getSalesReport(locationId, selectionRange.startDate, selectionRange.endDate).then(response => {
            setSalesReport(response.data.data);
            setLoading(false);
            setContentLoading(false);
        }).catch(error => {
            setLoading(false);
            setContentLoading(false);
            console.error(error);
        });
    };

    const loadLater = () => {
        getMenuReport(locationId, selectionRange.startDate, selectionRange.endDate).then(response => {
            setMenuReport(response.data.data);
        }).catch(error => {
            console.error(error);
        });
        getDepositReport(locationId, selectionRange.startDate, selectionRange.endDate).then(response => {
            setDepositReport(response.data.data);
        }).catch(error => {
            console.error(error);
        });
        populateItemsData();
        populateOrdersData();
    };

    const populateItemsData = (page = 0, limit = 6) => {
        getItemsReport(locationId, selectionRange.startDate, selectionRange.endDate, page, limit).then(response => {
            setItemsReport(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    };

    const populateOrdersData = (page = 0) => {
        // setLoading(true);
        getOrders(locationId, selectionRange.startDate, selectionRange.endDate, page, paginator.limit).then(response => {
            const responseData = response.data?.data;
            const orders = responseData.store?.orders;
            setSalesReports(orders || []);
            paginator.current = page;
            paginator.pageCount = Math.ceil(responseData.store?.totalOrders / paginator.limit);
            paginator.totalOrders = responseData.store?.totalOrders;
            paginator.showing.from = (paginator.current * paginator.limit) + 1;
            paginator.showing.to = ((paginator.current + 1) * paginator.limit);
            if (paginator.showing.to > paginator.totalOrders) {
                paginator.showing.to = paginator.totalOrders;
            }
            setPaginator(paginator);
            setLoading(false);
            setContentLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
            setContentLoading(false);
        });
    }

    const viewOrderDetail = (order) => {
        setSelectedOrder(order);
    };

    const handleDownload = () => {
        setShowDownloadModal(true);
    };

    const handleRequestRefund = (report) => {
        setRefundOrder(report);
    };

    const handleCancelRefund = () => {
        setRefundOrder(null);
    };

    const presets = [
        { label: 'Today', start: new Date(), end: new Date() },
        { label: 'Last Week', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date() }
    ];
    if (contentLoading && !isMobile()) {
        return (
            <ContentLoader
                speed={2}
                width={'100%'}
                height={900}
                viewBox="0 0 100% 900"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
            >
                <rect x="0" y="0" rx="6" ry="6" width="200" height="38" />
                <rect x="0" y="50" rx="6" ry="6" width="280" height="10" />
                <rect x="82%" y="0" rx="6" ry="6" width="18%" height="60" />
                <rect x="0" y="80" rx="20" ry="20" width="48%" height="350" />
                <rect x="51%" y="80" rx="20" ry="20" width="49%" height="350" />
                <rect x="0" y="460" rx="6" ry="6" width="200" height="38" />
                <rect x="0" y="530" rx="20" ry="20" width="100%" height="350" />
            </ContentLoader>
        );
    }
    return (
        <div className="position-relative">
            {loading &&
                <div className="loading-overlay">
                    <Spinner className="mb-5" animation="border" variant="primary" />
                </div>
            }
            <OrderDetailModal selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder}></OrderDetailModal>
            <RequestRefundModal refundOrder={refundOrder} cancel={handleCancelRefund}></RequestRefundModal>
            <DownloadReportModal showModal={showDownloadModal} setShowModal={setShowDownloadModal} type="normal" selectionRange={selectionRange}></DownloadReportModal>
            <div className="form-head d-flex flex-lg-row flex-md-row flex-column mb-4 align-items-start">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Dashboard</h2>
                    <p className="mb-0 d-none d-md-block d-lg-block">Welcome to Birch Coffee Admin!</p>
                </div>
                <Dropdown className="dropdown date-range-dropdown">
                    <Dropdown.Toggle
                        className="btn btn-sm btn-primary light w-100 d-flex align-items-center svg-btn"
                        data-toggle="dropdown"
                    >
                        <svg
                            width={28}
                            height={28}
                            viewBox="0 0 28 28"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g>
                                <path
                                    d="M22.4281 2.856H21.8681V1.428C21.8681 0.56 21.2801 0 20.4401 0C19.6001 0 19.0121 0.56 19.0121 1.428V2.856H9.71606V1.428C9.71606 0.56 9.15606 0 8.28806 0C7.42006 0 6.86006 0.56 6.86006 1.428V2.856H5.57206C2.85606 2.856 0.560059 5.152 0.560059 7.868V23.016C0.560059 25.732 2.85606 28.028 5.57206 28.028H22.4281C25.1441 28.028 27.4401 25.732 27.4401 23.016V7.868C27.4401 5.152 25.1441 2.856 22.4281 2.856ZM5.57206 5.712H22.4281C23.5761 5.712 24.5841 6.72 24.5841 7.868V9.856H3.41606V7.868C3.41606 6.72 4.42406 5.712 5.57206 5.712ZM22.4281 25.144H5.57206C4.42406 25.144 3.41606 24.136 3.41606 22.988V12.712H24.5561V22.988C24.5841 24.136 23.5761 25.144 22.4281 25.144Z"
                                    fill="#2F4CDD"
                                />
                            </g>
                        </svg>
                        <div className="text-left ml-3 mr-2 text-end">
                            <span className="d-block fs-16">Filter Period</span>
                            <small className="d-block fs-13">{selectionRange.label}</small>
                            {/* <small className="d-block fs-13">4 June 2020 - 4 July 2020</small> */}
                        </div>
                        {/* <i className="fa fa-angle-down scale5 ml-3" /> */}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        <DateRangePicker
                            ranges={[selectionRange]}
                            rangeColors={[
                                '#FF3333'
                            ]}
                            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
                            maxDate={new Date()}
                            onChange={handleSelect}
                            presets={presets}
                        />
                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <div className="row">
                <div className="col-xl-6 col-xxl-6 col-lg-12 col-md-12">
                    <Tab.Container defaultActiveKey="sales">
                        <div className="card report-cards">
                            <div className="card-header border-0 pb-0 d-sm-flex d-block justify-content-center">
                                <div className="card-action card-tabs mt-3 mt-sm-0">
                                    <Nav as="ul" className="nav nav-tabs report-tabs" role="tablist">
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                className="nav-link"
                                                data-toggle="tab"
                                                to="#"
                                                role="tab"
                                                eventKey="sales"
                                            >
                                                Sales
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                className="nav-link"
                                                data-toggle="tab"
                                                to="#"
                                                role="tab"
                                                eventKey="menus"
                                            >
                                                Menus
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                className="nav-link"
                                                data-toggle="tab"
                                                to="#"
                                                role="tab"
                                                eventKey="items"
                                            >
                                                Items
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                className="nav-link"
                                                data-toggle="tab"
                                                to="#"
                                                role="tab"
                                                eventKey="deposit"
                                            >
                                                Deposit
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="card-body orders-summary">
                                <Tab.Content>
                                    <Tab.Pane eventKey="sales">
                                        <div className="row">
                                            <div className="col-sm-6 mb-4">
                                                <div className="border px-3 py-3 rounded-xl">
                                                    <h2 className="fs-32 font-w600 counter">
                                                        {currencyFormat(salesReport?.revenue, 2)}
                                                    </h2>
                                                    <p className="fs-16 mb-0">Revenue</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <div className="border px-3 py-3 rounded-xl">
                                                    <h2 className="fs-32 font-w600 counter">
                                                        {currencyFormat(salesReport?.tips, 2)}
                                                    </h2>
                                                    <p className="fs-16 mb-0">Tips</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <div className="border px-3 py-3 rounded-xl">
                                                    <h2 className="fs-32 font-w600 counter">
                                                        {currencyFormat(salesReport?.taxes, 2)}
                                                    </h2>
                                                    <p className="fs-16 mb-0">Taxes</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <div className="border px-3 py-3 rounded-xl">
                                                    <h2 className="fs-32 font-w600 counter">
                                                        {currencyFormat(salesReport?.gross, 2)}
                                                    </h2>
                                                    <p className="fs-16 mb-0">Gross</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <div className="border px-3 py-3 rounded-xl">
                                                    <h2 className="fs-32 font-w600 counter">
                                                        {currencyFormat(salesReport?.serviceCharge, 2)}
                                                    </h2>
                                                    <p className="fs-16 mb-0">Processing</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 mb-4">
                                                <div className="border px-3 py-3 rounded-xl">
                                                    <h2 className="fs-32 font-w600 counter">
                                                        {currencyFormat(salesReport?.deposit, 2)}
                                                    </h2>
                                                    <p className="fs-16 mb-0">Deposit</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="menus">
                                        <div className={"row" + (menuReport.length > 6 ? " report-scrollable" : "")}>
                                            {menuReport.map((menu, i) => {
                                                return (<div className="col-sm-6 mb-4" key={i}>
                                                    <div className="border px-3 py-3 rounded-xl">
                                                        <h2 className="fs-32 font-w600 counter">
                                                            {currencyFormat(menu.amount, 2)}
                                                        </h2>
                                                        <div>
                                                            <p className="fs-16 mb-0">{menu.menu}</p>
                                                            <small>{menu.count} Orders</small>
                                                        </div>
                                                    </div>
                                                </div>);
                                            })}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="items">
                                        <div className={"row" + (itemsReport.length > 6 ? " report-scrollable" : "")}>
                                            {itemsReport.map((item, i) => {
                                                return (<div className="col-sm-6 mb-4" key={i}>
                                                    <div className="border px-3 py-3 rounded-xl">
                                                        <h2 className="fs-32 font-w600 counter">
                                                            {currencyFormat(item.amount, 2)}
                                                        </h2>
                                                        <div>
                                                            <p className="fs-16 mb-0">{item.items}</p>
                                                            <small>{item.count} Orders</small>
                                                        </div>
                                                    </div>
                                                </div>);
                                            })}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="deposit">
                                        <div className={"row" + (depositReport.length > 6 ? " report-scrollable" : "")}>
                                            {depositReport.map((deposit, i) => {
                                                return (<div className="col-sm-6 mb-4" key={i}>
                                                    <div className="border px-3 py-3 rounded-xl">
                                                        <h2 className="fs-32 font-w600 counter">
                                                            {currencyFormat(deposit.deposit, 2)}
                                                        </h2>
                                                        <div>
                                                            <p className="fs-16 mb-0">{humanReadableFormattedDateString(deposit.date)}</p>
                                                            <small className="text-success">{deposit.count} Orders</small>
                                                        </div>
                                                    </div>
                                                </div>);
                                            })}
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </Tab.Container>
                </div>
                <div className="col-xl-6 col-xxl-6 col-lg-12 col-md-12">
                    <TabContainer defaultActiveKey={"volume"}>
                        <div className="card">
                            <div className="card-header border-0 pb-0 d-sm-flex d-block justify-content-center">
                                <div className="card-action card-tabs mt-3 mt-sm-0">
                                    <Nav as="ul" className="nav nav-tabs" role="tablist" onSelect={setGraphType}>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                className="nav-link"
                                                data-toggle="tab"
                                                to="#"
                                                role="tab"
                                                eventKey="volume"
                                            >
                                                Volume
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                className="nav-link"
                                                data-toggle="tab"
                                                to="#"
                                                role="tab"
                                                eventKey="orders"
                                            >
                                                Orders
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item className="nav-item">
                                            <Nav.Link
                                                className="nav-link"
                                                data-toggle="tab"
                                                to="#"
                                                role="tab"
                                                eventKey="users"
                                            >
                                                Users
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>
                            <div className="card-body revenue-chart px-3">
                                <div id="chartBar" className="mt-5">
                                    <BarChart graphType={graphType} startDate={selectionRange.startDate} endDate={selectionRange.endDate}></BarChart>
                                </div>
                            </div>
                        </div>
                    </TabContainer>
                </div>
                <div className="col-12">
                    <div className="form-head d-flex mb-3 align-items-start">
                        <div className="mr-auto">
                            <h2 className="text-black font-w600 mb-0">Sales Report</h2>
                        </div>
                        {!!salesReports.length &&
                            <Link to="#" className="btn btn-sm btn-primary d-flex align-items-center justify-content-center" onClick={handleDownload}>
                                <i className="las la-cloud-download-alt fs-24 mr-2"></i> <span className="d-none d-md-block d-lg-block">Download</span>
                            </Link>
                        }
                    </div>
                    <div className="table-responsive">
                        {!salesReports.length &&
                            <div className="card"><div className="card-body">Nothing found</div></div>}
                        {!!salesReports.length &&
                            <div id="order_list" className="dataTables_wrapper no-footer">
                                <table
                                    className="display mb-4 dataTablesCard dataTable no-footer w-100 "
                                    style={{ minWidth: 845 }}
                                    role="grid"
                                >
                                    <thead>
                                        <tr role="row">
                                            <th>Date</th>
                                            <th>Customer</th>
                                            <th>Subtotal</th>
                                            <th>Tax</th>
                                            <th>Tip</th>
                                            <th>Discount</th>
                                            <th>Gross</th>
                                            {/* <th>Due</th> */}
                                            <th>Refund</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {salesReports?.map((report, key) => {
                                            return (
                                                <tr key={key} role="row" className={key % 2 ? "odd" : "even"}>
                                                    <td><label className="date-label">{humanReadableFormattedDateString(report.createdAt)}</label></td>
                                                    <td>{report?.customer}</td>
                                                    <td>{currencyFormat(report.totalMoney?.amount)}</td>
                                                    <td>{currencyFormat(report.totalTaxMoney?.amount)}</td>
                                                    <td>{currencyFormat(report.totalTipMoney?.amount)}</td>
                                                    <td>{currencyFormat(report.totalDiscountMoney?.amount)}</td>
                                                    {/* <td>{currencyFormat(report.totalMoney?.amount + report.totalTaxMoney?.amount + report.totalTipMoney?.amount - report.totalDiscountMoney?.amount)}</td> */}
                                                    <td>{currencyFormat(report.totalGrossMoney?.amount)}</td>

                                                    {/* <td>{currencyFormat(report.netAmountDueMoney?.amount)}</td> */}
                                                    <td>
                                                        {!!report.refundedAmountMoney?.amount ?
                                                            <span className="fs-14">
                                                                {currencyFormat(report.refundedAmountMoney?.amount)} Refunded
                                                            </span> :
                                                            <span className="btn btn-sm light btn-warning" onClick={() => handleRequestRefund(report)}>
                                                                Request
                                                            </span>}
                                                    </td>
                                                    <td className="menu-arrow-box">
                                                        <Link to="#" onClick={() => viewOrderDetail(report)}>
                                                            <div className="menu-arrow">
                                                                <i className="fs-32 las la-eye"></i>
                                                            </div>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                                <div className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
                                    <div className="dataTables_info">
                                        Showing {paginator.showing.from} to {paginator.showing.to}{" "}
                                        of {paginator.totalOrders} entries
                                    </div>
                                    {!!paginator.pageCount && paginator.pageCount > 1 &&
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={(event) => { populateOrdersData(event.selected); }}
                                            pageRangeDisplayed={isMobile() ? 2 : 4}
                                            marginPagesDisplayed={1}
                                            initialPage={paginator.current}
                                            pageCount={paginator.pageCount}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            pageClassName="paginate_button"
                                            pageLinkClassName=""
                                            previousClassName=""
                                            previousLinkClassName="btn btn-sm btn-light"
                                            nextClassName=""
                                            nextLinkClassName="btn btn-sm btn-light"
                                            breakClassName=""
                                            breakLinkClassName=""
                                            containerClassName="dataTables_paginate paging_simple_numbers d-flex align-items-center"
                                            activeClassName="current"
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
