import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import swal from "sweetalert";
import { deleteSubMenu, getSubMenus, subMenusOrder, toggleSubMenuAvailable, updateSubMenu } from "../../services/MenuService";
import SubMenuModal from "../modals/SubMenuModal";
import { setSubMenuAction } from "../../store/actions/BreadcrumbActions";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const SubMenus = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [subMenus, setSubMenus] = useState([]);
    const [loading, setLoading] = useState(false);
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const [selectedSubMenu, setSelectedSubMenu] = useState(null);
    const { selectedMenu: breadcrumbMenu, onLocationId } = useSelector((state) => state.breadcrumbs);
    const history = useHistory();
    const [willRearrange, setWillRearrange] = useState(false);

    useEffect(() => {
        if (onLocationId != locationId) {
            history.push('/menu');
        }
    }, [locationId]);

    useEffect(() => {
        populateSubMenusData();
    }, []);

    const populateSubMenusData = () => {
        setLoading(true);
        getSubMenus(params.id).then(response => {
            const responseData = response.data.data;
            if (responseData.subMenu) {
                setSubMenus(responseData.subMenu);
            }
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    const onOff = (checked, id) => {
        toggleSubMenuAvailable(id, checked).then((reponse) => {
            const newMenus = subMenus.map(x => {
                if (x._id == id) {
                    x.isAvailable = checked;
                }
                return x;
            });
            setSubMenus([...newMenus]);
        });
    };

    const handleAddClick = (event) => {
        const selected = {
            menuId: params.id,
            name: "",
            submenuHours: {}
        };
        days.map(val => {
            selected.submenuHours[val] = {
                "availability": "all_day",
                "firstPickupTime": {
                    "hours": 0,
                    "minutes": 0
                },
                "lastPickupTime": {
                    "hours": 0,
                    "minutes": 0
                }
            };
        });
        setSelectedSubMenu(selected);
    };

    const handleEditClick = (event, subMenu) => {
        const selectedSubMenu = {
            _id: subMenu._id,
            menuId: params.id,
            name: subMenu?.name,
            submenuHours: subMenu?.submenuHours
        };
        setSelectedSubMenu(selectedSubMenu);
    };
    const handleDeleteClick = (id) => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the sub-menu",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteSubMenu(params.id, id).then((response) => {
                    const newSubMenus = subMenus.filter(x => {
                        return x._id !== id;
                    });
                    setSubMenus([...newSubMenus]);
                    swal(
                        "Poof! Your sub-menu is deleted",
                        {
                            icon: "success",
                        }
                    );
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const cancelModal = () => {
        setSelectedSubMenu(null);
    };

    const saveModal = (updatedSubMenu) => {
        setSelectedSubMenu(null);
        if (updatedSubMenu) {
            // updated
            const newSubMenus = subMenus.map(x => {
                if (x._id == updatedSubMenu._id) {
                    x.name = updatedSubMenu.name;
                    x.submenuHours = updatedSubMenu.submenuHours
                }
                return x;
            });
            setSubMenus([...newSubMenus]);
        } else {
            // refresh
            populateSubMenusData();
        }
    };

    const onDragEnd = (result) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        stopDragging(destination.index, source.index);
    }

    const compare = (a, b) => {
        return a.orderNumber - b.orderNumber;
    }

    const stopDragging = (destinationIndex, sourceIndex) => {
        if (sourceIndex == destinationIndex) {
            return;
        }
        let newSubMenus = subMenus.sort(compare).map((subMenu, index) => {
            subMenu.orderNumber = index + 1;
            if (subMenu.orderNumber == sourceIndex) {
                return {
                    ...subMenu,
                    orderNumber: destinationIndex
                }
            }
            if (sourceIndex < destinationIndex && subMenu.orderNumber <= destinationIndex) {
                return {
                    ...subMenu,
                    orderNumber: subMenu.orderNumber - 1
                }
            }

            if (sourceIndex > destinationIndex && subMenu.orderNumber >= destinationIndex) {
                return {
                    ...subMenu,
                    orderNumber: subMenu.orderNumber + 1
                }
            }
            return subMenu;
        });
        setSubMenus([]);
        setTimeout(() => {
            setSubMenus([...newSubMenus]);
            setWillRearrange(true);
        }, 0);
    };

    const handleCancelRerrange = () => {
        setSubMenus([]);
        setWillRearrange(false);
        populateSubMenusData();
    };

    const handleSaveRearrange = () => {
        setLoading(true);
        const request = subMenus.sort(compare).map((subMenu, index) => {
            return {
                subMenuId: subMenu._id,
                orderNumber: index + 1
            }
        });
        subMenusOrder(request).then(response => {
            setLoading(false);
            setWillRearrange(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    };

    return (
        <>
            <SubMenuModal selectedSubMenu={selectedSubMenu} setSelectedSubMenu={setSelectedSubMenu} cancel={cancelModal} save={saveModal}></SubMenuModal>
            <div className="form-head d-flex mb-0 align-items-start">
                <div className="mr-auto">
                    <h2 className="text-black font-w600 mb-0">Sub Menus</h2>
                    <ol className="breadcrumb d-none d-md-flex d-lg-flex">
                        <li className="breadcrumb-item">
                            <Link to="/menu">
                                Main
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/menu/menus">
                                Menus
                            </Link>
                        </li>
                        {!!breadcrumbMenu?.categoryData?.name &&
                            <li className="breadcrumb-item text-capitalize">
                                {breadcrumbMenu?.categoryData?.name}
                            </li>
                        }
                    </ol>
                </div>
                <Link className="btn mr-2" to="/menu/menus">
                    <i className="las la-undo mr-0 mr-lg-2"></i>
                    <span className="d-none d-lg-inline-block">Back</span>
                </Link>
                <Button onClick={handleAddClick}>+ New Submenu</Button>
            </div>

            <div className="row">
                <div className="col-lg-12">
                    {loading && !subMenus.length && <Spinner animation="border" variant="primary" />}
                    {!(loading && !subMenus.length) &&
                        <div className="card">
                            <div className="card-body " style={{ padding: "1.25rem" }}>
                                <div className="table-responsive no-overflow">
                                    {
                                        !loading && !subMenus.length && <>Nothing Found</>
                                    }
                                    {
                                        !!subMenus.length &&
                                        (
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <table className="table mb-0 text-black">
                                                    <thead>
                                                        <tr>
                                                            <th className="align-middle td-sm"></th>
                                                            <th className="align-middle"></th>
                                                            <th className="align-middle text-left td-sm">Actions</th>
                                                            <th className="no-sort" />
                                                        </tr>
                                                    </thead>
                                                    <Droppable droppableId="orders">
                                                        {(provided) => {
                                                            return (
                                                                <tbody id="orders" {...provided.droppableProps} ref={provided.innerRef}>
                                                                    {subMenus.sort(compare).map((row, index) => {
                                                                        return (
                                                                            <Draggable draggableId={row._id} index={index + 1} key={index}>
                                                                                {(provided2) => {
                                                                                    return (
                                                                                        <tr  {...provided2.draggableProps}
                                                                                            {...provided2.dragHandleProps}
                                                                                            ref={provided2.innerRef}
                                                                                            key={row._id} className="btn-reveal-trigger">
                                                                                            <td className="py-2">
                                                                                                <i className="fs-32 las la-coffee"></i>
                                                                                            </td>
                                                                                            <td className="py-2">
                                                                                                <h4 className="mb-0">{row.name} <span className="mx-2">•</span> {row.items.length} Items</h4>
                                                                                            </td>
                                                                                            <td className="py-2 text-right">

                                                                                                <div className="d-flex align-items-center justify-content-right">
                                                                                                    <BootstrapSwitchButton
                                                                                                        checked={row.isAvailable !== false}
                                                                                                        onlabel='ON'
                                                                                                        offlabel='OFF'
                                                                                                        size="sm"
                                                                                                        onChange={(checked) => {
                                                                                                            onOff(checked, row._id);
                                                                                                        }}
                                                                                                    />
                                                                                                    <Link to="#" className="btn btn-secondary shadow btn-xs sharp ml-2 mr-2"
                                                                                                        onClick={(event) => handleEditClick(event, row)}
                                                                                                    >
                                                                                                        <i className="fa fa-pencil"></i>
                                                                                                    </Link>
                                                                                                    <Link to="#" className="btn btn-danger shadow btn-xs sharp"
                                                                                                        onClick={() => handleDeleteClick(row._id)}
                                                                                                    >
                                                                                                        <i className="fa fa-trash"></i>
                                                                                                    </Link>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className="menu-arrow-box">
                                                                                                <Link to={`/menu/menus/${params.id}/submenus/${row._id}/items`}>
                                                                                                    <div className="menu-arrow" onClick={() => dispatch(setSubMenuAction(row))}>
                                                                                                        <i className="fs-32 las la-angle-double-right"></i>
                                                                                                    </div>
                                                                                                </Link>
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }}
                                                                            </Draggable>
                                                                        );
                                                                    })}
                                                                    {provided.placeholder}
                                                                </tbody>);
                                                        }}
                                                    </Droppable>
                                                </table>
                                            </DragDropContext>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {!!willRearrange &&
                <div className="card-footer text-center save-cancel">
                    {!loading &&
                        <>
                            <button type="button" onClick={() => handleCancelRerrange()} className="btn btn-light mr-3 w-25"> <i className="flaticon-delete-1"></i> Cancel</button>
                            <button type="submit" className="btn btn-primary w-25" onClick={handleSaveRearrange}>Save</button>
                        </>
                    }
                    {!!loading && <Spinner animation="border" variant="primary" />}
                </div>
            }
        </>
    );
};

export default SubMenus;