import axiosInstance from './AxiosInstance';

export function getMenus(locationId) {
    return axiosInstance.get(`/admin/menu?locationId=${locationId}`);
}

export function menusOrder(menus) {
    const request = {
        menu: menus
    };
    return axiosInstance.put(`admin/menuOrder`, request);
}

export function createMenu(menuData) {
    return axiosInstance.post(`/admin/menu`, menuData);
}

export function updateMenu(menu) {
    return axiosInstance.put(`admin/menu`, menu);
}

export function deleteMenu(menuId) {
    return axiosInstance.delete(`admin/menu?menuId=${menuId}`);
}

export function getSubMenus(menuId) {
    return axiosInstance.get(`/admin/submenu?menuId=${menuId}`);
}

export function subMenusOrder(subMenus) {
    const request = {
        subMenus: subMenus
    };
    return axiosInstance.put(`admin/submenuOrder`, request);
}

export function createSubMenu(subMenuData) {
    return axiosInstance.post(`/admin/submenu`, subMenuData);
}

export function updateSubMenu(subMenu) {
    return axiosInstance.put(`admin/submenu`, subMenu);
}

export function deleteSubMenu(menuId, subMenuId) {
    return axiosInstance.delete(`admin/submenu?menuId=${menuId}&submenuId=${subMenuId}`);
}

export function toggleMenuAvailable(menuId, isAvailable) {
    const request = {
        menuId: menuId,
        isAvailable: isAvailable
    };
    return axiosInstance.put(`admin/isAvailable`, request);
}

export function toggleSubMenuAvailable(subMenuId, isAvailable) {
    const request = {
        submenuId: subMenuId,
        isAvailable: isAvailable
    };
    return axiosInstance.put(`admin/isAvailable`, request);
}