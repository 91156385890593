import axiosInstance from './AxiosInstance';
import { formattedDateString } from './UtilsService';

export function getSalesReport(locationId, startDate, endDate) {
    if (!startDate) {
        startDate = "";
    } else {
        startDate = formattedDateString(startDate);
    }
    if (!endDate) {
        endDate = "";
    } else {
        endDate = formattedDateString(endDate);
    }
    return axiosInstance.get(`/admin/salesReport?locationId=${locationId}&startDate=${startDate}&endDate=${endDate}`);
}

export function getItemsReport(locationId, startDate, endDate, page = 0, limit = 0) {
    if (!startDate) {
        startDate = "";
    } else {
        startDate = formattedDateString(startDate);
    }
    if (!endDate) {
        endDate = "";
    } else {
        endDate = formattedDateString(endDate);
    }
    if (!(page && page > 0)) {
        page = 0;
    }
    if (!(limit && limit > 0)) {
        limit = 6;
    }
    return axiosInstance.get(`/admin/itemsReport?locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`);
}

export function getMenuReport(locationId, startDate, endDate, page = 0, limit = 0) {
    if (!startDate) {
        startDate = "";
    } else {
        startDate = formattedDateString(startDate);
    }
    if (!endDate) {
        endDate = "";
    } else {
        endDate = formattedDateString(endDate);
    }
    if (!(page && page > 0)) {
        page = 0;
    }
    if (!(limit && limit > 0)) {
        limit = 6;
    }
    return axiosInstance.get(`/admin/menuReport?locationId=${locationId}&startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`);
}

export function getDepositReport(locationId, startDate, endDate, page = 0, limit = 0) {
    if (!startDate) {
        startDate = "";
    } else {
        startDate = formattedDateString(startDate);
    }
    if (!endDate) {
        endDate = "";
    } else {
        endDate = formattedDateString(endDate);
    }
    if (!(page && page > 0)) {
        page = 0;
    }
    if (!(limit && limit > 0)) {
        limit = 6;
    }
    return axiosInstance.get(`/admin/depositReport?locationId=${locationId}&startDate=${startDate}&endDate=${endDate}`);
}

export function getOrders(locationId, startDate, endDate, page = 0, limit = 0, userId = null) {
    if (!startDate) {
        startDate = "";
    } else {
        startDate = formattedDateString(startDate);
    }
    if (!endDate) {
        endDate = "";
    } else {
        endDate = formattedDateString(endDate);
    }
    if (!(page && page > 0)) {
        page = 0;
    }
    if (!(limit && limit > 0)) {
        limit = 10;
    }
    let query = `startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}`;
    if (userId) {
        query += `&userId=${userId}`;
    }
    if(locationId) {
        query += `&locationId=${locationId}`
    }
    return axiosInstance.get(`/admin/orders?${query}`);
}

export function refundOrderAPI(refundData) {
    return axiosInstance.post(`/admin/refund`, refundData);
}

export function downloadReport(request) {
    return axiosInstance.post(`/admin/download`, request);
}

export function getGraphData(locationId, startDate, endDate, type = "volume") {
    if (!startDate) {
        startDate = "";
    } else {
        startDate = formattedDateString(startDate);
    }
    if (!endDate) {
        endDate = "";
    } else {
        endDate = formattedDateString(endDate);
    }
    let graphType = "dashboardVolumes";
    if (type == "orders") {
        graphType = "dashboardOrders";
    } else if (type == "users") {
        graphType = "dashboardUsers";
        return axiosInstance.get(`/admin/${graphType}?startDate=${startDate}&endDate=${endDate}`);
    }
    return axiosInstance.get(`/admin/${graphType}?locationId=${locationId}&startDate=${startDate}&endDate=${endDate}`);
}