import React, { useEffect, useState } from 'react';
import swal from "sweetalert";
import { useDispatch, useSelector } from 'react-redux';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { Link } from 'react-router-dom';
import DescriptionModal from '../modals/DescriptionModal';
import OpenCloseTime from '../reusable/OpenCloseTime';
import { deleteLocation, editLocation, getLocationDetail, getSquareLocations } from '../../services/LocationService';
import { getHOrMFromLocalTimeString, formattedTimeString, defaultPeriods } from '../../services/UtilsService';
import LocationModal from '../modals/LocationModal';
import { Spinner } from 'react-bootstrap';
import { getLocationsAction } from '../../store/actions/LocationActions';

const Settings = () => {
    const dispatch = useDispatch();
    const locationId = useSelector((state) => state.location.selectedLocationId);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [errors, setErrors] = useState({});
    const [settingsData, setSettingsData] = useState(null);
    const [editLocationData, setEditLocationData] = useState(null);
    const [descriptionData, setDescriptionData] = useState(null);
    const [showLocationHours, setShowLocationHours] = useState(false);

    useEffect(() => {
        if (locationId) {
            populateSettingsData();
        }
    }, [locationId]);

    const populateSettingsData = () => {
        getLocationDetail(locationId).then(response => {
            response.data.data.businessHours.periods.forEach(p => {
                p.closed = !!p.closed;
                delete p._id;
            });
            if (response.data.data.businessHours.periods.length < 1) {
                response.data.data.businessHours.periods = defaultPeriods;
            }
            setSettingsData(response.data.data);
        }).catch(error => {
            console.error(error);
        });
    };

    const handleEditLocation = () => {
        const locationData = {
            squareLocationId: settingsData.id,
            address: settingsData.address,
            displayAddress: settingsData.name,
            coordinates: settingsData.loc.coordinates,
            image: settingsData.image,
            locationSearchedOnGoogle: true
        };
        setEditLocationData({ ...locationData });
    };

    const handleSaveLocationAddress = (updatedLocationData) => {
        settingsData.id = updatedLocationData.squareLocationId;
        settingsData.address = updatedLocationData.address;
        settingsData.name = updatedLocationData.displayAddress;
        settingsData.loc.coordinates = updatedLocationData.coordinates;
        settingsData.image = updatedLocationData.image;
        settingsData.isEdited = true;
        setSettingsData({ ...settingsData });
        setEditLocationData(null);
    };
    const handleCancelLocationAddress = () => {
    };

    const validateForm = (key = null) => {
        let error = false;
        return false;
        const newErrors = key ? errors : {};
        if (key) {
            delete newErrors[key];
        }

        if (!settingsData?.locationId && (key === null || key == "locationId")) {
            error = true;
            newErrors.locationId = "Please select a location";
        }
        setErrors({ ...newErrors });
        return error;
    }

    const handleSaveFormSubmit = (event) => {
        event.preventDefault();
        if (!validateForm()) {
            const request = {
                locationId: settingsData._id,
                squareLocationId: settingsData.id,
                name: settingsData.name,
                address: settingsData.address,
                latitude: settingsData.loc.coordinates?.length ? settingsData.loc.coordinates[1] : null,
                longitude: settingsData.loc.coordinates?.length ? settingsData.loc.coordinates[0] : null,
                country: settingsData.country,
                capabilities: settingsData.capabilities,
                phoneNumber: settingsData.phoneNumber,
                image: settingsData.image,
                businessEmail: settingsData.businessEmail,
                twitterUsername: settingsData.twitterUsername,
                instagramUsername: settingsData.instagramUsername,
                facebookUrl: settingsData.facebookUrl,
                periods: settingsData.businessHours.periods,
                isClosed: !!settingsData.isClosed,
                acceptTips: !!settingsData.acceptTips,
                curbsidePickup: !!settingsData.curbsidePickup,
                allowOrderSchedule: settingsData.allowOrderSchedule || "Current day only",
                languageCode: settingsData.languageCode,
                currency: settingsData.currency,
                businessName: settingsData.businessName,
                squareIntegration: true,
                taxAmount: settingsData?.taxAmount || 0
            };
            editLocation(request).then(response => {
                populateSettingsData();
                dispatch(getLocationsAction(settingsData._id));
                swal('Good job!', 'Successfully Updated', "success");
            }).catch(error => {
                console.error(error);
                swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
            });
        }
    };

    const handleChangeField = (event, key) => {
        settingsData[key] = event.target.value;
        if (key == "taxAmount") {
            if (settingsData[key] < 0) {
                settingsData[key] = 0;
            }
        }
        settingsData.isEdited = true;
        setSettingsData({ ...settingsData });
        validateForm(key);
    };

    const handleChangeToggle = (checked, key) => {
        settingsData[key] = checked;
        settingsData.isEdited = true;
        setSettingsData({ ...settingsData });
        validateForm(key);
    };

    const handleChangeDayToggle = (checked, key, day) => {
        day[key] = checked;
        settingsData.isEdited = true;
        setSettingsData({ ...settingsData });
    };

    const handleDayTimeChange = (timeData, day) => {
        day.startLocalTime = formattedTimeString(timeData.startTime);
        day.endLocalTime = formattedTimeString(timeData.endTime);
        settingsData.isEdited = true;
        setSettingsData({ ...settingsData });
    };

    const handleCancel = () => {
        populateSettingsData();
        setErrors({});
    };

    const handleDelete = () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover the location",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteLocation(locationId).then((response) => {
                    window.location.href = '/settings';
                    swal(
                        "Poof! Location is deleted",
                        {
                            icon: "success",
                        }
                    );
                }).catch(error => {
                    console.error(error);
                    swal('Something went wrong!', error.response?.data?.message || 'API failed', "error");
                });
            }
        })
    };

    const addInAppMessage = () => {
        setDescriptionData({
            title: "message",
            description: settingsData.message || "",
            editing: !!settingsData.message
        });
    };

    const saveDescription = (description) => {
        // settingsData.message = description;
        // settingsData.isEdited = true;
        setSettingsData({ ...settingsData });
        setDescriptionData(null);
    };

    if (!settingsData) {
        return (<Spinner animation="border" variant="primary" />);
    }

    return (
        <div className="row">
            <LocationModal editLocationData={editLocationData} setEditLocationData={setEditLocationData} save={handleSaveLocationAddress} cancel={handleCancelLocationAddress}></LocationModal>
            <DescriptionModal descriptionData={descriptionData} setDescriptionData={setDescriptionData} save={saveDescription}></DescriptionModal>
            <div className="col-lg-12">
                <div className="card">
                    <form>
                        <div className="card-body">
                            <i className="flaticon-cancel-12 close"></i>
                            <div className="add-contact-box">
                                <div className="add-contact-content">
                                    <div className="d-flex align-items-center justify-content-between pr-2">
                                        <h3 className="form-title mt-3 mt-lg-0">
                                            Location Address
                                        </h3>
                                        <Link to="#" onClick={handleEditLocation}>EDIT</Link>
                                    </div>
                                    <div className="form-group px-2 py-3 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <h4 className="text-black font-w500 mb-0">Current Address</h4>
                                            <div>{settingsData.address?.addressLine1}</div>
                                        </div>
                                    </div>
                                    <div className="form-group px-2 py-3">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <h4 className="text-black font-w500 mb-0">Display Address</h4>
                                            <div>{settingsData.name}</div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between pr-2">
                                        <h3 className="form-title">
                                            Delete Location
                                        </h3>
                                    </div>
                                    <div className="form-group px-2 py-3">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="text-black font-w500 mb-0">Click on the button to delete</h4>
                                            <div>
                                                <button type="button" onClick={() => handleDelete()} className="btn btn-sm btn-danger">
                                                    <i className="flaticon-delete-1"></i> Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                    { /*<div className="d-flex align-items-center justify-content-between pr-2">
                                        <h3 className="form-title">
                                            Location Detail
                                        </h3>
                                    </div>
                                    <div className="form-group px-2 py-3">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="text-black font-w500 mb-0">Square Integration</h4>
                                            <div>
                                                {!!settingsData.squareIntegration ?
                                                    <span className="badge badge-success">Connected<span className="ml-1 fa fa-check"></span></span>
                                                    : <span className="badge badge-danger">Not Connected<span className="ml-1 fa fa-times"></span></span>
                                                }
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="d-flex align-items-center justify-content-between pr-2">
                                        <h3 className="form-title">
                                            Features
                                        </h3>
                                    </div>
                                    <div className="form-group px-2 py-3 border-bottom">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="text-black font-w500 mb-0">Store Temporarily Closed</h4>
                                            <BootstrapSwitchButton
                                                checked={settingsData?.isClosed !== false}
                                                onlabel='ON'
                                                offlabel='OFF'
                                                size="sm"
                                                onChange={(checked) => handleChangeToggle(checked, 'isClosed')}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group px-2 py-3 border-bottom">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="text-black font-w500 mb-0">Store Accepts Tips</h4>
                                            <BootstrapSwitchButton
                                                checked={settingsData?.acceptTips !== false}
                                                onlabel='ON'
                                                offlabel='OFF'
                                                size="sm"
                                                onChange={(checked) => handleChangeToggle(checked, 'acceptTips')}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className="form-group px-2 py-3 border-bottom">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="text-black font-w500 mb-0">Do you offer curbside pick up?</h4>
                                            <BootstrapSwitchButton
                                                checked={settingsData?.curbsidePickup !== false}
                                                onlabel='ON'
                                                offlabel='OFF'
                                                size="sm"
                                                onChange={(checked) => handleChangeToggle(checked, 'curbsidePickup')}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="form-group px-2 py-3 border-bottom">
                                        <div className='d-flex justify-content-between align-items-start align-items-lg-center flex-column flex-lg-row'>
                                            <h4 className="text-black font-w500 mb-0">Do you allow orders to be scheduled?</h4>
                                            <select className="form-control ml-auto w-auto" value={settingsData.allowOrderSchedule} onChange={(event) => handleChangeField(event, 'allowOrderSchedule')}>
                                                <option value="Current day only">Current day only</option>
                                                <option value="1 day ahead">1 day ahead</option>
                                                <option value="2 days ahead">2 days ahead</option>
                                                <option value="3 days ahead">3 days ahead</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="form-group px-2 py-3 border-bottom">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="text-black font-w500 mb-0">What is tax percentage?</h4>
                                            <input type="number" className="form-control ml-auto w-tax-per" autoComplete="off" required="required" value={settingsData?.taxAmount} onChange={(event) => handleChangeField(event, 'taxAmount')} placeholder="Enter Tax %age" />
                                        </div>
                                    </div>
                                    {/* <div className="form-group mb-0 px-2 py-3 border-bottom">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="text-black font-w500 mb-0">Add an in-app message</h4>
                                            <Link to="#" onClick={addInAppMessage}>
                                                <div className="menu-arrow">
                                                    {!!settingsData.message && <i className='fs-24 fa fa-pencil'></i>}
                                                    {!settingsData.message &&
                                                        <i className="fs-32 las la-plus"
                                                        ></i>}
                                                </div>
                                            </Link>
                                        </div>
                                    </div> */}
                                    <div className="form-group px-2 py-3 border-bottom expand-collapse-title">
                                        <div className='d-flex justify-content-between align-items-center'>
                                            <h4 className="text-black font-w500 mb-0">Location Hours</h4>
                                            <Link to="#" onClick={() => { setShowLocationHours(!showLocationHours) }}>
                                                <div className="menu-arrow">
                                                    {!!showLocationHours && <i className="fs-32 las la-angle-down"></i>}
                                                    {!showLocationHours && <i className="fs-32 las la-angle-right"></i>}
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    {showLocationHours &&
                                        <>
                                            {settingsData.businessHours.periods.map((day, dayIndex) => {
                                                return (
                                                    <>
                                                        <div className="d-flex align-items-center justify-content-between pr-2">
                                                            <h3 className="form-title">
                                                                {days[dayIndex]}
                                                            </h3>
                                                        </div>
                                                        <div className="form-group px-2 py-3 border-bottom">
                                                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                                                <div className="d-flex">
                                                                    <h4 className="text-black mb-0 font-w500">Closed All Day?</h4>
                                                                </div>
                                                                <BootstrapSwitchButton
                                                                    checked={day?.closed === true}
                                                                    onlabel='ON'
                                                                    offlabel='OFF'
                                                                    size="sm"
                                                                    onChange={(checked) => handleChangeDayToggle(checked, 'closed', day)}
                                                                />
                                                            </div>
                                                            <OpenCloseTime initData={{
                                                                startTimeLabel: 'Open Time',
                                                                endTimeLabel: 'Close Time',
                                                                startHours: getHOrMFromLocalTimeString(day.startLocalTime, 'h'),
                                                                startMinutes: getHOrMFromLocalTimeString(day.startLocalTime, 'm'),
                                                                endHours: getHOrMFromLocalTimeString(day.endLocalTime, 'h'),
                                                                endMinutes: getHOrMFromLocalTimeString(day.endLocalTime, 'm'),
                                                                disabled: day.closed === true
                                                            }} save={(timeData) => {
                                                                handleDayTimeChange(timeData, day);
                                                            }}></OpenCloseTime>
                                                        </div>
                                                    </>
                                                );
                                            })}

                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        {!!settingsData.isEdited &&
                            <div className="card-footer text-center save-cancel">
                                <button type="button" onClick={() => handleCancel()} className="btn btn-light mr-3 w-25"> <i className="flaticon-delete-1"></i> Cancel</button>
                                <button type="submit" className="btn btn-primary w-25" onClick={handleSaveFormSubmit}>Save</button>
                            </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Settings;
