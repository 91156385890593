export const SELECT_MENU_ACTION = "[Breadcrumb Action] Set Menu";
export const SELECT_SUBMENU_ACTION = "[Breadcrumb Action] Set SubMenu";
export const SELECT_MODIFIERLIST_ACTION = "[Breadcrumb Action] Set Modifier List";
export const SELECT_NONE_ACTION = "[Breadcrumb Action] Clear all";

export const setMenuAction = (menu, locationId) => {
    return {
        type: SELECT_MENU_ACTION,
        payload: menu,
        onLocationId: locationId
    };
};

export const setSubMenuAction = (subMenu) => {
    return {
        type: SELECT_SUBMENU_ACTION,
        payload: subMenu
    };
};

export const setModifierListAction = (modifierList, locationId) => {
    return {
        type: SELECT_MODIFIERLIST_ACTION,
        payload: modifierList,
        onLocationId: locationId
    };
};

export const setNoneAction = () => {
    return {
        type: SELECT_NONE_ACTION
    };
};